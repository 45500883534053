import React from "react";

// Local imports
import PropTypes from 'prop-types';
import EncoderFeedDisplay from "../../encoder/components/sidebar/datadisplay/EncoderFeedDisplay";
import EvisFeedDisplay from "../../evis/components/sidebar/datadisplay/EvisFeedDisplay";
import ArchitectureTabs from "../reusableComponents/architectureTabs/ArchitectureTabs";

/**
 * The `AllSelectedFeedsDisplay` component renders a tabbed interface for displaying selected feed content.
 * @param {number} props.value - The current value of the selected tab.
 * @param {function} props.handleTabChange - The function to be called when the tab selection changes.
 * @returns {JSX.Element} - The rendered `AllSelectedFeedsDisplay` component.
 */
export default function AllSelectedFeedsDisplay({ value, handleTabChange }) {
    const architectures = [
        { label: "OnPrem Infra", component: <EncoderFeedDisplay /> },
        { label: "EVIS", component: <EvisFeedDisplay /> },
        // ADD: Add more architectures here
    ];

    return (
        <ArchitectureTabs architectures={architectures} value={value} handleTabChange={handleTabChange} />
    );
}

AllSelectedFeedsDisplay.propTypes = {
    value: PropTypes.number.isRequired,
    handleTabChange: PropTypes.func.isRequired,
};
