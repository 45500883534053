import React from "react";

// Local imports
import ArchAttributes from "../../../../commonComponents/reusableComponents/archAttributes/ArchAttributes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { zixiActionTypes } from "../../../../contexts/zixi/zixiActionTypes";

/**
 * The EncoderAttributes component allows the user to select various attributes related to the encoder.
 *
 * @returns {JSX.Element} - The rendered EncoderAttributes component.
 */
export default function EncoderAttributes() {
    const { storeState, storeDispatch } = React.useContext(GlobalContext);
    const { zixiStates } = storeState;

    const { attributeState } = zixiStates;

    const handleCheckBoxChange = (event) => {
        const { name, checked } = event.target;
        if (name === "showCustomLabelField") {
            //Set showCustomLabelField to true
            storeDispatch({
                type: zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE,
                newAttributeState: {
                    [name]: checked,
                },
            });
        } else {
            storeDispatch({
                type: zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE,
                newAttributeState: {
                    [name]: checked,
                },
            });
        }
    };

    /**
     * handles the check all checkbox change event.
     * It updates the `evisAttributeState` in the global state by setting all the checkboxes to the new state.
     * @param {*} event the event object containing the checkbox state
     */
    const handleCheckAllCheckBox = (event) => {
        const newAttributeState = {};
        Object.keys(attributeState).forEach((key) => {

            newAttributeState[key] = event.target.checked;

        });
        storeDispatch({
            type: zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE,
            newAttributeState,
        });
    }


    const firstGroupCheckboxes = [
        { name: "encoder_link", label: "Encoder Link" },
        { name: "encoder_event", label: "Encoder Event" },
        { name: "event_stats", label: "Event Stats" },
        { name: "encoder_stats", label: "Encoder Stats" },
    ];

    const secondGroupCheckboxes = [
        { name: "active_alerts", label: "Active Alerts" },
        { name: "download_buttons", label: "SCTE Logs" },
    ];

    return (
        <ArchAttributes
            handleCheckboxChange={handleCheckBoxChange}
            handleCheckAll={handleCheckAllCheckBox}
            attributes={zixiStates.attributeState}
            firstGroupCheckboxes={firstGroupCheckboxes}
            secondGroupCheckboxes={secondGroupCheckboxes}
        />

    );
}
