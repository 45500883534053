import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Fab, styled, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { genericActionTypes } from '../../contexts/generic/genericActionTypes';
import { GlobalContext } from '../../contexts/globalContextStore/GlobalContextStore';
import { isLoggerEnabled } from "../../js/utilities.js/logger";

const StyledFab = styled(Fab)(({ theme }) => ({
    backgroundColor: "#0f0f0f",
    color: "#ff9100",
    // size: "small",
    opacity: 0.6,
    // on hover
    "&:hover": {
        backgroundColor: "#2c1b03",
        // size: "small",
        Opacity: "0.8"
    },
}));

/**
 * FloatingNotesIcon component to display a floating notes icon.
 * @returns {JSX.Element} The rendered floating icon.
 */
export const FloatingNotesIcon = () => {
    const {  storeDispatch } = useContext(GlobalContext);

    const handleNotesClick = () => {
        isLoggerEnabled && console.log("Notes icon clicked");
        storeDispatch({
            type: genericActionTypes.TOGGLE_CUSTOM_LABEL,
        });
    };

    return (
        <StyledFab
            aria-label="notes"
            size="small"
            onClick={handleNotesClick}
            // sx={notesIconStyles.floatingIcon} // Apply fixed positioning
        >
            <Tooltip enterNextDelay={500} title="Take Notes" arrow>
                <TextSnippetIcon />
            </Tooltip>
        </StyledFab>
    );
};








