/**
 * A flag that determines whether the logger is enabled or not. It's initially set to false.
 * It gets enabled depending on the environment set up. (check envConfig.js)
 */
let isLoggerEnabled = false;

/**
 * Enables the logger.
 */
export function enableLogger() {
    isLoggerEnabled = true;
}

/**
 * Disables the logger.
 */
export function disableLogger() {
    isLoggerEnabled = false;
}

export { isLoggerEnabled };
