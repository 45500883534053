
const cardShadow = {
    boxShadow: '0px 4px 8px rgba(134, 95, 48, 0.5), 0px -4px 8px rgba(120, 61, 24, 0.3)',
}

const cardContent = {
    color: "#a89587",
    fontSize: "0.1rem",
    backgroundColor: "#000000",
    boxShadow: "#222333"
}

const infoCard = {
    ...cardShadow,
    position: 'absolute',
    top: '12%',  // postion relative to the parent card
    zIndex: 10,  // won't disturb other elements
    boxShadow: '0px 4px 8px rgba(134, 95, 48, 0.5)',
    width: '100%'
}

const cardHeader = {
    padding: '0px 8px',
    '& .MuiCardHeader-action': {
        marginTop: '0',
    },
}

const noInfoMessage = {
    font: 'italic',
}

const attrInfoIcon = {
    color: "#411c03"
}

const iconButton = { padding: '4px', width: '24px', height: '24px' }

const placeHolderCardWhenLoading = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "238px",
    margin: "6px",
}

const streamViewCardStyles = {

    cardShadow: cardShadow,
    cardContent: cardContent,
    infoCard: infoCard,
    cardHeader: cardHeader,
    noInfoMessage: noInfoMessage,
    iconButton: iconButton,
    placeHolderCardWhenLoading: placeHolderCardWhenLoading,
    attrInfoIcon: attrInfoIcon,
}

export default streamViewCardStyles;
