import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

/**
 * CustomAdditionalCard component displays a card with a textarea to add notes.
 * Notes are persisted in localStorage based on the streamObject ID.
 */
export const CustomLabelCard = ({ streamObject }) => {
    const [notes, setNotes] = useState("");

    // Encoder/zixi || EVIS
    const storageKey = streamObject.feed?.split('-')[0] || streamObject.streamObject.evisId;

    // Load notes from localStorage when component mounts
    useEffect(() => {
        const savedNotes = localStorage.getItem(storageKey);
        if (savedNotes) {
            setNotes(savedNotes); // Load saved notes into state
        }
    }, [storageKey]);

    // Update notes state and save to localStorage when textarea value changes
    const handleNotesChange = (event) => {
        const newNotes = event.target.value;
        setNotes(newNotes);
        localStorage.setItem(storageKey, newNotes); // Save notes to localStorage
    };

    return (
        <Card sx={{ minWidth: 275 }}>
            <CardContent>
                <Typography variant="h6" display="flex" sx={{ color: "#00EE00", fontWeight: "bold" }}>
                    {streamObject.feed?.split('-')[0] || streamObject.streamObject.evisId}
                </Typography>

                <Box mt={2}>
                    <textarea
                        value={notes}
                        onChange={handleNotesChange}
                        placeholder="Enter your notes here..."
                        style={{
                            width: '100%',
                            height: '100px',  // Fixed height to avoid resize errors
                            padding: '8px',
                            fontSize: '12px',
                            boxSizing: 'border-box',
                            resize: 'none',  // Disable manual resizing
                        }}
                    />
                </Box>
            </CardContent>
        </Card>
    );
};
