const MULTIPLE_ACTIONS = 'MULTIPLE_ACTIONS';
const SET_DEBUG_ON = 'SET_DEBUG_ON';
const SET_DEBUG_OFF = 'SET_DEBUG_OFF';
const OPEN_SIDEBAR = 'OPEN_SIDEBAR';
const SET_FROM_LOGIN_PAGE = 'SET_FROM_LOGIN_PAGE';
const GET_DASHBOARD_FEED_QUERY_DATA = 'GET_DASHBOARD_FEED_QUERY_DATA';

const OPERATION_ADD = "add";
const OPERATION_REMOVE = "remove";
const OPERATION_RESET = "reset";
const SET_URL_DATA = "SET_URL_DATA";
const TOGGLE_CUSTOM_LABEL = "TOGGLE_CUSTOM_LABEL";

export const genericActionTypes = {
    MULTIPLE_ACTIONS,
    SET_DEBUG_ON,
    SET_DEBUG_OFF,
    OPEN_SIDEBAR,
    SET_FROM_LOGIN_PAGE,
    GET_DASHBOARD_FEED_QUERY_DATA,
    OPERATION_ADD,
    OPERATION_REMOVE,
    OPERATION_RESET,
    SET_URL_DATA,
    TOGGLE_CUSTOM_LABEL,
};