/** Styles */
const checkBoxBorderStyle = {
    color: '#9d7c4c',
};

const checkBoxHoverStyle = {
    "&:hover": {
        backgroundColor: "#171818",
    },
};

const formControlStyle = {
    m: 0,
    width: "100%",
};

const groupFormControlStyle = {
    m: 0,
    width: "50%",
};

const checkAllCheckboxStyle = {
    ...checkBoxHoverStyle,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
};

const archAttributesStyles = {
    checkboxBoarder: checkBoxBorderStyle,
    checkBoxHover: checkBoxHoverStyle,
    formControl: formControlStyle,
    groupFormControl: groupFormControlStyle,
    checkAllCheckbox: checkAllCheckboxStyle,
};

export default archAttributesStyles;