import { evisInitialState, evisReducer } from "../evis/evisReducer";
import { genericInitialStates, genericReducer } from "../generic/genericReducer";
import { zixiInitialState, zixiReducer } from "../zixi/zixiReducer";

const architectures = [
    {
        name: "evisStates",
        initialState: evisInitialState,
        reducer: evisReducer,
    },
    {
        name: "zixiStates",
        initialState: zixiInitialState,
        reducer: zixiReducer,
    },
    {
        name: "genericStates",
        initialState: genericInitialStates,
        reducer: genericReducer,
    }
    // add more architectures here
];

export default architectures;