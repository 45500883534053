// 
const EVIS_ACTIVE_CHECKED_IDS = "EVIS_ACTIVE_CHECKED_IDS";
const EVIS_CHECKBOX_SECTION_DATA = "EVIS_CHECKBOX_SECTION_DATA";
const EVIS_SHOW_ID_DATA = "EVIS_SHOW_ID_DATA";
const EVIS_SHOW_CHECKBOX_DATA = "EVIS_SHOW_CHECKBOX_DATA";

const EVIS_ID_URL_PARAMS_QUEUE = "EVIS_ID_URL_PARAMS_QUEUE";
const EVIS_ID_URL_PARAMS_ATTRIBUTES_QUEUE = "EVIS_ID_URL_PARAMS_ATTRIBUTES_QUEUE";


const EVIS_SET_ATTRIBUTE_STATE = "EVIS_SET_ATTRIBUTE_STATE";
const EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA = "EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA";

const EVIS_ID_DATA = "EVIS_ID_DATA";
const SET_EVIS_ATTRIBUTE_STATE = "SET_EVIS_ATTRIBUTE_STATE";
const EVIS_AVOC_VIEW_FEED_LIST = "EVIS_AVOC_VIEW_FEED_LIST";
export const evisActionTypes = {
    EVIS_ACTIVE_CHECKED_IDS,
    EVIS_CHECKBOX_SECTION_DATA,
    EVIS_SHOW_ID_DATA,
    EVIS_SHOW_CHECKBOX_DATA,
    EVIS_ID_URL_PARAMS_QUEUE,
    EVIS_ID_URL_PARAMS_ATTRIBUTES_QUEUE,
    EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA,
    EVIS_ID_DATA,
    SET_EVIS_ATTRIBUTE_STATE,
    EVIS_SET_ATTRIBUTE_STATE,
    EVIS_AVOC_VIEW_FEED_LIST
}