import ShareIcon from "@mui/icons-material/Share";
import { Fab, Snackbar, styled, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { GlobalContext } from '../../contexts/globalContextStore/GlobalContextStore';
import { handleSnapShotShare } from '../../js/utilities.js/utils';

const StyledFab = styled(Fab)(({ theme }) => ({
    backgroundColor: "#0f0f0f",
    color: "#ff9100",
    // size: "small",
    opacity: 0.6,
    // on hover
    "&:hover": {
        backgroundColor: "#2c1b03",
        // size: "small",
        Opacity: "0.8"
    },
}));


/**
 * Snapshot component to snapshot to share the current menu.
 * @returns {JSX.Element} The rendered component.
 */
export const Snapshot = () => {
    const { storeState } = useContext(GlobalContext);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    return (
        <>
            <StyledFab
                color="inherit"
                aria-label="share"
                // sx={snapshotStyle.floatingIcon}
                size="small"
                onClick={
                    () => handleSnapShotShare(
                        storeState, setSnackBarOpen)
                }
            >
                <Tooltip
                    enterNextDelay={500}
                    title="Share this menu"
                    arrow
                >
                    <ShareIcon />
                </Tooltip>
            </StyledFab>
            <Snackbar
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                autoHideDuration={1000}
                message="Copied to clipboard"
            />
        </>
    );
};
