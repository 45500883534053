import { Delete } from "@mui/icons-material";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { StyledCancelIcon, StyledLensIcon } from "../../CustomComponents";
import selectedFeedsViewStyles from "./selectedFeedsViewStyles";

/**
 * The CheckUnCheckDeleteAll component renders the "Check All" checkbox and the "Delete All" button.
 *
 * @param {object} props - The component props.
 * @param {array} props.checkedFeeds - The list of checked feeds.
 * @param {array} props.selectedFeeds - The list of selected feeds.
 * @param {function} props.handleCheckAll - The function to handle the "Check All" checkbox change.
 * @param {function} props.handleDeleteAll - The function to handle the "Delete All" button click.
 * @returns {JSX.Element} - The rendered CheckUnCheckDeleteAll component.
 */
const CheckUnCheckDeleteAll = ({ checkedFeeds, selectedFeeds, handleCheckAll, handleDeleteAll }) => {
    return (
        <React.Fragment>
            <Box sx={selectedFeedsViewStyles.container}>
                <FormControlLabel
                    componentsProps={{ typography: { variant: 'caption', color: '#a89587' } }}
                    control={
                        <Checkbox
                            checked={checkedFeeds.length === selectedFeeds.length}
                            size="small"
                            name="checkAll"
                            onChange={handleCheckAll}
                            sx={selectedFeedsViewStyles.checkbox}
                        />
                    }
                    label="Check All"
                    labelPlacement="end"
                />
                <Tooltip title="Delete all selected feeds">
                    <IconButton aria-label="Delete All" onClick={handleDeleteAll}>
                        <Delete fontSize="caption" sx={selectedFeedsViewStyles.iconButton} />
                    </IconButton>
                </Tooltip>
            </Box>
        </React.Fragment>
    );
};
CheckUnCheckDeleteAll.propTypes = {
    checkedFeeds: PropTypes.array.isRequired,
    selectedFeeds: PropTypes.array.isRequired,
    handleCheckAll: PropTypes.func.isRequired,
    handleDeleteAll: PropTypes.func.isRequired,
};

/**
 * The SelectedFeedsCheckboxSection component renders the checkboxes for the selected feeds.
 *
 * @param {object} props - The component props.
 * @param {array} props.selectedFeeds - The list of selected feeds.
 * @param {array} props.checkedFeeds - The list of checked feeds.
 * @param {function} props.handleCheckboxSelect - The function to handle the checkbox selection.
 * @param {function} props.handleCheckboxDelete - The function to handle the checkbox deletion.
 * @param {string} [props.propertyToGetFeedName] - The propertyToGetFeedName to use for the feed label.
 * @param {string} [props.connectionStatusValue] - The propertyToGetFeedName to use for the connection status.
 * @returns {JSX.Element} - The rendered SelectedFeedsCheckboxSection component.
 */
const SelectedFeedsCheckboxSection = ({ selectedFeeds, checkedFeeds, handleCheckboxSelect, handleCheckboxDelete, propertyToGetFeedName, connectionStatusValue = null }) => {
    return (
        <>
            {selectedFeeds.map((feed) => (
                <div
                    key={propertyToGetFeedName ? feed[propertyToGetFeedName] : feed}
                    style={selectedFeedsViewStyles.feedContainer}
                >
                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <FormControlLabel
                            componentsProps={{ typography: { variant: 'caption', color: '#a89587' } }}
                            control={
                                <Checkbox
                                    checked={checkedFeeds.includes(propertyToGetFeedName ? feed[propertyToGetFeedName] : feed)}
                                    size="small"
                                    name={propertyToGetFeedName ? feed[propertyToGetFeedName] : feed}
                                    onChange={handleCheckboxSelect}
                                    sx={selectedFeedsViewStyles.checkbox}
                                />
                            }
                            label={propertyToGetFeedName ? feed[propertyToGetFeedName] : feed}
                            labelPlacement="end"
                            sx={selectedFeedsViewStyles.feedLabel} // Allow label to grow and wrap
                        />
                        <IconButton
                            onClick={() => handleCheckboxDelete(propertyToGetFeedName ? feed[propertyToGetFeedName] : feed)}
                            sx={selectedFeedsViewStyles.deleteButton}
                        >
                            <StyledCancelIcon />
                        </IconButton>
                    </div>
                    {connectionStatusValue !== null && connectionStatusValue !== undefined && (
                        <IconButton
                            sx={selectedFeedsViewStyles.connectionStatusIcon(feed[connectionStatusValue] === "connected")}
                        >
                            <Tooltip title={feed[connectionStatusValue] === "connected" ? "Connected" : "Ready"}>
                                <StyledLensIcon fontSize="small" />
                            </Tooltip>


                        </IconButton>
                    )}
                </div>
            ))}
        </>
    );
};
SelectedFeedsCheckboxSection.propTypes = {
    selectedFeeds: PropTypes.array.isRequired,
    checkedFeeds: PropTypes.array.isRequired,
    handleCheckboxSelect: PropTypes.func.isRequired,
    handleCheckboxDelete: PropTypes.func.isRequired,
    propertyToGetFeedName: PropTypes.string,
    connectionStatusValue: PropTypes.string,
};

/**
 * The SelectedFeedsView component renders the selected feeds view.
 * It includes the "Check All" checkbox, the "Delete All" button, and the selected feeds checkboxes.
 * 
 * @param {selectedFeeds} - The list of selected feeds(checked/unchecked)
 * @param {checkedFeeds} - The list of checked feeds.
 * @param {handleCheckAll} - The function to handle the "Check All" checkbox change.
 * @param {handleCheckboxSelect} - The function to handle the checkbox selection.
 * @param {message} - The message to display when no feeds are selected.
 * @param {handleDeleteAll} - The function to handle the "Delete All" button click.
 * @param {handleCheckboxDelete} - The function to handle the checkbox deletion.
 * @param {propertyToGetFeedName} - The propertyToGetFeedName to use for the feed label.
 * @param {connectionStatusProperty} - The propertyToGetFeedName to use for the connection status.
 * 
 * @returns {JSX.Element} - The rendered SelectedFeedsView component.
 */
const SelectedFeedsView = ({ selectedFeeds, checkedFeeds, handleCheckAll, handleCheckboxSelect, message, handleDeleteAll, handleCheckboxDelete, propertyToGetFeedName, connectionStatusProperty }) => {
    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" sx={{ fontSize: "12px" }}>
                {selectedFeeds.length === 0 ? (
                    <Box sx={selectedFeedsViewStyles.messageBox}>
                        {message}
                    </Box>
                ) : (
                    <div>
                        <CheckUnCheckDeleteAll
                            checkedFeeds={checkedFeeds}
                            selectedFeeds={selectedFeeds}
                            handleCheckAll={handleCheckAll}
                            handleDeleteAll={handleDeleteAll}
                        />
                        <SelectedFeedsCheckboxSection
                            selectedFeeds={selectedFeeds}
                            checkedFeeds={checkedFeeds}
                            handleCheckboxSelect={handleCheckboxSelect}
                            handleCheckboxDelete={handleCheckboxDelete}
                            propertyToGetFeedName={propertyToGetFeedName}
                            connectionStatusValue={connectionStatusProperty}

                        />
                    </div>
                )}
            </FormGroup>
        </FormControl>
    );
};
SelectedFeedsView.propTypes = {
    selectedFeeds: PropTypes.array.isRequired,
    checkedFeeds: PropTypes.array.isRequired,
    handleCheckAll: PropTypes.func.isRequired,
    handleCheckboxSelect: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    handleDeleteAll: PropTypes.func.isRequired,
    handleCheckboxDelete: PropTypes.func.isRequired,
    propertyToGetFeedName: PropTypes.string,
    connectionStatusProperty: PropTypes.string,
};

export default SelectedFeedsView;
