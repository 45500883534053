import { genericActionTypes } from "../generic/genericActionTypes";
import { generateUniqueStreamPositon } from "../generic/streamPosition";
/**
 * Reducer function to handle the state of active checked EVIS feeds.
 * This function processes various operations on the `activeCheckedEvisFeeds` state,
 * such as adding, removing, or resetting the list of active feeds.
 * 
 * - no duplicates are allowed in the list 
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisActiveCheckedItems = (state, action) => {
    switch (action.operation) {
        case genericActionTypes.OPERATION_ADD:
            if (!state.activeCheckedEvisFeeds.includes(action.feed)) {
                return {
                    ...state,
                    activeCheckedEvisFeeds: [...state.activeCheckedEvisFeeds, action.feed],
                };
            }
            return state;
        case "addAll":
            return {
                ...state,
                activeCheckedEvisFeeds: [...action.feeds],
            };
        case "remove":
            return {
                ...state,
                activeCheckedEvisFeeds: [...state.activeCheckedEvisFeeds.filter((o) => o !== action.feed)],
            };
        case "reset":
            return {
                ...state,
                activeCheckedEvisFeeds: [],
            };
        default:
            return state;
    }
}


/**
 * Reducer function to handle the state of selected EVIS feeds in the checkbox section.
 * This function processes various operations on the `selectedEvisIdsCheckboxData` state,
 * such as adding or removing selected feeds.
 * 
 * - no duplicates are allowed in the list
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisSelectedIdToCheckBoxSectionData = (state, action) => {
    if (action.operation === genericActionTypes.OPERATION_ADD) {
        if (Array.isArray(action.addSelectedEvisFeedToCheckBoxSectionData)) {
            const newSelectedEvisIdsCheckboxData = [...state.selectedEvisIdsCheckboxData];
            for (const feed of action.addSelectedEvisFeedToCheckBoxSectionData) {
                if (newSelectedEvisIdsCheckboxData.findIndex((item) => item.evisIdName === feed.evisIdName) === -1) {
                    newSelectedEvisIdsCheckboxData.push(feed);
                }
            }
            return {
                ...state,
                selectedEvisIdsCheckboxData: newSelectedEvisIdsCheckboxData,
            };
        }
        return state;
    } else if (action.operation === "remove") {
        const currentState = state;
        const removeSelectedEvisFeed = action.removeSelectedEvisFeedFromCheckBoxSectionData;

        // if removeSelectedEvisFeed is an array
        if (Array.isArray(removeSelectedEvisFeed)) {
            const currentSelectedEvisIdsCheckboxData = currentState.selectedEvisIdsCheckboxData;
            const newSelectedEvisIdsCheckboxData = currentSelectedEvisIdsCheckboxData.filter(
                (item) => !removeSelectedEvisFeed.includes(item.evisIdName)
            );
            const newState = {
                ...currentState,
                selectedEvisIdsCheckboxData: newSelectedEvisIdsCheckboxData,
            };
            return newState;
        }
        const currentSelectedEvisIdsCheckboxData = currentState.selectedEvisIdsCheckboxData;
        const newSelectedEvisIdsCheckboxData = currentSelectedEvisIdsCheckboxData.filter(
            (item) => item.evisIdName !== removeSelectedEvisFeed
        );
        const newState = {
            ...currentState,
            selectedEvisIdsCheckboxData: newSelectedEvisIdsCheckboxData,
        };
        return newState;

    }

    return state;
}

/**
 * Reducer function to handle the state of the checkbox data display.
 * This function processes the action to show or hide the checkbox data.
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisShowCheckboxData = (state, action) => {
    return {
        ...state,
        showEvisCheckboxData: action.showEvisCheckboxData,
    };
}

/**
 * Reducer function to handle the state of the EVIS ID data display.
 * This function processes various operations on the `checkedEvisIdsStreams` state,
 * such as adding or removing EVIS IDs.
 * 
 * - no duplicates are allowed in the list
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisIdData = (state, action) => {
    switch (action.operation) {
        case genericActionTypes.OPERATION_ADD:
            const { showThisEvisId } = action;

            if (Array.isArray(showThisEvisId) && showThisEvisId.length > 0) {
                // Filter out existing streams that match any in the showThisEvisId array
                const newCheckedEvisIdsStreams = state.checkedEvisIdsStreams.filter((item) => {
                    return !showThisEvisId.some((streamObj) => streamObj.stream_id === item.streamObject.stream_id);
                });

                const newOrder = generateUniqueStreamPositon();

                for (const streamObject of showThisEvisId) {
                    // Check for duplicates using the correct property name
                    if (!newCheckedEvisIdsStreams.some((item) => item.streamObject.stream_id === streamObject.stream_id)) {
                        newCheckedEvisIdsStreams.push({
                            order: newOrder,
                            streamObject: streamObject
                        });
                    }
                }

                return {
                    ...state,
                    checkedEvisIdsStreams: newCheckedEvisIdsStreams,
                    order: newOrder,
                };
            }

            return state;



        case "remove":
            const updatedEvisIdList = state.checkedEvisIdsStreams.filter((o) => {
                return !action.removeThisEvisId.some((streamObj) => streamObj.stream_id === o.streamObject.stream_id);
            });
            return {
                ...state,
                checkedEvisIdsStreams: updatedEvisIdList,
            };
        default:
            return state;

    }


}

/**
 * Reducer function to handle the state of the EVIS ID data display.
 * This function processes the action to show or hide the EVIS ID data.
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisShowIdData = (state, action) => {
    return {
        ...state,
        showEvisIdData: action.showEvisIdData,
    };
}


/**
 * Reducer function to handle the state of the EVIS attribute state.
 * This function processes the action to update the EVIS attribute state.
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisSetAttributeState = (state, action) => {
    return {
        ...state,
        evisAttributeState: {
            ...state.evisAttributeState,
            ...action.newEvisAttributeState,
        },
    };
}


/**
 * Reducer function to handle the state of the EVIS URL parameters(attributes).
 * This function processes the action to update the EVIS URL parameters.
 * 
 * @param {*} state
 * @param {*} action
 * @returns the new state after processing the action
 */
export const handleEvisURLParamsIdAttrQueue = (state, action) => {
    return {
        ...state,
        urlParamsEvisAttrList: action.urlParamEvisAttrList,
    };
}

/**
 * Reducer function to handle the state of the EVIS URL parameters(evisIds).
 * This function processes the action to update the EVIS URL parameters.
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisAddIdToUrlParamsQueue = (state, action) => {
    return {
        ...state,
        urlParamsEvisIdList: action.urlParamEvisIdList,
    };
}

/**
 * Reducer function to handle the state of the EVIS AVOC view feed list.
 * This function processes various operations on the `avocViewEvisIdList` state,
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns the new state after processing the action
 */
export const handleEvisAvocViewFeedList = (state, action) => {
    switch (action.operation) {
        case genericActionTypes.OPERATION_ADD:
            if (!state.avocViewEvisIdList.includes(action.newAvocViewEvisId)) {
                return {
                    ...state,
                    avocViewEvisIdList: [...state.avocViewEvisIdList, action.newAvocViewEvisId],
                };
            }
            return state;
        case genericActionTypes.OPERATION_REMOVE:
            return {
                ...state,
                avocViewEvisIdList: [
                    ...state.avocViewEvisIdList.filter((o) => !action.removeThisAvocViewEvisId.includes(o))],
            };

        default:

            return state;

    }



}