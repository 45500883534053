import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import { Avatar, IconButton, Toolbar, Tooltip, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import React, { useContext } from "react";
import { genericActionTypes } from "../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import V1SidebarContents from "../sidebar/SidebarContents";
import leftMenuDrawerStyles from "./leftMenuDrawerStyles";

/**
 * The LeftMenuDrawer component is used to display the left side menu.
 * In this component, you're able to adjust the width of the drawer.
 * The content of the drawer is displayed in the V1SidebarContents component.
 * @returns {JSX.Element} The LeftMenuDrawer component
 */
export default function LeftMenuDrawer() {

    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;

    const { openSideBar } = genericStates;

    /**
     * Handles the closing of the side menu.
     */
    const closeSideMenu = () => {
        storeDispatch({ type: genericActionTypes.OPEN_SIDEBAR, openSideBar: false });
    };

    return (
        <Drawer
            sx={leftMenuDrawerStyles.drawerStyle}
            variant="persistent"
            anchor="left"
            open={openSideBar}
            elevation={100}
        >
            <Toolbar
                sx={leftMenuDrawerStyles.drawerToolbar}
                variant="dense"
                position="static"
            >

                <Avatar edge="start" sx={{ bgcolor: "#FF9900", mr: 2, opacity: 0.6, width: 36, height: 36 }}>
                    <TheatersOutlinedIcon sx={{ color: "#FFFF", opacity: 1.0, fontWeight: "bold", }} />
                </Avatar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "#A0A0A0", fontWeight: "bold" }}>
                    StreamPreview
                </Typography>

                <IconButton
                    color="inherit"
                    aria-label="close drawer"
                    onClick={closeSideMenu}
                    size="small"
                    disabled={genericStates.dashboardFeedQueryData.isDbQueryLoading ? true : false}
                >
                    <Tooltip
                        enterNextDelay={1000}
                        title="Close this menu"
                        arrow
                    >
                        <CloseTwoToneIcon sx={{ marginLeft: "50px" }} />
                    </Tooltip>
                </IconButton>
            </Toolbar>

            <Divider sx={leftMenuDrawerStyles.dividerStyle} />
            <V1SidebarContents />
        </Drawer>
    );
}