import {
    AppBar,
    Button,
    CardActions,
    CardContent,
    Container,
    CssBaseline,
    Grid,
    Toolbar,
    Typography
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { genericActionTypes } from "../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import { isLoggerEnabled } from "../../js/utilities.js/logger";
import DashboardTheme from "../DashboardTheme";
import loginStyles from "./loginPageStyles";

// Memoized LoginToolbar to prevent unnecessary re-renders
/**
 * Renders the login toolbar with the application title.
 *
 * @returns {JSX.Element} - A React component that displays the login toolbar.
*/
const LoginToolbar = React.memo(() => (
    <AppBar position="fixed" sx={{ alignItems: 'center' }}>
        <Toolbar>
            <Typography
                variant="h4"
                color="inherit"
                noWrap
            // sx={loginStyles.loginToolbar}
            >
                <strong>{"StreamPreview"}</strong>
            </Typography>
        </Toolbar>
    </AppBar>
));

/**
 * Renders the login page for the application.
 *
 * @returns {JSX.Element} - A React component that displays the login page.
 */
export const Login = () => {
    const { storeDispatch } = useContext(GlobalContext);
    const locationData = useLocation();

    // signIn handler function
    const signIn = async (e) => {
        e.preventDefault();
        try {
            isLoggerEnabled && console.log("Logging in via AWS-Federate");

            const locationDataUrl = JSON.stringify(locationData);
            isLoggerEnabled && console.log("locationDataUrl: ", locationDataUrl);

            localStorage.setItem("url", locationDataUrl); // Save URL data
            storeDispatch({
                type: genericActionTypes.SET_URL_DATA,
                urlData: locationDataUrl,
            });
            await Auth.federatedSignIn({ provider: "AWS-Federate" });
        } catch (error) {
            console.error("Error signing in", error);
            // Add error handling logic here to inform the user, if necessary
        }
    };

    return (
        <ThemeProvider theme={DashboardTheme()}>
            <Container>
                <CssBaseline />
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={loginStyles.container}
                >
                    <LoginToolbar />
                    <CardContent>
                        <Typography variant="h4" component="div" gutterBottom mb={4}>
                            {"Please Login"}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button sx={loginStyles.loginButton} variant="outlined" onClick={signIn}>
                            {"Login"}
                        </Button>
                    </CardActions>
                </Grid>
            </Container>
        </ThemeProvider>
    );
};