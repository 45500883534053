import { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import { useHandleEncoderFeedsParam } from "../../encoder/js/encoder/hooks/EncoderCustomHooks";
import { useHandleEvisFeedsParam } from "../../evis/js/hooks/EvisCustomHooks";

/**
 * Component that processes web URL feeds based on the global state.
 * Utilizes custom hooks to handle encoder and Evis feeds.
 * 
 * @returns {JSX.Element} - Empty element as the component is used for side-effects only.
 */
export function ProcessWebUrlFeeds() {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates, evisStates, zixiStates } = storeState;

    const { urlParamsFeedList } = zixiStates;
    const { urlParamsEvisIdList } = evisStates;
    const { dashboardFeedQueryData } = genericStates;

    const processEncoderData = useHandleEncoderFeedsParam(urlParamsFeedList, dashboardFeedQueryData, storeDispatch);
    const processEvisData = useHandleEvisFeedsParam(urlParamsEvisIdList, dashboardFeedQueryData, storeDispatch);

    useEffect(() => {
        processEncoderData();
        processEvisData();
    }, [processEncoderData, processEvisData]);

    return null;
}
