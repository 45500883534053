import { CardMedia, Link, Typography } from "@mui/material";
import React, { useContext } from "react";
import { StreamViewCard } from "../../../commonComponents/reusableComponents/streamViewCard/StreamViewCard";
import { GlobalContext } from "../../../contexts/globalContextStore/GlobalContextStore";
import { getEvisStreamThumbnailData } from "../../../encoder/js/encoder/utils/dashboardUtils";
import { useEvisAttributesById, useEvisThumbnailApiQuery } from "../../js/hooks/useEvisQueryUtils";
import { getBitrate, getInputType } from "../../js/utils/evisUtils";
import evisStreamViewCardStyles from "./evisStreamViewCardStyles";

// Helper function to determine card box shadow based on streamId
const getCardShadow = (streamId) => {
    if (streamId.includes("dub-grp") || streamId.includes("lhr-grp")) {
        // shadow for 'dub-grp' and 'lhr-grp'
        return '0px 4px 8px rgba(185, 185, 180, 0.5), 0px -4px 8px rgba(59, 58, 53, 0.3)';
    }
    if (streamId.includes("dub-prim") || streamId.includes("lhr-prim")) {
        // shadow for 'dub-prim' and 'lhr-prim'
        return '0px 4px 8px rgba(8, 155, 200, 0.5), 0px -4px 8px rgba(3, 44, 92, 0.3)';
    }
    return '0px 4px 8px rgba(134, 95, 48, 0.5), 0px -4px 8px rgba(120, 61, 24, 0.3)';

};

/**
 * Renders a card view for an EVIS stream using provided stream object data.
 * This component fetches necessary attributes and thumbnail data via custom hooks and
 * conditionally renders various stream details 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.streamObject - The object representing the EVIS stream data.
 * @returns {JSX.Element} The rendered stream view card component.
 */
export const EvisStreamViewCard = (props) => {
    const { storeState } = useContext(GlobalContext);
    const { evisStates } = storeState;
    const { evisAttributeState } = evisStates;

    const evisIdAttributesApiQueryResult = useEvisAttributesById(props.streamObject);
    const evsiIdAttributes = evisIdAttributesApiQueryResult.data;
    const thumbnailApiQueryResult = useEvisThumbnailApiQuery(props.streamObject);

    /**
     * Renders the content inside the stream view card for EVIS streams.
     * It conditionally displays various attributes and a thumbnail image based on the EVIS state.
     * 
     * @function
     * @returns {JSX.Element} The JSX content for the EVIS stream card.
     */
    const EvisCardContent = () => {
        return (
            <React.Fragment>
                <StreamIdTypography streamId={props.streamObject.stream_id} />
                <CEIDTypography ceid={evsiIdAttributes?.ceid} visible={evisAttributeState.ceid} />
                <HostUrlLink host={props.streamObject.host} visible={evisAttributeState.host_url} />
                <ThumbnailImage thumbnailData={thumbnailApiQueryResult.data} />
                <InputTypeTypography inputType={getInputType(evsiIdAttributes)} visible={evisAttributeState.input_type} />
                <BitrateTypography bitrate={getBitrate(evsiIdAttributes)} visible={evisAttributeState.bitrate} />
                <InputErrorTypography inputError={evsiIdAttributes?.["evis.input_error"]} visible={evisAttributeState.input_error} />
                <LatencyTypography latency={evsiIdAttributes?.["net_recv.latency"]} visible={evisAttributeState.latency} />
            </React.Fragment>
        );
    };

    return (
        <StreamViewCard
            queryResult={thumbnailApiQueryResult}
            firstHalfCardContent={<EvisCardContent />}
            attributeData={evsiIdAttributes}
            cardShadow={getCardShadow(props.streamObject.stream_id)}
        />
    );
};

/**
 * Renders the stream ID as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.streamId - The ID of the stream to display.
 * @returns {JSX.Element} The rendered typography element for the stream ID.
 */
const StreamIdTypography = ({ streamId }) => (
    <Typography variant="caption" display="block">
        {streamId}
    </Typography>
);

/**
 * Renders the CEID as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.ceid - The CEID value to display.
 * @param {boolean} props.visible - Determines whether the CEID should be displayed.
 * @returns {JSX.Element|null} The rendered typography element for the CEID, or null if not visible.
 */
const CEIDTypography = ({ ceid, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            CEID: {ceid || "N/A"}
        </Typography>
    ) : null
);

/**
 * Renders a link to the EVIS host URL.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.host - The host name for the EVIS stream.
 * @param {boolean} props.visible - Determines whether the link should be displayed.
 * @returns {JSX.Element|null} The rendered link element for the host URL, or null if not visible.
 */
const HostUrlLink = ({ host, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            {"EVIS: "}
            <Link
                sx={{ fontSize: "0.8rem" }}
                href={`http://${host}.prod.evis.amazonavoc.com`}
                target="_blank"
            >
                {`${host}.prod.evis`}
            </Link>
        </Typography>
    ) : null
);

/**
 * Renders a thumbnail image for the EVIS stream.
 * If the thumbnail data is not available, a placeholder image is used.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.thumbnailData - The data used to generate the thumbnail image.
 * @returns {JSX.Element} The rendered image element for the thumbnail.
 */
const ThumbnailImage = ({ thumbnailData }) => {
    const imageData = getEvisStreamThumbnailData(thumbnailData).thisStreamThumbnailData;
    const imageSrc = imageData ? `data:image/jpeg;base64,${window.btoa(imageData)}` : "data:image/jpeg;base64," + window.btoa("public/NoSignal.jpg"); //TODO: Change to use a placeholder image

    return (
        <CardMedia
            component="img"
            sx={evisStreamViewCardStyles.cardMedia}
            image={imageSrc}
            alt="Event Thumbnail Image"
        />
    );
};


/**
 * Renders the input type of the EVIS stream as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.inputType - The input type value to display.
 * @param {boolean} props.visible - Determines whether the input type should be displayed.
 * @returns {JSX.Element|null} The rendered typography element for the input type, or null if not visible.
 */
const InputTypeTypography = ({ inputType, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            {'Input Type: '}{inputType || "N/A"}
        </Typography>
    ) : null
);

/**
 * Renders the bitrate of the EVIS stream as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.bitrate - The bitrate value to display.
 * @param {boolean} props.visible - Determines whether the bitrate should be displayed.
 * @returns {JSX.Element|null} The rendered typography element for the bitrate, or null if not visible.
 */
const BitrateTypography = ({ bitrate, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            {"Bitrate: "}{bitrate || "N/A"}
        </Typography>
    ) : null
);

/**
 * Renders the input error status of the EVIS stream as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.inputError - The input error value to display.
 * @param {boolean} props.visible - Determines whether the input error should be displayed.
 * @returns {JSX.Element|null} The rendered typography element for the input error, or null if not visible.
 */
const InputErrorTypography = ({ inputError, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            {"Input Error: "}{inputError || "N/A"}
        </Typography>
    ) : null
);

/**
 * Renders the latency of the EVIS stream as a typography element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.latency - The latency value to display.
 * @param {boolean} props.visible - Determines whether the latency should be displayed.
 * @returns {JSX.Element|null} The rendered typography element for the latency, or null if not visible.
 */
const LatencyTypography = ({ latency, visible }) => (
    visible ? (
        <Typography variant="caption" display="block">
            {"Latency: "}{latency || "N/A"}
        </Typography>
    ) : null
);