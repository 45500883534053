import { createTheme } from "@mui/material";


/**
 * Creates a custom Material-UI theme for a dashboard application in dark mode.
 *
 * @returns {object} - A Material-UI theme object.
 */
const DashboardTheme = () =>
  createTheme({
    palette: {
      primary: {
        main: "#d18410",
        dark: "#e8dd0a",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
      background: {
        default: "#000000",
        paper: "#000000"
      },
      text: {
        primary: "#dfe5d9",
        secondary: "#a89587",
      },
      icons: "#FF9900"
    },
  });

export default DashboardTheme;