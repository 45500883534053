import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import { EncoderStreamViewCard } from "../../encoder/components/streamviewer/EncoderStreamViewCard";
import { ZixiStreamViewCard } from "../../encoder/components/streamviewer/ZixiStreamViewCard";
import { getArchTypeFromStream, isFeed } from "../../encoder/js/encoder/utils/dashboardUtils";
import { EvisStreamViewCard } from "../../evis/components/streamviewer/EvisStreamViewCard";
import { CustomLabelCard } from "../reusableComponents/customLabelCard/CustomLabelCard";
import streamViewerStyles from "./streamViewerStyles";
/**
 * If the given string is a stream name, get it's architecture type and return the corresponding card.
 * @param {*} param0 
 * @returns 
 */
const ShowStreamViewCard = ({ streamName }) => {
    const { storeState } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;

    if (!isFeed(streamName)) {
        let thisArchType = getArchTypeFromStream(streamName, dashboardFeedQueryData.dbQueryData[0]);

        if (thisArchType === "zixi") {
            return <ZixiStreamViewCard key={streamName} streamName={streamName} />;
        } else if (thisArchType === "hw_encoder") {
            return <EncoderStreamViewCard key={streamName} streamName={streamName} />;
        } else {
            return <EvisStreamViewCard key={streamName} streamObject={streamName} />;
        }
    } else {
        return null;
    }
};


/**
 * Encoder data display component; If custom label field not enabled, show the stream cards. Else show the stream cards with custom label field.
 * @param {*} props
 * @returns {JSX} JSX representation of Encoder data display component
 */
export default function StreamViewer(props) {
    const { storeState } = useContext(GlobalContext);
    const { evisStates, zixiStates, genericStates } = storeState;

    const { feedNameList } = zixiStates;
    const { checkedEvisIdsStreams } = evisStates;
    const { customLabelEnabled } = genericStates;  // Boolean for enabling the additional card

    // combine feednamelist and checkedEvisIdsStreams and sort based on order property
    const sortedStreamObjects = [...feedNameList, ...checkedEvisIdsStreams].sort((a, b) => {
        return a.order - b.order;
    });

    const renderStreamViewCard = (streamObject, index, key) => (
        <React.Fragment key={key}>
            {index > 0 && sortedStreamObjects[index - 1].order !== streamObject.order && (
                <Divider sx={streamViewerStyles.feedDivider} />
            )}
            <Grid sx={streamViewerStyles.gridItem}>
                <ShowStreamViewCard streamName={streamObject.feed || streamObject.streamObject} />
            </Grid>
            {customLabelEnabled && (index === sortedStreamObjects.length - 1 || sortedStreamObjects[index + 1].order !== streamObject.order) && (
                <Grid sx={streamViewerStyles.gridItem}>
                    <CustomLabelCard streamObject={streamObject} />
                </Grid>
            )}
        </React.Fragment>
    );
    return (
        <Grid container spacing={3} sx={{ padding: 3 }}>
            {sortedStreamObjects.map((streamObject, index) => {
                const key = streamObject.feed || streamObject.streamObject.stream_id;
                return renderStreamViewCard(streamObject, index, key);
            })}
        </Grid>
    );
}