const architectureTabsStyles = {
    root: {
        width: '100%',
    },
    tabsContainer: {
        borderBottom: 1,
        borderColor: 'divider',
    },
    tab: {
        color: "#8d6447",
        textTransform: "none",
    },
};

export default architectureTabsStyles;