import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Chip, Divider, IconButton, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import favoriteSessionStyles from './favoriteSessionStyles';


/**
 * Displays saved session titles from localStorage and allows deleting them.
 */
const DisplayFavoriteSession = () => {
    const [sessions, setSessions] = useState([]); // State to store session titles and links

    // Function to fetch all sessions from localStorage
    const fetchSessions = () => {
        const savedSessions = [];

        // Loop through localStorage keys and find those starting with "session_"
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith("session_")) {
                const sessionTitle = key.replace("session_", ""); // Remove the "session_" prefix
                const sessionLink = localStorage.getItem(key);
                savedSessions.push({ title: sessionTitle, link: sessionLink });
            }
        }
        setSessions(savedSessions);
    };

    // Delete a session from localStorage and update the list
    const deleteSession = (title, event) => {
        event.preventDefault(); // Prevent refresh
        event.stopPropagation(); // Prevent triggering the session click event
        localStorage.removeItem(`session_${title}`);
        fetchSessions();
    };

    // Fetch sessions on component mount and whenever localStorage is updated
    useEffect(() => {
        fetchSessions();

        const handleStorageChange = (e) => {
            if (e.key && e.key.startsWith("session_")) {
                fetchSessions();
            }
        };

        // Add event listener for localStorage changes
        window.addEventListener("storage", handleStorageChange);

        // Cleanup event listener when component is unmounted
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    // Handle click event to navigate to the session's link within the same page
    const handleSessionClick = (link) => {
        window.location.href = link;
    };

    return (
        <div>
            {sessions.length === 0 ? (
                <Typography variant="body2" color="textSecondary">
                    No saved sessions found.
                </Typography>
            ) : (
                <List>
                    {sessions.map((session, index) => (
                        <>
                            <ListItem
                                key={`${session.title}-${index}`}
                                secondaryAction={
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={(e) => deleteSession(session.title, e)}
                                    >
                                        <DeleteIcon sx={{ color: "#d10202" }} />
                                    </IconButton>
                                }
                                onClick={() => handleSessionClick(session.link)}
                            >
                                {/* <CircleIcon sx={favoriteSessionStyles.bullet} /> */}
                                <Chip
                                    avatar={<Avatar>{session.title ? session.title.substring(0, 1).toUpperCase() : ""}</Avatar>}
                                    label={session.title}
                                    onClick={() => handleSessionClick(session.link)}
                                />
                                {/* <ListItemText primary={session.title} sx={favoriteSessionStyles.favoriteItemText} /> */}
                            </ListItem>

                            <Divider sx={favoriteSessionStyles.divider} />

                        </>

                    ))}
                </List>
            )}
        </div>
    );
};

export default DisplayFavoriteSession;
