import { makeAPICall } from "../../../../js/utilities.js/apiUtils";

const path = "streampreview_dashboard_api/nbazixi"
const getZixiStreamConfig = async (params) => makeAPICall(path, "get_zixi_stream_config", params);
const getZixiInputId = async (params) => makeAPICall(path, "get_zixi_input_id", params);
const getZixiThumbnailData = async (params) => makeAPICall(path, "get_zixi_thumbnail_binary", params);
const getZixiInputStreamStats = async (params) => makeAPICall(path, "get_zixi_input_stream_stats", params);
const getZixiThumbnailUrl = async (params) => makeAPICall(path, "get_zixi_thumbnail_url", params);

export {
    // used in useZixiQueryUtils
    getZixiInputId, getZixiInputStreamStats,
    getZixiStreamConfig, getZixiThumbnailData, getZixiThumbnailUrl
};

// TODO: