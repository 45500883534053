import * as React from 'react';
import { GlobalContext } from '../../contexts/globalContextStore/GlobalContextStore';
import EncoderAttributes from '../../encoder/components/sidebar/attributes/EncoderAttributes';
import { useEncoderAttrParamHandler } from '../../encoder/js/encoder/hooks/EncoderCustomHooks';
import EvisAttributes from '../../evis/components/sidebar/attributes/EvisAttributes';
import { useEvisAttrParamHandler } from '../../evis/js/hooks/EvisCustomHooks';
import ArchitectureTabs from '../reusableComponents/architectureTabs/ArchitectureTabs';


/**
 * Responsible for handling the URL attributes for the Encoder and EVIS components.
 * It updates the global state with the attributes from the URL params list.
 * Used when the page is loaded with URL parameters for the first time.
 * @returns {null} Returns null.
 */
const WebUrlAttrHandler = () => {
    const { storeState, storeDispatch } = React.useContext(GlobalContext);
    const { evisStates, zixiStates } = storeState;

    useEncoderAttrParamHandler(zixiStates.urlParamsAttrList, storeDispatch);
    useEvisAttrParamHandler(evisStates.urlParamsEvisAttrList, storeDispatch);

    return null;
};

/**
 * Responsible for displaying all the attributes of the Encoder and EVIS components.
 * It renders the Encoder and EVIS attributes components.
 * @param {int} value - The current tab index.
 * @param {function} handleTabChange - The function to handle tab change.
 * @returns {JSX.Element} The rendered AllAttributesDisplay component.
 */
export default function AllAttributesDisplay({ value, handleTabChange }) {
    const architectures = [
        { label: "OnPrem Infra", component: <EncoderAttributes /> },
        { label: "EVIS", component: <EvisAttributes /> },
        // ADD: Add more architectures here
    ];

    return (
        <>
            {WebUrlAttrHandler()}
            <ArchitectureTabs architectures={architectures} value={value} handleTabChange={handleTabChange} />
        </>
    );
}
