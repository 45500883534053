import LensIcon from '@mui/icons-material/Lens';
import { styled } from "@mui/material";


// StyledLensIcon component with dynamic color based on connection status
const StyledLensIcon = styled(LensIcon)(({ theme, color }) => ({
    fontSize: "0.8rem",
    color: color || theme.palette.text.primary, // Default to the primary text color if no color is provided
}));

const dropdownItemStyle = {
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const dropDownTextStyle = {
    overflow: 'hidden',
    fontSize: '12px',
}


const boxStyle = {
    m: 1, width: 235

};

const autoCompleteStyles = {
    lensIcon: StyledLensIcon,
    dropdownItem: dropdownItemStyle,
    box: {
        ...boxStyle,
        '& .MuiAutocomplete-inputRoot': {
            fontSize: '12px',
        },
        '& .MuiAutocomplete-option': {
            fontSize: '12px',
        },
        '& .MuiInputLabel-root': {
            fontSize: '12px',
        },
    },
    dropDownText: dropDownTextStyle,
    iconColor: { color: '#d18410' }
};

export default autoCompleteStyles;