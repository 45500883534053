import { Bookmark } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { GlobalContext } from '../../contexts/globalContextStore/GlobalContextStore';
import { handleSnapShotShare } from '../../js/utilities.js/utils';

import { Fab, styled } from "@mui/material";


const StyledFab = styled(Fab)(({ theme }) => ({
    backgroundColor: "#0f0f0f",
    color: "#ff9100",
    // size: "small",
    opacity: 0.6,
    // on hover
    "&:hover": {
        backgroundColor: "#2c1b03",
        // size: "small",
        Opacity: "0.8"
    },
}));

/**
 * FavoriteSession component to display a floating icon and save a session with a title.
 * Saves up to 5 sessions in localStorage and prevents duplicates.
 * @returns {JSX.Element} The rendered floating icon.
 */
export const FavoriteSession = () => {
    const [open, setOpen] = useState(false); // State to manage the dialog visibility
    const [sessionTitle, setSessionTitle] = useState(""); // State to store the session title
    const [errorMessage, setErrorMessage] = useState(""); // State for error messages

    const { storeState } = useContext(GlobalContext);

    // Fetch the current number of saved sessions in localStorage
    const getSavedSessionsCount = () => {
        return Object.keys(localStorage).filter((key) => key.startsWith("session_")).length;
    };

    // Check if the session title already exists in localStorage
    const isDuplicateSession = (title) => {
        return localStorage.getItem(`session_${title}`) !== null;
    };

    // Save the session to localStorage with title as key and link as value
    const saveSession = (title, link) => {
        // Check if session with the same title already exists
        if (isDuplicateSession(title)) {
            setErrorMessage("A session with this title already exists.");
            return;
        }

        // Check if the number of sessions is already at the limit (5)
        if (getSavedSessionsCount() >= 5) {
            setErrorMessage("You can only save up to 5 sessions.");
            return;
        }

        // Save new session with title as the key and link as the value
        localStorage.setItem(`session_${title}`, link);
        setErrorMessage(""); // Clear any existing errors
        setOpen(false); // Close the dialog after saving
    };

    // Handle the save button click and open the dialog
    const handleSaveClick = () => {
        setOpen(true); // Open the dialog for user input
    };

    // Handle dialog close
    const handleClose = () => {
        setOpen(false); // Close the dialog without saving
        setErrorMessage(""); // Clear any existing errors
    };

    // Handle save in the dialog
    const handleDialogSave = () => {
        const sessionLink = handleSnapShotShare(storeState)
        saveSession(sessionTitle, sessionLink);
    };

    return (
        <>
            <StyledFab
                aria-label="notes"
                size="small"
                onClick={handleSaveClick}
            // sx={favoriteSessionStyles.floatingIcon}
            >
                <Tooltip enterNextDelay={500} title="Add this session to my favorites" arrow>
                    <Bookmark />
                </Tooltip>
            </StyledFab>

            {/* Dialog for entering the session title */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Save Session</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter title for this session. Refresh the page to see your saved sessions.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="sessionTitle"
                        label="Session Title"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={sessionTitle}
                        onChange={(e) => setSessionTitle(e.target.value)}
                        error={!!errorMessage}
                        helperText={errorMessage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDialogSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};