import { disableLogger, enableLogger, isLoggerEnabled } from "../js/utilities.js/logger";

const prodConfig = {
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_l19Rh7lhY",
        userPoolWebClientId: "4o7ch38ekoldoqdg5c0jp1npmq",
        identityPoolId: "us-west-2:e2de8a64-a55e-4d8e-b395-5f4fbb876388",
        mandatorySignIn: false,
        oauth: {
            domain: "dv-live-streampreview.auth.us-west-2.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: "https://prod.streampreview.amazonavoc.com/",
            redirectSignOut: "https://prod.streampreview.amazonavoc.com/signout/",
            responseType: "code",
        },
    },
    API: {
        endpoints: [
            {
                name: "Prod",
                endpoint: "https://prod.api.amazonavoc.com/",
                region: "us-west-2",
            },
            {
                name: "Gamma",
                endpoint: "https://gamma.api.amazonavoc.com/",
                region: "us-west-2",
            }
        ],
    },
};

const gammaConfig = {
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_l19Rh7lhY",
        userPoolWebClientId: "2lop0io96oo4r01m9fqu5lcrs5",
        identityPoolId: "us-west-2:e2de8a64-a55e-4d8e-b395-5f4fbb876388",
        mandatorySignIn: false,
        oauth: {
            domain: "dv-live-streampreview.auth.us-west-2.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: "https://gamma.streampreview.amazonavoc.com/",
            redirectSignOut: "https://gamma.streampreview.amazonavoc.com/signout/",
            responseType: "code",
        },
    },
    API: {
        endpoints: [
            {
                name: "Prod",
                endpoint: "https://prod.api.amazonavoc.com/",
                region: "us-west-2",
            },
            {
                name: "Gamma",
                endpoint: "https://gamma.api.amazonavoc.com/",
                region: "us-west-2",
            }
        ],
    },
};

const localConfig = {
    API: {
        endpoints: [
            {
                name: "local",
                endpoint: "http://localhost:9999/",
                region: "us-west-2",
            }
        ],
    },
};

// Enable or disable logger based on environment variable
// If enabled, logging will be displayed in the console
const debugEnvOn = process.env.REACT_APP_DEBUG_ENVIRONMENT === 'true' ? true : false;
debugEnvOn ? enableLogger() : disableLogger();
isLoggerEnabled && console.log("Debug environment enabled");

//Initialize local variables
let envConfig;
isLoggerEnabled && console.log("Node ENV", process.env.NODE_ENV);
isLoggerEnabled && console.log("React App ENV", process.env.REACT_APP_API_ENVIRONMENT);

const prodEnvOn = process.env.NODE_ENV === "production" && process.env.REACT_APP_API_ENVIRONMENT === "Prod" ? true : false;
const gammaEnvOn = process.env.NODE_ENV === "production" && process.env.REACT_APP_API_ENVIRONMENT === "Gamma" ? true : false;
const localEnvOn = process.env.NODE_ENV === "development" && process.env.REACT_APP_API_ENVIRONMENT === "local" ? true : false;

//Auto Switch between different environments based on code branch and URL's
if (prodEnvOn) {
    isLoggerEnabled && console.log("Using Prod OAuth config and Prod API");
    envConfig = { ...prodConfig };
} else if (gammaEnvOn) {
    isLoggerEnabled && console.log("Using Gamma OAuth config and Gamma API");
    envConfig = { ...gammaConfig };
} else if (localEnvOn) {
    isLoggerEnabled && console.log("Using No Oauth and Local API config");
    envConfig = { ...localConfig };
}

export default envConfig;
