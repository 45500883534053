import { genericActionTypes } from "../generic/genericActionTypes";
import { generateUniqueStreamPositon } from "../generic/streamPosition";

export const handleZixiActiveCheckedItems = (state, action) => {
    switch (action.operation) {
        case genericActionTypes.OPERATION_ADD:
            return state.activeCheckedZixiFeeds.includes(action.feed) ? state : {
                ...state,
                activeCheckedZixiFeeds: [...state.activeCheckedZixiFeeds, action.feed],
            };
        case "addAll":
            return {
                ...state,
                activeCheckedZixiFeeds: [...new Set([...state.activeCheckedZixiFeeds, ...action.feeds])],
            };
        case "remove":
            return {
                ...state,
                activeCheckedZixiFeeds: state.activeCheckedZixiFeeds.filter((o) => o !== action.feed),
            };
        case "reset":
            return {
                ...state,
                activeCheckedZixiFeeds: [],
            };
        default:
            return state;
    }
}

export const handleZixiCheckboxSectionData = (state, action) => {
    if (action.operation === "reset") {
        return {
            ...state,
            checkBoxSectionData: [],
        };
    } else if (action.operation === genericActionTypes.OPERATION_ADD) {
        //Prevent duplicate entries
        if (typeof action.addToCheckBoxSectionData === "object") {
            const existingFeeds = new Set(state.checkBoxSectionData); // O(N) time, O(N) space
            const newFeeds = [];

            for (const feed of action.addToCheckBoxSectionData) {
                if (!existingFeeds.has(feed)) { // O(1) average time for Set lookup
                    existingFeeds.add(feed); // O(1) average time for Set insertion
                    newFeeds.push(feed); // Collect new feeds
                }
            }

            if (newFeeds.length > 0) {
                state = {
                    ...state,
                    checkBoxSectionData: [...state.checkBoxSectionData, ...newFeeds], // O(N + M) space
                };
            }
        }

    } else if (action.operation === "removeItem") {
        return {
            ...state,
            checkBoxSectionData: [...state.checkBoxSectionData.filter((o) => o !== action.removeFromCheckBoxSectionData)],
        };
    }
    return state;

}

export const handleZixiFeedData = (state, action) => {
    if (action.operation === "reset") {
        return {
            ...state,
            feedNameList: [],
        };
    } else if (action.operation === "remove") {

        const updatedFeedList = state.feedNameList.filter((o) => {
            return !action.streams.some((feed) => feed === o.feed);
        });
        return {
            ...state,
            feedNameList: updatedFeedList,
        };


    } else if (action.operation === genericActionTypes.OPERATION_ADD) {
        // Handle if array of streams
        if (Array.isArray(action.streams) && action.streams.length > 0) {
            const existingFeeds = new Set(state.feedNameList.map(item => item.feed));
            const uniqueStreams = action.streams.filter(feed => !existingFeeds.has(feed));

            if (uniqueStreams.length > 0) {
                const newOrder = generateUniqueStreamPositon();
                const updatedFeedNameList = [
                    ...state.feedNameList,
                    ...uniqueStreams.map(feed => ({ order: newOrder, feed }))
                ];

                state = {
                    ...state,
                    feedNameList: updatedFeedNameList,
                    order: newOrder,
                };
            }
        }
    }

    return state;
}

export const handleZixiAttributeState = (state, action) => {
    return {
        ...state,
        attributeState: {
            ...state.attributeState,
            ...action.newAttributeState,
        },
    };
}

export const handleZixiCheckboxDisplay = (state, action) => {
    return {
        ...state,
        showCheckboxData: action.showCheckboxData,
    };
}

export const handleZixiFeedDataDisplay = (state, action) => {
    return {
        ...state,
        showFeedData: action.showFeedData,
    };
}

export const handleZixiAvocViewFeedList = (state, action) => {
    if (action.operation === "reset") {
        return {
            ...state,
            avocViewFeedList: [],
        };
    } else if (action.operation === genericActionTypes.OPERATION_ADD) {
        // list of feeds
        for (const feed of action.newAvocViewFeed) {
            if (!state.avocViewFeedList.includes(feed)) {
                state = {
                    ...state,
                    avocViewFeedList: [...state.avocViewFeedList, feed],
                };
            }
        }
    } else if (action.operation === "removeItem") {
        return {
            ...state,
            avocViewFeedList: [...state.avocViewFeedList.filter((o) => o !== action.removeThisAvocViewFeed)],
        };
    }
    return state;
}

export const handleZixiSCTEEventsDisplay = (state, action) => {
    return {
        ...state,
        showScteEvents: action.showScteEvents,
    };
}

export const handleZixiSCTEData = (state, action) => {
    return {
        ...state,
        scteData: action.scteData,
    };
}

export const handleZixiURLParamsFeedQueue = (state, action) => {
    return {
        ...state,
        urlParamsFeedList: action.urlParamsFeedList,
    };
}

export const handleZixiURLParamsAttributesQueue = (state, action) => {
    return {
        ...state,
        urlParamsAttrList: action.urlParamsAttrList,
    };
}