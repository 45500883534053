import { genericActionTypes } from "../generic/genericActionTypes";
import { handleZixiActiveCheckedItems, handleZixiAttributeState, handleZixiAvocViewFeedList, handleZixiCheckboxDisplay, handleZixiCheckboxSectionData, handleZixiFeedData, handleZixiFeedDataDisplay, handleZixiSCTEData, handleZixiSCTEEventsDisplay, handleZixiURLParamsAttributesQueue, handleZixiURLParamsFeedQueue } from "./handleZixiActions";
import { zixiActionTypes } from "./zixiActionTypes";

export const zixiInitialState = {
    feedNameList: [], // contains list of streams of selected checked feeds
    avocViewFeedList: [], // List of selected checked feeds
    showFeedData: false, // boolean to show/hide feed data 
    showAvocStyleFeedData: false,
    checkBoxSectionData: [], // This is where selected feeds are stored =(Checked/unched)
    showCheckboxData: false,
    urlParamsFeedList: [],
    urlParamsAttrList: [],
    activeCheckedZixiFeeds: [],
    showScteEvents: false,
    scteData: {},
    attributeState: {
        encoder_link: true,
        encoder_event: false,
        encoder_stats: false,
        // nba
        event_stats: false,
        active_alerts: false,
        download_buttons: false,
        // showCustomLabelField: false,
    },
}

export const zixiReducer = (state = zixiInitialState, action) => {
    switch (action.type) {
        case genericActionTypes.MULTIPLE_ACTIONS:
            return action.actions.reduce((acc, currentAction) => zixiReducer(acc, currentAction), state);
        case zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS:
            return handleZixiActiveCheckedItems(state, action);
        case zixiActionTypes.ZIXI_CHECKBOX_SECTION_DATA:
            return handleZixiCheckboxSectionData(state, action);

        case zixiActionTypes.ZIXI_FEED_DATA:
            return handleZixiFeedData(state, action);

        case zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE:
            return handleZixiAttributeState(state, action);

        case zixiActionTypes.ZIXI_SHOW_CHECKBOX_DATA:
            return handleZixiCheckboxDisplay(state, action);

        case zixiActionTypes.ZIXI_SHOW_FEED_DATA:
            return handleZixiFeedDataDisplay(state, action);

        case zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST:
            return handleZixiAvocViewFeedList(state, action);

        case zixiActionTypes.ZIXI_SHOW_SCTE_EVENTS:
            return handleZixiSCTEEventsDisplay(state, action);

        case zixiActionTypes.ZIXI_SET_SCTE_DATA:
            return handleZixiSCTEData(state, action);
        case zixiActionTypes.ZIXI_ADD_TO_URL_PARAMS_FEED_QUEUE:
            return handleZixiURLParamsFeedQueue(state, action);
        case zixiActionTypes.ZIXI_ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE:
            return handleZixiURLParamsAttributesQueue(state, action);
        default:
            return state;

    }


}