/**Used by EncoderStreamViewCard */

import { useQuery } from "@tanstack/react-query";
import React from "react";

// Local imports
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { isLoggerEnabled } from "../../../../js/utilities.js/logger";
import {
    getActiveAlertsCount,
    getEventId,
    getEventStatus,
    getHardwareStatus,
    getStreamConfig,
    getThumbnailData,
} from "../utils/encoderApiUtils";
import { encoderRefetchInterval } from "./encoderQueryConfig";



// const thumbnailApiQueryRefreshInterval = 6000000; //10 minutes
// const streamApiQueryRefreshInterval = 6000000; //10 minutes
// const configApiQueryRefreshInterval = 6000000; //10 minutes

/**
 * Cyclic query hook that makes API query to lambda
 * @param {*} thisStreamName
 * @returns {obj} Object containing data /error
 */
function useStreamApiQuery(thisStreamName) {

    // UseQuery hook to fetch data from API
    return useQuery(
        // key for the query
        [thisStreamName],
        // async function that fetches data from API
        async () => {
            let streamData = {};
            streamData["stream_name"] = thisStreamName;

            // getStreamConfig is a Necessary Call
            const feedStatusData = await getStreamConfig({
                stream_name: thisStreamName,
            });
            streamData = { ...streamData, ...feedStatusData[0] };

            //getEventId is Necessary a Call
            if (streamData.event_name && streamData.live_service_endpoint) {
                const eventIdDataData = await getEventId({
                    event_name: streamData.event_name,
                    live_service_endpoint: streamData.live_service_endpoint,
                });
                streamData = { ...streamData, ...eventIdDataData[0] };
            } else {
                isLoggerEnabled && console.warn(
                    "Cant execute getEventId. event_name & live_service_endpoint not found for ",
                    thisStreamName
                );
            }
            return [streamData];
        },
        // Configuration options for the query
        {
            // Refetch the data every  refresh interval seconds
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: encoderRefetchInterval.stream,
        }
    );
}

/**
 * Cyclic query hook that makes API query to lambda
 * @param {obj} Object with event_name, event_id, live_service_endpoint & streamName
 * @returns {obj} Object containing data /error
 */
function useThumbnailApiQuery(dataObj) {
    let stream_name, event_name, event_id, live_service_endpoint;

    if (dataObj !== undefined && dataObj[0] !== undefined) {
        stream_name = dataObj[0].stream_name;
        event_name = dataObj[0].event_name;
        event_id = dataObj[0].event_id;
        live_service_endpoint = dataObj[0].live_service_endpoint;
    }

    return useQuery(
        [stream_name, event_name, event_id, live_service_endpoint],
        async () => {
            let thumbnailData = {};
            if (event_id && live_service_endpoint) {
                const getThumbnailUrlData = await getThumbnailData({
                    event_id: event_id,
                    live_service_endpoint: live_service_endpoint,
                });
                thumbnailData = { ...thumbnailData, ...getThumbnailUrlData[0] };
            } else {
                isLoggerEnabled && console.warn(
                    "Cant execute ***getThumbnailData. event_id, event_name  & live_service_endpoint not found for ",
                    stream_name
                );
            }

            return [thumbnailData];
        },
        {
            // Refetch the data every  refresh interval (seconds)
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: encoderRefetchInterval.thumbnail,
        }
    );
}

/**
 * Cyclic query hook that makes API query to lambda
 * @param {obj} Object with event_name, event_id, live_service_endpoint & streamName
 * @returns {obj} Object containing data /error
 */
function useGetConfigApiQuery(dataObj) {
    const { storeState } = React.useContext(GlobalContext);
    const { zixiStates } = storeState;
    const { attributeState } = zixiStates;
    let stream_name, event_id, live_service_endpoint;

    if (dataObj !== undefined && dataObj[0] !== undefined) {
        stream_name = dataObj[0].stream_name;
        event_id = dataObj[0].event_id;
        live_service_endpoint = dataObj[0].live_service_endpoint;
    }

    return useQuery(
        [stream_name, event_id, live_service_endpoint],
        async () => {
            let streamData = {};

            //getEventStatus is Optional Can be controlled via attribute selection
            if (attributeState.event_stats) {
                if (event_id && live_service_endpoint) {
                    const getEventStatusData = await getEventStatus({
                        event_id: event_id,
                        live_service_endpoint: live_service_endpoint,
                    });
                    streamData = { ...streamData, ...getEventStatusData[0] };
                } else {
                    console.warn(
                        "Cant execute getEventStatus. event_id & live_service_endpoint  not found for ",
                        stream_name
                    );
                }
            }

            //getHardwareStatus is Optional Can be controlled via attribute selection
            if (attributeState.encoder_stats) {
                if (live_service_endpoint) {
                    const getHardwareStatusData = await getHardwareStatus({
                        live_service_endpoint: live_service_endpoint,
                    });
                    streamData = { ...streamData, ...getHardwareStatusData[0] };
                } else {
                    console.warn("Cant execute getHardwareStatus. live_service_endpoint  not found for ", stream_name);
                }
            }

            //getActiveAlertsCount is Optional Can be controlled via attribute selection
            if (attributeState.active_alerts) {
                if (live_service_endpoint) {
                    const getActiveAlertsCountData = await getActiveAlertsCount({
                        live_service_endpoint: live_service_endpoint,
                    });
                    streamData = { ...streamData, ...getActiveAlertsCountData[0] };
                } else {
                    console.warn(
                        "Cant execute getActiveAlertsCount. live_service_endpoint  not found for ",
                        stream_name
                    );
                }
            }
            return [streamData];
        },
        {
            // Refetch the data every refresh interval seconds
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: encoderRefetchInterval.config,
        }
    );
}

export { useGetConfigApiQuery, useStreamApiQuery, useThumbnailApiQuery };
