const selectedFeedsViewStyles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    checkbox: {
        color: '#9d7c4c',
    },
    iconButton: {
        color: '#ff0000',
    },
    feedContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    feedLabelContainer: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
    },
    feedLabel: {
        marginRight: '8px',
        flexGrow: 1,
    },
    deleteButton: {
        color: "#FF9900",
    },
    connectionStatusIcon: (isConnected) => ({
        color: isConnected ? "#80be0c" : "#8b0c06",
        marginLeft: '16px',
    }),
    messageBox: {
        fontStyle: "italic",
        color: "#a89587",
        fontSize: "14px",
        textAlign: "center",
        margin: "15px",
    },
};

export default selectedFeedsViewStyles;