import { getZixiSnapshotParams } from "../../encoder/js/encoder/utils/encoderUtils";
import { getEvisSnapshotParams } from "../../evis/js/utils/evisUtils";
import { isLoggerEnabled } from "./logger";

const handleSnapShotShare = (storeState, setSnackBarOpen = null) => {
    // add new architecture type here
    const { zixiStates, evisStates, genericStates } = storeState;

    const { dashboardFeedQueryData } = genericStates;
    let url = process.env.REACT_APP_API_ENVIRONMENT === "Gamma"
        ? "https://gamma.streampreview.amazonavoc.com"
        : process.env.REACT_APP_API_ENVIRONMENT === "Prod"
            ? "https://prod.streampreview.amazonavoc.com"
            : process.env.REACT_APP_API_ENVIRONMENT === "local"
                ? "http://localhost:3000"
                : "https://gamma.d1spqeq6296up.amplifyapp.com";

    const zixiParams = getZixiSnapshotParams(zixiStates.checkBoxSectionData, dashboardFeedQueryData, zixiStates.attributeState);
    const evisParams = getEvisSnapshotParams(evisStates.selectedEvisIdsCheckboxData, evisStates.evisAttributeState);

    const zixiFeeds = `feeds=${zixiParams.feeds}`;
    const zixiAttributes = `attributes=${zixiParams.attributes}`;

    const evisIds = `evisIds=${evisParams.evisIds}`;
    const evisAttributes = `evisAttributes=${evisParams.evisAttributes}`;


    const snapshotURL = `${url}/params?${zixiFeeds}&&${zixiAttributes}&&${evisIds}&&${evisAttributes}`;

    isLoggerEnabled && console.log("snapshotURL: ", snapshotURL);

    setSnackBarOpen && navigator.clipboard.writeText(snapshotURL).then(() => {
        setSnackBarOpen(true);
    });
    // alert("Snapshot URL copied to clipboard");
    return snapshotURL;
};

export {
    handleSnapShotShare
};
