import React, { createContext, useReducer } from "react";

/**
 * The AuthReducer function is a reducer function used to manage the state of the authentication context.
 * It handles two actions: "LOGIN" and "LOGOUT".
 *
 * @param {Object} AuthState - The current state of the authentication context.
 * @param {Object} AuthAction - The action to be performed on the authentication context.
 * @param {string} AuthAction.type - The type of the action, either "LOGIN" or "LOGOUT".
 * @param {Object} AuthAction.payload - The payload associated with the action.
 * @param {string} AuthAction.payload.user - The user information.
 * @param {string} AuthAction.payload.token - The authentication token.
 * @returns {Object} - The new state of the authentication context.
 */
const AuthReducer = (AuthState, AuthAction) => {
    switch (AuthAction.type) {
        case "LOGIN":
            localStorage.setItem("user", AuthAction.payload.user);
            localStorage.setItem("token", AuthAction.payload.token);
            return {
                ...AuthState,
                isAuthenticated: true,
                user: AuthAction.payload.user,
                token: AuthAction.payload.token,
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...AuthState,
                isAuthenticated: false,
                user: AuthAction.payload.user,
                token: AuthAction.payload.token,
            };
        default:
            return AuthState;
    }
};

/**
 * The initial state of the authentication context.
 * @type {Object}
 * @property {boolean} isAuthenticated - Indicates if the user is authenticated.
 * @property {string | null} user - The user information.
 * @property {string | null} token - The authentication token.
 */
const AuthInitialState = {
    isAuthenticated: false,
    user: null,
    token: null,
};

/**
 * The AuthContext is a React context that provides the authentication state and dispatch function.
 */
const AuthContext = createContext(null);

/**
 * The AuthContextProvider is a React component that wraps the application with the AuthContext.
 * It initializes the authentication state using the AuthReducer and the AuthInitialState.
 *
 * @param {Object} props - The component properties.
 * @param {ReactNode} props.children - The child components to be wrapped by the AuthContextProvider.
 * @returns {JSX.Element} - The AuthContextProvider component.
 */
const AuthContextProvider = ({ children }) => {
    const AuthContextValue = useReducer(AuthReducer, AuthInitialState);

    return <AuthContext.Provider value={AuthContextValue}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthContextProvider };
