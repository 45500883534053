import React, { createContext, useReducer } from "react";

// local imports
import contextLogger from "../../js/utilities.js/contextLogger";
import architectures from "./architectures";

/**
 * The combinedReducer function is a reducer function that combines the state updates from the individual architectures.
 * It updates the state based on the action dispatched and calculates the total order delta.
 *
 * @param {Object} state - The current state of the global context.
 * @param {Object} action - The action to be performed on the global context.
 * @returns {Object} - The updated state of the global context.
 */
const combinedReducer = (state, action) => {
    let newState = { ...state };

    for (const architecture of Object.values(architectures)) {
        newState[architecture.name] = architecture.reducer(state[architecture.name], action);
    }

    // Calculate the total order delta
    newState.genericStates = {
        ...newState.genericStates,
        order: state.genericStates.order + Object.values(newState)
            .filter(({ orderDelta }) => orderDelta !== undefined)
            .reduce((total, { orderDelta }) => total + (orderDelta || 0), 0),
    };

    return newState;
};

/**
 * Create new context for global
 */
const GlobalContext = createContext(undefined);
/**
 * Wrapper around the combinedReducer function that logs the state changes to the console
 * if debug mode is enabled.
 */
const appReducerWithLogger = contextLogger(combinedReducer);

/**
 * Contains initial state of global context.
 * Constructed by combining the initial states of all architectures.
 */
const initialState = Object.fromEntries(
    Object.values(architectures).map((arch) => [arch.name, arch.initialState])
);

/**
 * Configure Context Provider with reducer function, 
 * initial global values and globalContext.
 * Wraps around the children components with the global context.
 * It initializes the global context with the initial state and appReducerWithLogger.
 * @param {ReactNode} props.children - The child components to be wrapped by the GlobalContextProvider.
 * @returns {JSX.Element} - The GlobalContextProvider component.
 */
const GlobalContextProvider = ({ children }) => {
    const [storeState, storeDispatch] = useReducer(appReducerWithLogger, initialState);
    return <GlobalContext.Provider value={{ storeState, storeDispatch }}>{children}</GlobalContext.Provider>;
};
export { GlobalContext, GlobalContextProvider };
