import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Auth, Hub } from "aws-amplify";
import jwt from "jwt-decode";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "./commonComponents/Login/Login";


// Local imports
import { Box, Container, CssBaseline } from "@mui/material";
import { AuthContext } from "./contexts/AuthProvider";
import { genericActionTypes } from "./contexts/generic/genericActionTypes";
import { GlobalContext } from "./contexts/globalContextStore/GlobalContextStore";
import { ShowScte35Info } from "./encoder/components/sidebar/datadisplay/scteInfo/ShowEncoderScteInfo";
import { isLoggerEnabled } from "./js/utilities.js/logger";
import Dashboard from "./pages/dashboard/Dashboard";
import { NotFoundPage } from "./pages/InValidPath";

const RedirectOnLogin = () => {

    const { storeDispatch } = useContext(GlobalContext);

    const redirectData = JSON.parse(localStorage.getItem("url"));
    const navigate = useNavigate();

    useEffect(() => {
        storeDispatch({
            type: genericActionTypes.MULTIPLE_ACTIONS,
            actions: [
                { type: "SET_FROM_LOGIN_PAGE", fromLoginPage: false },
                { type: "SET_URL_DATA", urlData: null },
            ]
        })

        localStorage.setItem("url", null); //clear url data
        isLoggerEnabled && console.log("RedirectOnLogin redirectData: ", redirectData);
        if (typeof redirectData === "object" && redirectData !== null && redirectData !== undefined) {
            isLoggerEnabled && console.log(`in RedirectOnLogin(1), pathname: ${redirectData.pathname} search: ${redirectData.search}`);
            navigate({
                pathname: redirectData.pathname,
                search: redirectData.search,

            });

        } else {
            isLoggerEnabled && console.log(`in RedirectOnLogin(2), pathname: "/" search: "" `);
            navigate({
                pathname: "/",
                search: "",
            });
        }
    }, []);
    return (
        <>
            {isLoggerEnabled && console.log(`in RedirectOnLogin(3), returning`)}
            <Container maxWidth="xxl" >
                <CssBaseline />
                <Box >
                    <h4> Redirecting .......</h4>
                </Box>
            </Container>
        </>
    );
};

function App() {
    const [AuthState, AuthDispatch] = useContext(AuthContext);
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const queryClient = new QueryClient();

    useEffect(() => {
        let user = localStorage.getItem("user") || null;
        let token = localStorage.getItem("token") || null;

        // Set debug state
        storeDispatch({
            type: "SET_DEBUG_ON",
            isDebugOn: process.env.REACT_APP_DEBUG_ENVIRONMENT === 'true' ? true : false,
        });

        if (user && token) {
            //Check token validity. Relogin if necessary
            let userTokenExpEpochDate = jwt(token).exp;
            let currentEpochDate = Math.round(Date.now() / 1000);
            if (currentEpochDate >= userTokenExpEpochDate) {
                //Token Expired
                isLoggerEnabled && console.log("Token expired, Signout in progress");
                Auth.signOut();
                localStorage.clear();
                token = null;
                user = null;
                AuthDispatch({
                    type: "LOGOUT",
                    payload: {
                        user,
                        token,
                    },
                });
            } else {
                //token valid
                isLoggerEnabled && console.log("Existing Token valid, Sigin in progress");
                AuthDispatch({
                    type: "LOGIN",
                    payload: {
                        user,
                        token,
                    },
                });
            }
        }

        //exit cleanup function
        return () => {
            AuthDispatch({
                type: "LOGOUT",
                payload: {
                    user,
                    token,
                },
            });
        };
    }, []);

    React.useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            isLoggerEnabled && console.dir(process.env);

            switch (event) {
                case "signIn":
                    isLoggerEnabled && console.log("signIn event in progress");
                    break;

                case "cognitoHostedUI":
                    isLoggerEnabled && console.log("In cognitoHostedUI");
                    // console.dir(data); //debug aam

                    Auth.currentSession().then((session) => {
                        isLoggerEnabled && console.dir(session);
                        const user = session.idToken.payload.identities[0].userId || null;
                        const token = session.accessToken.jwtToken;

                        //Check token validity. Relogin if necessary
                        let userTokenExpEpochDate = jwt(token).exp;
                        let currentEpochDate = Math.round(Date.now() / 1000);
                        isLoggerEnabled && console.log(`currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`);
                        if (currentEpochDate >= userTokenExpEpochDate) {
                            //Token Expired
                            isLoggerEnabled && console.log("Token expired, Signout event in progress");
                            Auth.signOut();
                            localStorage.clear();
                            let token = null;
                            let user = null;
                            AuthDispatch({
                                type: "LOGOUT",
                                payload: {
                                    user,
                                    token,
                                },
                            });
                        } else {
                            //token valid (after redirection from cognito)
                            isLoggerEnabled && console.log("New Token valid, Sigin in event progress");

                            storeDispatch({
                                type: "SET_FROM_LOGIN_PAGE",
                                fromLoginPage: true,
                            });
                            AuthDispatch({
                                type: "LOGIN",
                                payload: {
                                    user,
                                    token,
                                },
                            });
                        }
                    });
                    break;

                case "signOut":
                    isLoggerEnabled && console.log("signOut event in progress");
                    localStorage.clear();
                    let token = null;
                    let user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "oAuthSignOut":
                    isLoggerEnabled && console.log("oAuthSignOut event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "signIn_failure":
                    isLoggerEnabled && console.log("signIn_failure event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "cognitoHostedUI_failure":
                    isLoggerEnabled && console.log("cognitoHostedUI_failure event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                default:
                    isLoggerEnabled && console.log("In Hub Default option.");
            }
        });
    }, []);

    const AppRoutes = <>
        <QueryClientProvider client={queryClient}>
            <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="params" element={<Dashboard />} />
                <Route path="scte35Info" element={<ShowScte35Info />} />
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </QueryClientProvider>
    </>;

    const printDebug = () => {
        console.log("process.env:", process.env);
        console.log("localStorage:", localStorage);
        console.log("AuthState:", AuthState);
        console.log("genericStates:", genericStates);
    };

    return (
        <React.Fragment>
            {isLoggerEnabled && printDebug()}
            <BrowserRouter>
                {
                    process.env.REACT_APP_API_ENVIRONMENT === "local" ? (
                        <>
                            {isLoggerEnabled && console.log("local env, switching to routes")}
                            {AppRoutes}
                        </>
                    ) : (
                        <>
                            {/* Non Local Env */}
                            {
                                AuthState.isAuthenticated === false ? (
                                    <>
                                        {isLoggerEnabled && console.log("user not logged in, switching to LOGIN component")}
                                        <Login />
                                    </>
                                ) : (
                                    <>
                                        {/* login page or direct entry */}
                                        {
                                            genericStates.fromLoginPage ? (
                                                <>
                                                    {/* URL presennce or absence */}
                                                    {
                                                        localStorage.getItem("url") !== "null" && localStorage.getItem("url") !== undefined ? (
                                                            <>
                                                                {isLoggerEnabled && console.log("fromLoginPage true, url != null , switching to RedirectOnLogin component")}
                                                                <RedirectOnLogin />
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isLoggerEnabled && console.log("fromLoginPage true, url is null , switching to AppRoute")}
                                                                {AppRoutes}
                                                            </>
                                                        )
                                                    }

                                                </>
                                            ) : (
                                                <>
                                                    {/* auth true & not from login page */}
                                                    {isLoggerEnabled && console.log("fromLoginPage false, no localstorage search , switching to AppRoute")}
                                                    {AppRoutes}
                                                </>
                                            )
                                        }

                                    </>
                                )
                            }
                        </>
                    )

                }
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;