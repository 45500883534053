import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, ListItem, ListItemIcon, ListItemText, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import autoCompleteStyles from './autoCompleteStyles';


/**
 * AutoCompleteSelectItems component renders an autocomplete dropdown list
 * with custom options and connection status icons.
 * 
 * @param {Array} list - Array of options to display in the autocomplete.
 * @param {string} property - The property of the option object to display.
 * @param {Function} handleSelect - Callback function to handle selection of an item.
 * @param {string} label - Label for the autocomplete input field.
 * @param {string} connectionStatusProp - Optional property to determine connection status for the icon color.
 * @returns {JSX.Element} - The rendered autocomplete component.
 */
export function AutoCompleteSelectItems({ list, property = undefined, handleSelect, label, connectionStatusProp = undefined }) {
    /**
    * The currently selected value from the autocomplete dropdown.
    * @type {object|null}
    */
    const [selectedValue, setSelectedValue] = useState(null);

    /**
     * Ref to the autocomplete container to detect clicks outside the component.
     * @type {object}
     */
    const autocompleteRef = useRef(null);

    /**
     * Handles the selection change from the autocomplete dropdown.
     * 
     * @param {object} event - The event object.
     * @param {object} selectedItem - The item selected from the dropdown.
     */
    const handleSelectChange = (event, selectedItem) => {
        setSelectedValue(selectedItem);
        if (selectedItem) {
            handleSelect(selectedItem);
        }
    };

    /**
     * Handles document-wide clicks to clear the autocomplete selection
     * if the click occurs outside the autocomplete component.
     * 
     * @param {object} event - The click event object.
     */
    const handleDocumentClick = (event) => {
        if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
            setSelectedValue(null);
        }
    };

    /**
     * Sets up a click listener when the component first appears on the page.
     * Removes the click listener when the component is taken off the page
     * to avoid any potential problems.
     */
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    return (
        <Box sx={autoCompleteStyles.box} ref={autocompleteRef}>
            <Autocomplete
                value={selectedValue}
                options={list}
                onChange={handleSelectChange}
                getOptionLabel={(option) => property ? option[property] : option}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="standard"
                    />
                )}
                renderOption={(props, option) => {
                    const iconColor = connectionStatusProp && option[connectionStatusProp] === 'connected'
                        ? "#80be0c"
                        : "#8b0c06";

                    return (
                        <ListItem
                            {...props}
                            key={property ? option[property] : option}
                            sx={autoCompleteStyles.dropdownItem}
                        >
                            <ListItemText
                                primary={property ? option[property] : option}
                                disableTypography
                                sx={autoCompleteStyles.dropDownText}
                            />
                            {connectionStatusProp && (
                                <ListItemIcon sx={{ minWidth: 'auto', marginLeft: '8px' }}>
                                    <autoCompleteStyles.lensIcon color={iconColor} />
                                </ListItemIcon>
                            )}
                        </ListItem>
                    );
                }}
                popupIcon={<ArrowDropDownIcon style={autoCompleteStyles.iconColor} />}
            />
        </Box>
    );
}

// PropTypes validation
AutoCompleteSelectItems.propTypes = {
    list: PropTypes.array.isRequired,
    property: PropTypes.string,
    handleSelect: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    connectionStatusProp: PropTypes.string,
};
