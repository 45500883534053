const spinnerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: 'absolute',
    height: '90vh',
    width: '90vw',
    marginLeft: '60%',
};

const thumbnailViewerStyles = {
    spinner: spinnerStyle,
};

export default thumbnailViewerStyles;