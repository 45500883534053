import { useQuery } from "@tanstack/react-query";
import React from "react";

// Local imports
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { getZixiInputId, getZixiInputStreamStats, getZixiStreamConfig, getZixiThumbnailData } from "../utils/zixiApiUtils";
import { zixiRefetchInterval } from "./zixiQueryConfig";


/**
 * Cyclic query hook that makes API query to lambda
 * @param {*} thisStreamName
 * @returns {obj} Object containing data /error
 */
function useZixiStreamApiQuery(thisStreamName) {
    return useQuery(
        [thisStreamName],
        async () => {
            let zixiStreamData = {};
            zixiStreamData["stream_name"] = thisStreamName;

            //getZixiStreamConfig is a Necessary Call
            const zixiStreamConfigData = await getZixiStreamConfig({
                stream_name: thisStreamName,
            });
            zixiStreamData = { ...zixiStreamData, ...zixiStreamConfigData[0] };

            //get input id
            if (zixiStreamData.realm && zixiStreamData.live_service_endpoint) {
                const zixiInputIdData = await getZixiInputId({
                    stream_name: zixiStreamData.stream_name,
                    realm: zixiStreamData.realm,
                    live_service_endpoint: zixiStreamData.live_service_endpoint,
                });
                zixiStreamData = { ...zixiStreamData, ...zixiInputIdData[0] };
            } else {
                console.warn(
                    "Cant execute getZixiInputId. realm & live_service_endpoint not found for ",
                    thisStreamName
                );
            }
            return [zixiStreamData];
        },
        {
            // Refetch the data every  refresh interval seconds
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: zixiRefetchInterval.stream,
        }
    );
}

/**
 * Cyclic query hook that makes API query to lambda
 * @param {obj} Object with realm, live_service_endpoint & streamName
 * @returns {obj} Object containing data /error
 */
function useZixiThumbnailApiQuery(dataObj) {
    let stream_name, realm, live_service_endpoint;

    if (dataObj !== undefined && dataObj[0] !== undefined) {
        stream_name = dataObj[0].stream_name;
        realm = dataObj[0].realm;
        live_service_endpoint = dataObj[0].live_service_endpoint;
    }

    return useQuery(
        [stream_name, realm, live_service_endpoint],
        async () => {
            let zixiThumbnailData = {};
            if (realm && live_service_endpoint) {
                const getZixiThumbnailUrlData = await getZixiThumbnailData({
                    stream_name: stream_name,
                    realm: realm,
                    live_service_endpoint: live_service_endpoint,
                });
                zixiThumbnailData = { ...zixiThumbnailData, ...getZixiThumbnailUrlData[0] };
            } else {
                console.warn(
                    "Cant execute getZixiThumbnailUrl. realm  & live_service_endpoint not found for ",
                    stream_name
                );
            }

            return [zixiThumbnailData];
        },
        {
            // Refetch the data every  refresh interval (seconds)
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: zixiRefetchInterval.thumbnail,
        }
    );
}

/**
 * Cyclic query hook that makes API query to lambda
 * @param {obj} Object with event_name, event_id, live_service_endpoint & streamName
 * @returns {obj} Object containing data /error
 */
function useGetZixiConfigApiQuery(dataObj) {
    const { storeState } = React.useContext(GlobalContext);
    const { zixiStates } = storeState;
    const { attributeState } = zixiStates;
    let stream_name, input_id, realm, live_service_endpoint;

    if (dataObj !== undefined && dataObj[0] !== undefined) {
        stream_name = dataObj[0].stream_name;
        realm = dataObj[0].realm;
        input_id = dataObj[0].input_id;
        live_service_endpoint = dataObj[0].live_service_endpoint;
    }

    return useQuery(
        [stream_name, input_id, realm, live_service_endpoint],
        async () => {
            let zixiStreamData = {};

            //getInputStreamStats is Optional Can be controlled via attribute selection
            if (attributeState.event_stats) {
                if (realm && input_id && live_service_endpoint) {
                    const getZixiInputStreamStatsData = await getZixiInputStreamStats({
                        input_id: input_id,
                        realm: realm,
                        live_service_endpoint: live_service_endpoint,
                    });
                    zixiStreamData = { ...zixiStreamData, ...getZixiInputStreamStatsData[0] };
                } else {
                    console.warn(
                        "Cant execute getZixiInputStreamStats. input_id & realm & live_service_endpoint  not found for ",
                        stream_name
                    );
                }
            }

            return [zixiStreamData];
        },
        {
            // Refetch the data every refresh interval seconds
            //Actual refresh will be total fetch time (~5-8 sec) + refresh interval
            refetchInterval: zixiRefetchInterval.config,
        }
    );
}

export {
    useGetZixiConfigApiQuery, useZixiStreamApiQuery,
    useZixiThumbnailApiQuery
};
