export const encoderApiConfig = {
    name: "encoderData",
    path: "streampreview_dashboard_api/hwenc",
    getInitialData: "get_feed_data",
    getEncoderStreamConfig: "get_stream_config",
    getEncoderEventId: "get_event_id",
    getEncoderEventStatus: "get_event_status_data",
    getEncoderHardwareStatus: "get_hardware_status_data",
    getEncoderFeedStatus: "get_feed_status_data",
    getEncoderActiveAlertsCount: "get_alerts_count",
    getEncoderThumbnailData: "get_encoder_thumbnail_binary",
    getEncoderThumbnailUrl: "get_encoder_thumbnail_s3",
    getEncoderEventXML: "get_event_xml",
    getEncoderEventlog: "get_event_log",
    getEncoderSCTElog: "get_scte_log",
}
