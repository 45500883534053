const evisStreamViewCardStyles = {
    cardMedia: {
        aspectRatio: 16 / 9,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        padding: 1,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
    }
}

export default evisStreamViewCardStyles;
