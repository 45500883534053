import { StyledEngineProvider } from "@mui/material/styles";
import { Amplify } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";

// Local imports
import App from "./App";
import envConfig from "./config/envConfig";
import { AuthContextProvider } from "./contexts/AuthProvider";
import { GlobalContextProvider } from "./contexts/globalContextStore/GlobalContextStore";
import "./index.css";

Amplify.configure(envConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalContextProvider>
    <AuthContextProvider>
      <StyledEngineProvider injectFirst>
        <App />
      </StyledEngineProvider>
    </AuthContextProvider>
  </GlobalContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(isDebugOn && console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
