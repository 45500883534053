const titleStyle = {
    color: "#8d6447",
    fontWeight: "bold",
    fontSize: "1rem",
    textAlign: "center",
    margin: "16px",
    shadow: "inherit"
};

const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "inherit"
};

const dividerStyle = {
    backgroundColor: "#4e392a",
};

const sideBarContentStyles = {
    title: titleStyle,
    loading: loadingStyle,
    divider: dividerStyle
};

export default sideBarContentStyles;