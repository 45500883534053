import { getFeedNameFromAlias } from "./dashboardUtils";

/**
 * This function takes an array of objects and returns a new array of objects with the keys of each object sorted.
 * @param {*} data 
 * @returns {Array} - An array of objects with the keys of each object sorted.
 */
export function getSortedEncoderTenants(data) {
    if (!Array.isArray(data) || data.length === 0) {
        console.error("Invalid input: data should be a non-empty array.");
        return [];
    }


    const tenantData = data[0];

    if (typeof tenantData !== 'object' || tenantData === null) {
        console.error("Invalid input: data[0] should be a non-null object.");
        return [];
    }

    // Sort the keys of the tenant data object
    const sortedPropertyKeys = Object.keys(tenantData).sort();

    // Create a new object with properties sorted by the sorted keys
    const sortedTenantData = sortedPropertyKeys.reduce((accumulator, key) => {
        accumulator[key] = tenantData[key];
        return accumulator;
    }, {});


    return [sortedTenantData];
}

function getZixiSnapshotParams(checkBoxSectionData, dashboardFeedQueryData, attributeState) {
    const feeds = [];
    const attrs = [];

    for (const alias of checkBoxSectionData) {
        feeds.push(getFeedNameFromAlias(alias, dashboardFeedQueryData.dbQueryData[0]));
    }

    for (const [key, value] of Object.entries(attributeState)) {
        if (value) {
            attrs.push(key);
        }
    }

    return {
        feeds: feeds.toString(),
        attributes: attrs.toString(),
    };
}

export {
    getZixiSnapshotParams
};
// TODO: 