import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { a11yProps, CustomTabPanel } from '../../CustomComponents';
import architectureTabsStyles from './architectureTabStyles';

/**
 * ArchitectureTabs component handles the display of tabs and their content.
 * It receives the list of architectures and a value/handleTabChange pair to control the selected tab.
 * 
 * @param {Array} architectures - Array of architectures, each containing a label and a component.
 * @param {number} value - Current selected tab index.
 * @param {function} handleTabChange - Function to handle tab change events.
 * @returns {JSX.Element} - The rendered ArchitectureTabs component.
 */
function ArchitectureTabs({ architectures, value, handleTabChange }) {
    return (
        <Box sx={architectureTabsStyles.root}>
            <Box sx={architectureTabsStyles.tabsContainer}>
                <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="architecture tabs"
                    variant='fullWidth'
                >
                    {architectures.map((architecture, index) => (
                        <Tab key={index} label={architecture.label} {...a11yProps(index)} sx={architectureTabsStyles.tab} />
                    ))}
                </Tabs>
            </Box>

            {architectures.map((architecture, index) => (
                <CustomTabPanel key={index} value={value} index={index}>
                    {architecture.component}
                </CustomTabPanel>
            ))}
        </Box>
    );
}

// PropTypes validation
ArchitectureTabs.propTypes = {
    architectures: PropTypes.arrayOf(
        PropTypes.shape(
            {
                label: PropTypes.string.isRequired,
                component: PropTypes.element.isRequired,
            })
    ).isRequired,
    value: PropTypes.number.isRequired,
    handleTabChange: PropTypes.func.isRequired,
};

export default ArchitectureTabs;
