import React, { useCallback } from "react";
import { evisActionTypes } from "../../../contexts/evis/evisActionTypes";
import { genericActionTypes } from "../../../contexts/generic/genericActionTypes";

/**
 * Custom hook to handle Evis feeds.
 * Processes and dispatches actions based on URL params of Evis.
 * 
 * @param {Array} urlParamsEvisIdList - List of Evis IDs from URL params.
 * @param {Function} storeDispatch - Dispatch function from the global store.
 * @returns {Function} - A function to process Evis data.
 */
function useHandleEvisFeedsParam(urlParamsEvisIdList, dashboardFeedQueryData, storeDispatch) {
    return useCallback(() => {
        if (urlParamsEvisIdList.length > 0) {
            urlParamsEvisIdList.forEach(evisId => {
                storeDispatch({ type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS, operation: genericActionTypes.OPERATION_ADD, feed: evisId });
                storeDispatch({ type: evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA, operation: genericActionTypes.OPERATION_ADD, selectedEvisIdsCheckboxData: [evisId] });
            });
        }
    }, [urlParamsEvisIdList, dashboardFeedQueryData, storeDispatch]);
}

/**
 * Custom hook for handling EVIS attributes.
 * It updates the global attribute state of EVIS with the attributes from the
 * urlParamsEvisAttr list(This is where EVIS attributes from the URL are stored).
 * @param {Array} urlParamsEvisAttrList - The list of EVIS attributes from the URL params
 * @param {function} storeDispatch - The dispatch function from the global context
 */
const useEvisAttrParamHandler = (urlParamsEvisAttrList, storeDispatch) => {
    React.useEffect(() => {
        if (urlParamsEvisAttrList.length > 0) {
            for (const attr of urlParamsEvisAttrList) {
                storeDispatch({
                    type: evisActionTypes.EVIS_SET_ATTRIBUTE_STATE,
                    newEvisAttributeState: { [attr]: true },
                });
            }
        }
    }, [urlParamsEvisAttrList]);
};


export { useHandleEvisFeedsParam, useEvisAttrParamHandler };


