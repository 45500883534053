import { genericActionTypes } from "./genericActionTypes";
import { handleGetDashboardFeedQueryData, handleOpenSidebar, handleSetCustomLabelEnabled, handleSetDebugOn, handleSetFromLoginPage } from "./handleGenericActions";


export const genericInitialStates = {
    isDebugOn: false, // enable / disable log view
    toggleDark: true,
    openSideBar: true,
    fromLoginPage: false,
    order: 1,
    dashboardFeedQueryData: {
        dbQueryData: null,
        dbEvisQueryData: null,
        isDbQueryLoading: false,
        isDbQueryError: false,
        dbQueryErrorMsg: null,
    },
    customLabelEnabled: false,
}
export const genericReducer = (state = genericInitialStates, action) => {

    switch (action.type) {
        case genericActionTypes.OPEN_SIDEBAR:
            return handleOpenSidebar(state, action);
        case genericActionTypes.SET_FROM_LOGIN_PAGE:
            return handleSetFromLoginPage(state, action);
        case genericActionTypes.SET_DEBUG_ON:
            return handleSetDebugOn(state, action);
        case genericActionTypes.GET_DASHBOARD_FEED_QUERY_DATA:
            return handleGetDashboardFeedQueryData(state, action);
        case genericActionTypes.TOGGLE_CUSTOM_LABEL:
            return handleSetCustomLabelEnabled(state, action);
        default:
            return state;
    }
}