import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LensIcon from '@mui/icons-material/Lens';
import {
    styled,
    Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import React from "react";

// TODO: 

const StyledTypographyAttributesLabel = styled(Typography)(({ theme }) => ({

    color: "#a89587",
    fontSize: "0.8rem",
}));

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    boxShadow: "0 2px 4px rgba(39, 18, 4, 0.5)", // Subtle shadow for depth
    overflow: "hidden", // Ensures content doesn't overflow(like shadows)
}));

/**
 * Front side of the accordion(bar)
 */
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    backgroundColor: "#000000", // color of the accordion
    color: "#8d6447", // Text color
    boxShadow: '0px 4px 8px rgba(134, 95, 48, 0.5), 0px -4px 8px rgba(120, 61, 24, 0.3)', // shadow
    borderBottom: "1px solid #543403", // Adds a border between accordions
}));

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
    backgroundColor: "#000000",
}));

const CustomAccordion = ({ label, children, expanded, onChange }) => {
    return (
        <div>
            <StyledAccordion expanded={expanded} onChange={onChange}>
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon style={{ color: "#534e49" }} />}
                    aria-controls="architecture-content"
                    id={label}
                >
                    <Typography
                        style={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontWeight: "bold",
                        }}
                    >
                        {label}
                    </Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>{children}</StyledAccordionDetails>
            </StyledAccordion>
        </div>
    );
};

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    // flexGrow: 1,
    color: "#A0A0A0",
    fontWeight: "bold",
    // fontFamily: "cursive",
    fontSize: "1.4rem", // Increase the font size here
    // textAlign: "center",
    // marginTop: theme.spacing(2),
    marginTop: "160px",
    opacity: 1.0,
}));


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0, margin: 0 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const StyledCancelIcon = styled(HighlightOffIcon)(({ theme }) => ({
    "&:hover": { color: theme.palette.error.dark },
    fontSize: "0.8rem",
}));

const StyledLensIcon = styled(LensIcon)(({ theme }) => ({
    fontSize: "0.8rem",
}));


export {
    a11yProps,
    CustomAccordion,
    CustomTabPanel,
    StyledAccordionSummary,
    StyledCancelIcon,
    StyledLensIcon,
    StyledTypographyAttributesLabel,
    StyledTypographyTitle
};

