import { Paper, styled } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#1A2027',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const showScte35InfoStyles = {
    container: {
        marginTop: 20,
        maxWidth: 'xxl',
    },
    box: {
        bgcolor: '#cfe8fc',
        margin: 2,
        height: '100vh',
        backgroundColor: 'rgb(18, 18, 18)',
    },

    cardActions: {
        flexGrow: 1,
    },

    item: Item,
    closeIcon: {
        color: '#FF9900',
        fontSize: '1rem',
    },
    feedName: {
        color: '#FF9900',
        fontSize: '1rem',
    },
    scteData: {
        backgroundColor: '#121212',
        fontSize: '0.9rem',
        background: 'none',
        color: "rgb(255, 255, 255)"
    },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        width: '90vw',
    },
    postion: {
        position: 'left',
    },
};

export default showScte35InfoStyles;