import { styled } from "@mui/material";

const drawerWidth = 240;

/**
 * Styled component for the main content area of the dashboard.
 * It handles the transition and positioning based on weather the sidebar is open or closed.
 * 
 * @param {Object} theme - the theme object provided by MUI.
 * @param {boolean} open - the state of the sidebar.
 * @returns {Object} The styles main component with transition effects.
 */
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));


const invisibleBookmark = { display: "none", visibility: "hidden" }



const dashboardStyles = {
    main: Main,
    box: {
        display: "flex",
    },
    invisibleBookmark: invisibleBookmark,

}

export default dashboardStyles;