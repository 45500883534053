import { makeAPICall } from "../../../js/utilities.js/apiUtils";
import { evisApiConfig } from "../evisApiConfig";

/**
 * Retrieves all the EVIS IDs along with group and status informatio
 * @param {Object} params - Request parameters
 * @returns {Promise} Promise object represents the list of all EVIS IDs along with group and status information
 */
export const getAllEvisIds = (params) => makeAPICall(evisApiConfig.path, evisApiConfig.getAllEvisIds, params);

/**
 * Given Evis group and Evis Id, retrieves all streams associated with the Evis Id along with their host, stream id and type information
 * @param {Object} params - Request parameters
 * @param {String} params.evisid - Evis ID
 * @param {String} params.groupname - Evis group name 
 * @returns {Promise} Promise object represents the list of all streams associated with the Evis Id along with their host, stream id and type information
 */
export const getEvisIdData = async (params) => {
    return makeAPICall(evisApiConfig.path, evisApiConfig.getEvisStreamsOfEvisId, params);
}


/**
 * Given EVIS stream id, host and type, retrieves the attributes of the stream
 * @param {Object} params - Request parameters
 * @param {String} params.evis_host - Host of the stream
 * @param {String} params.evis_stream_id - Stream ID
 * @param {String} params.evis_input_type - Type of the stream
 * @returns {Promise} Promise object represents the attributes of the stream
 */
export const getEvisStreamAttribute = (params) => makeAPICall(evisApiConfig.path, evisApiConfig.getEvisStreamAttributes, params);


/**
 * Given EVIS stream id and host, retrieves the thumbnail of the stream
 * @param {Object} params - Request parameters
 * @param {String} params.stream_id - Stream ID
 * @param {String} params.host - Host of the stream
 * @returns {Promise} Promise object represents the thumbnail of the stream
 */
export const getEvisStreamThumbnail = (params) => makeAPICall(evisApiConfig.path, evisApiConfig.getEvisStreamThumbnail, params);



