import { Info } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, CircularProgress, IconButton, Typography } from "@mui/material";
import React from "react";
import { AttributesInfoDisplay } from "../../attributeInfo/AttributesInfoDisplay";
import streamViewCardStyles from "./streamViewCardStyles";

/**
 * Helper function to render the card content
 * @param {Object} attributeData object containing attribute data
 * @returns 
 */
const renderAttributeInfoCard = (attributeData) => (
    <Card sx={streamViewCardStyles.infoCard}>
        <CardContent>
            <Typography variant="caption" display="block">
                {(!attributeData || Object.keys(attributeData).length === 0)
                    ? <span style={streamViewCardStyles.noInfoMessage}>No attributes available</span>
                    : <AttributesInfoDisplay attributes={attributeData} />}
            </Typography>
        </CardContent>
    </Card>
);


/**
 * StreamViewCard component displays a card with content based on the queryResult status.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.queryResult - The result of the query, includes status and error.
 * @param {React.ReactNode} [props.firstHalfCardContent=null] - Content to display in the first half of the card.
 * @param {React.ReactNode} [props.secondHalfCardContent=null] - Content to display in the second half of the card.
 * @param {Object} props.attributeData - Encoder attributes data for displaying additional info. If empty, no info message will be displayed.
 * @returns {JSX.Element} The rendered StreamViewCard component.
 */
export const StreamViewCard = ({ queryResult, firstHalfCardContent = null, secondHalfCardContent = null, attributeData = {}, cardShadow = null }) => {
    const [showAttributeInfo, setShowAttributeInfo] = React.useState(false);

    /**
     * Toggles the display of the attribute information card.
     */
    const toggleAttributeInfo = () => {
        setShowAttributeInfo(prev => !prev);
    };

    return (
        <Box sx={{ position: 'relative' }}>
            {showAttributeInfo && renderAttributeInfoCard(attributeData)}

            <Card sx={{ boxShadow: cardShadow || streamViewCardStyles.cardShadow }}>
                <CardHeader
                    sx={streamViewCardStyles.cardHeader}
                    action={
                        <IconButton
                            aria-label="toggle-attributes-info"
                            sx={streamViewCardStyles.iconButton}
                            onClick={toggleAttributeInfo}
                        >
                            <Info fontSize="small" sx={streamViewCardStyles.attrInfoIcon} />
                        </IconButton>
                    }
                />
                <CardContent sx={streamViewCardStyles.cardContent}>
                    {queryResult.status === "loading" ? (
                        <Box sx={streamViewCardStyles.placeHolderCardWhenLoading}>
                            <CircularProgress />
                        </Box>
                    ) : queryResult.status === "error" ? (
                        <Typography color="error">Error: {queryResult.error.message}</Typography>
                    ) : (
                        <React.Fragment>
                            {firstHalfCardContent}
                            {secondHalfCardContent}
                        </React.Fragment>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
};