import { encoderApiConfig } from "../../../encoder/js/encoderApiConfig"
import { evisApiConfig } from "../../../evis/js/evisApiConfig"

/**
 * Defines the infrastructures and their respective API configurations.
 */
export const infrastructures = {
    "encoder": encoderApiConfig,
    "EVIS": evisApiConfig
}

