import * as React from "react";
import { Navigate } from "react-router-dom";
/**
 * Invalid path handler component for App.
 * @param {*} props
 * @returns {JSX} JSX
 */
export const NotFoundPage = () => {
  alert(
    "Web URL path" +
    window.location +
    " was incorrect. Redirecting to home page"
  );
  return (
    <>
      <Navigate to="/" replace={true} />
    </>
  );
};
