import { API } from "aws-amplify";
import { isLoggerEnabled } from "./logger";

/**
 * An object containing the default request headers to be used for API calls.
 */
const requestHeaders = {
    "Cache-Control": "no-store", // do not cache API responses
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8", // set the content type to JSON
    "Access-Control-Allow-Origin": "*", // allow requests from any origin
    "Access-Control-Allow-Headers": "Cache-Control, Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Authorization, X-Requested-With" // allow these headers
}

/**
 * Makes an API call using the AWS Amplify API.
 *
 * @param {string} path - The path of the API endpoint to call.
 * @param {string} action - The action to perform on the API endpoint.
 * @param {object} [params] - Optional parameters to pass with the API request.
 * @returns {Promise<object>} - The response from the API call.
 *
 * @example
 * const response = await makeAPICall("api-path", "my-action-GETG", { param1: 'value1', param2: 'value2' }
 */
export const makeAPICall = async (path, action, params) => {
    // isLoggerEnabled && console.log(`***Making API call to ${path} with action ${action} and params ${JSON.stringify(params)}`);
    try {
        const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
            headers: requestHeaders,
            queryStringParameters: {
                action: action,
                ...params,
            },
        });
        return response;
    } catch (err) {
        isLoggerEnabled && console.error(`Error executing ${action} function:`, err);
        return {
            code: 400,
            message: `Error executing ${action} function`,
        }
    }
}