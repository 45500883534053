import React from "react";
import { evisActionTypes } from "../../../../contexts/evis/evisActionTypes";
import { genericActionTypes } from "../../../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import SelectedFeedsView from "../../../../commonComponents/reusableComponents/selectedFeedsView/SelectedFeedsView";
/**
 * The EvisFeedDisplay component is responsible for displaying and managing the state of selected EVIS feeds.
 * It handles user interactions such as selecting, deselecting, and deleting EVIS feeds and dispatches
 * appropriate actions to update the global state.
 * 
 * @component
 * @returns {JSX.Element} The rendered component that displays selected EVIS feeds.
 */
export default function EvisFeedDisplay() {
    const { storeState, storeDispatch } = React.useContext(GlobalContext);
    const { evisStates } = storeState;
    const { selectedEvisIdsCheckboxData, activeCheckedEvisFeeds } = evisStates;

    /**
    * Handles the change event of individual EVIS feed checkboxes.
    * Adds or removes the selected feed from the active checked feeds based on the checkbox state.
    * 
    * @param {Event} e - The change event from the checkbox input.
    */
    const handleCheckBoxChange = (e) => {
        const { checked: isChecked, name: selectedFeed } = e.target;

        const evisStreams = selectedEvisIdsCheckboxData
            .filter((feed) => feed.evisIdName === selectedFeed)
            .flatMap((feed) => feed.evisStreams);

        const actions = [
            {
                type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS,
                operation: isChecked ? genericActionTypes.OPERATION_ADD : genericActionTypes.OPERATION_REMOVE,
                feed: selectedFeed,
            },
            {
                type: evisActionTypes.EVIS_ID_DATA,
                operation: isChecked ? genericActionTypes.OPERATION_ADD : genericActionTypes.OPERATION_REMOVE,
                [isChecked ? "showThisEvisId" : "removeThisEvisId"]: evisStreams,
            },
            {
                type: evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST,
                operation: isChecked ? genericActionTypes.OPERATION_ADD : genericActionTypes.OPERATION_REMOVE,
                [isChecked ? "newAvocViewEvisId" : "removeThisAvocViewEvisId"]: selectedFeed,
            },
        ];

        if (isChecked) {
            actions.push({
                type: evisActionTypes.EVIS_SHOW_ID_DATA,
                showEvisIdData: true,
            });
        }

        storeDispatch({ type: "MULTIPLE_ACTIONS", actions });
    };
    /**
     * Handles the cancel button click event for an individual EVIS feed.
     * Removes the feed and its associated streams from the global state.
     * 
     * @param {string} evisIdName - The name of the EVIS feed to be removed.
     */
    const handleCancelClick = (evisIdName) => {
        const evisStreamsToRemove = selectedEvisIdsCheckboxData
            .filter((feed) => feed.evisIdName === evisIdName)
            .flatMap((feed) => feed.evisStreams);

        storeDispatch({
            type: "MULTIPLE_ACTIONS",
            actions: [
                {
                    type: evisActionTypes.EVIS_ID_DATA,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeThisEvisId: evisStreamsToRemove,
                },
                {
                    type: evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeSelectedEvisFeedFromCheckBoxSectionData: evisIdName,
                },
                {
                    type: evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeThisAvocViewEvisId: evisIdName,
                },
                {
                    type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    feed: evisIdName,
                },
            ],
        });
    };

    /**
     * Handles the deletion of all selected EVIS feeds.
     * Resets the active checked feeds and removes all associated streams from the global state.
     */
    const handleDeleteAll = () => {
        const evisIds = selectedEvisIdsCheckboxData.map((feed) => feed.evisIdName);
        const evisStreams = selectedEvisIdsCheckboxData.flatMap((feed) => feed.evisStreams);

        storeDispatch({
            type: "MULTIPLE_ACTIONS",
            actions: [
                { type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS, operation: genericActionTypes.OPERATION_RESET },
                {
                    type: evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeSelectedEvisFeedFromCheckBoxSectionData: evisIds,
                },
                {
                    type: evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeThisAvocViewEvisId: evisIds,
                },
                {
                    type: evisActionTypes.EVIS_ID_DATA,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    removeThisEvisId: evisStreams,
                },
                { type: evisActionTypes.EVIS_SHOW_ID_DATA, showEvisIdData: false },
            ],
        });
    };

    /**
     * Handles the "select all" checkbox change event.
     * Selects or deselects all EVIS feeds based on the checkbox state.
     * 
     * @param {Event} e - The change event from the "select all" checkbox.
     */
    const handleSelectAll = (e) => {
        const { checked: isChecked } = e.target;

        if (isChecked) {
            const evisIds = selectedEvisIdsCheckboxData.map((feed) => feed.evisIdName);
            const evisStreams = selectedEvisIdsCheckboxData.flatMap((feed) => feed.evisStreams);

            storeDispatch({
                type: "MULTIPLE_ACTIONS",
                actions: [
                    {
                        type: evisActionTypes.EVIS_ID_DATA,
                        operation: genericActionTypes.OPERATION_ADD,
                        showThisEvisId: evisStreams,
                    },
                    {
                        type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS,
                        operation: "addAll",
                        feeds: evisIds,
                    },
                ],
            });
        } else {
            storeDispatch({ type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS, operation: genericActionTypes.OPERATION_RESET });

            selectedEvisIdsCheckboxData.forEach((evisIdData) => {

                storeDispatch({
                    type: genericActionTypes.MULTIPLE_ACTIONS,
                    actions: [
                        { type: evisActionTypes.EVIS_ID_DATA, operation: genericActionTypes.OPERATION_REMOVE, removeThisEvisId: evisIdData.evisStreams },
                        { type: evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST, operation: genericActionTypes.OPERATION_REMOVE, removeThisAvocViewEvisId: evisIdData.evisIdName },
                    ]
                });
            });
        }
    };

    return (
        <SelectedFeedsView
            selectedFeeds={selectedEvisIdsCheckboxData}
            checkedFeeds={activeCheckedEvisFeeds}
            handleCheckAll={handleSelectAll}
            handleDeleteAll={handleDeleteAll}
            handleCheckboxSelect={handleCheckBoxChange}
            handleCheckboxDelete={handleCancelClick}
            propertyToGetFeedName="evisIdName"
            message="No selected Evis IDs"
            connectionStatusProperty="status"
        />
    );
}
