import CloseIcon from '@mui/icons-material/Close';
import { Chip, Divider, Link, Stack, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const HelpDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export const HelpCard = ({ showCard, handleClose }) => {
    return (
        <React.Fragment>
            <HelpDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={showCard}
            >
                <DialogTitle sx={{ m: 0, p: 2, color: "#A0A0A0" }} id="customized-dialog-title">
                    Help Resources
                </DialogTitle>
                <Tooltip
                    enterNextDelay={500}
                    placement="top"
                    title="close"
                    arrow
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={(theme) => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500],
                        })}
                    >
                        <CloseIcon />
                    </IconButton>

                </Tooltip>

                <DialogContent dividers>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                    >
                        <Typography gutterBottom sx={{ color: "#A0A0A0", fontWeight: "bold" }}>
                            Developed By :
                        </Typography>

                        <Link
                            target="_blank"
                            href="https://permissions.amazon.com/a/team/DV%20Live%20Infrastructure"
                        >
                            <Typography gutterBottom>
                                PV-Live-Infra team
                            </Typography>
                        </Link>
                    </Stack>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                    >
                        <Typography gutterBottom sx={{ color: "#A0A0A0", fontWeight: "bold" }}>Contact:</Typography>
                        <Link
                            target="_blank"
                            href="mailto:dv-live-sys@amazon.com"
                        >
                            <Typography gutterBottom> dv-live-sys@</Typography>
                        </Link>
                        <Link
                            target="_blank"
                            href="mailto:ammandra@amazon.com"
                        >
                            <Typography gutterBottom> ammandra@</Typography>
                        </Link>
                    </Stack>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                    >
                        <Typography gutterBottom
                            sx={{ color: "#A0A0A0", fontWeight: "bold" }}
                        >
                            Slack Channel:
                        </Typography>
                        <Link
                            target="_blank"
                            href="https://amazon.enterprise.slack.com/archives/C0482MYE6BG"
                        >
                            <Typography gutterBottom> #streampreview-dashboard-customers</Typography>
                        </Link>
                    </Stack>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                    >
                        <Typography gutterBottom
                            sx={{ color: "#A0A0A0", fontWeight: "bold" }}
                        >
                            Powered by :
                        </Typography>

                        <Chip
                            // sx={{ color: "#A0A0A0", fontWeight: "bold" }}
                            color="primary" variant="outlined" size="small"
                            label="React"
                            onClick={() => window.open('https://create-react-app.dev/', '_blank', 'noopener, noreferrer')}
                        />

                        <Typography gutterBottom
                            sx={{ color: "#A0A0A0", fontWeight: "bold" }}
                        >
                            &
                        </Typography>

                        <Chip
                            color="primary" variant="outlined" size="small"
                            label="M.U.I"
                            onClick={() => window.open('https://mui.com/', '_blank', 'noopener, noreferrer')}
                        />
                    </Stack>
                </DialogContent>
            </HelpDialog>
        </React.Fragment>
    );
}