import { CardMedia, Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import React, { useContext, useMemo } from "react";

// Local imports
import { GlobalContext } from "../../../contexts/globalContextStore/GlobalContextStore";
import { useGetZixiConfigApiQuery, useZixiStreamApiQuery, useZixiThumbnailApiQuery } from "../../js/zixi/hooks/useZixiQueryUtils";

// TODO:

import { StreamViewCard } from "../../../commonComponents/reusableComponents/streamViewCard/StreamViewCard";
import {
    getZixiStreamActiveAlerts,
    getZixiStreamActiveInput,
    getZixiStreamAudioLevel,
    getZixiStreamAudioNorm,
    getZixiStreamEventNameAndId,
    getZixiStreamHwStatus,
    getZixiStreamInputBitrate,
    getZixiStreamLink,
    getZixiStreamThumbnailData,
    getZixiStreamUpTime,
} from "../../js/zixi/utils/zixiDashboardUtils";

export const ZixiStreamViewCard = (props) => {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { zixiStates } = storeState;
    const { attributeState } = zixiStates;

    const zixiStreamApiQueryResult = useZixiStreamApiQuery(props.streamName);
    const zixiThumbnailApiQueryResult = useZixiThumbnailApiQuery(zixiStreamApiQueryResult.data);
    const getZixiConfigApiQueryResult = useGetZixiConfigApiQuery(zixiStreamApiQueryResult.data);

    const zixiLinkComponent = () => {
        return (
            <Link
                sx={{ fontSize: "0.8rem" }}
                href={getZixiStreamLink(zixiStreamApiQueryResult.data).thisHref}
                target="_blank"
            >
                {getZixiStreamLink(zixiStreamApiQueryResult.data).thisLink}
            </Link>
        )

    }



    const zixiAttributes = useMemo(() => ({

        zixiLink: {
            label: "Zixi",
            data: {
                link: { label: "link", data: zixiLinkComponent() },
            }
        },
        activeAlerts: {
            label: "Active Alerts",
            data: getZixiStreamActiveAlerts(getZixiConfigApiQueryResult.data).thisStreamActiveAlerts,
        },
        encoderEvent: {
            label: "Encoder Event",
            data: `${getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventName} [${getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventId}]` || [],
        },
        encoderStats: {
            label: "Encoder Stats",
            data: {
                disk: { label: "Disk", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamDisk },
                ram: { label: "RAM", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamMem },
                cpu: { label: "CPU", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamCpu },
                gpu1: { label: "GPU1", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu1 },
                gpu2: { label: "GPU2", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu2 },
                gpu3: { label: "GPU3", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu3 },
                gpu4: { label: "GPU4", data: getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu4 },
                audioNormalization: { label: "Audio Normalization", data: getZixiStreamAudioNorm(getZixiConfigApiQueryResult.data).thisStreamAudioNorm },
            },
        },
        eventStats: {
            label: "Event Stats",
            data: {
                uptime: { label: "UpTime", data: getZixiStreamUpTime(getZixiConfigApiQueryResult.data).thisStreamUpTime },
                videoFrameRate: { label: "Input Bitrate", data: getZixiStreamInputBitrate(getZixiConfigApiQueryResult.data).thisInputBitrate },
                activeInput: { label: "Active Input State", data: getZixiStreamActiveInput(getZixiConfigApiQueryResult.data).thisStreamUri },
                audioLevel: { label: "Audio Level", data: getZixiStreamAudioLevel(getZixiConfigApiQueryResult.data).thisStreamAudioLevel },
            },
        },
    }), [zixiStreamApiQueryResult.data, getZixiConfigApiQueryResult.data]);

    return (
    <StreamViewCard
            queryResult={zixiStreamApiQueryResult} attributeData={zixiAttributes}
            firstHalfCardContent={<ZixiCardContent />} />
    );

    function ZixiCardContent() {
        return <>
            {/* <CardContent> */}
            <Typography variant="body1" display="block">
                {props.streamName}
            </Typography>

            {attributeState.encoder_link && (
                <Typography variant="caption" display="block">
                    {"Zixi: "}
                    <Link
                        sx={{ fontSize: "0.8rem" }}
                        href={getZixiStreamLink(zixiStreamApiQueryResult.data).thisHref}
                        target="_blank"
                    >
                        {getZixiStreamLink(zixiStreamApiQueryResult.data).thisLink}
                    </Link>
                </Typography>
            )}

            {attributeState.encoder_event && (
                <Typography variant="caption" display="block">
                    Event: {getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventName}{" "}
                    [{getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventId}]
                </Typography>
            )}

            {attributeState.event_stats && (
                <div>
                    <Typography variant="caption" display="block">
                        UpTime: {getZixiStreamUpTime(getZixiConfigApiQueryResult.data).thisStreamUpTime}
                    </Typography>

                    <Typography variant="caption" display="block">
                        Input Bitrate:{" "}
                        {getZixiStreamInputBitrate(getZixiConfigApiQueryResult.data).thisInputBitrate}
                    </Typography>

                    <Typography variant="caption" display="block">
                        Active input state:{" "}
                        {getZixiStreamActiveInput(getZixiConfigApiQueryResult.data).thisStreamUri}
                    </Typography>

                    <Typography mb={2} variant="caption" display="block">
                        Audio Level:{" "}
                        {getZixiStreamAudioLevel(getZixiConfigApiQueryResult.data).thisStreamAudioLevel}
                    </Typography>
                </div>
            )}

            {(
                <CardMedia
                    component="img"
                    sx={{
                        aspectRatio: 16 / 9,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        // objectFit: "contain",
                        padding: 1,
                        // backgroundColor: "grey",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}
                    image={"data:image/jpeg;base64," +
                        window.btoa(
                            getZixiStreamThumbnailData(zixiThumbnailApiQueryResult.data)
                                .thisStreamThumbnailData
                        )
                        // window.btoa(image2Data[0].binary_image )
                    }
                    alt="Event Thumbnail Image" />
            )}

            {attributeState.active_alerts && (
                <Typography mt={2} variant="caption" display="block">
                    Active Alerts:{" "}
                    {getZixiStreamActiveAlerts(getZixiConfigApiQueryResult.data).thisStreamActiveAlerts}
                </Typography>
            )}

            {attributeState.encoder_stats && (
                <div>
                    <Typography variant="caption" display="block">
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "fit-content",
                                borderRadius: 1,
                                "& hr": {
                                    mx: 1.0,
                                },
                            }}
                        >
                            Disk: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamDisk}
                            %
                            <Divider orientation="vertical" flexItem />
                            RAM: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamMem}
                            %
                            <Divider orientation="vertical" flexItem />
                            CPU: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamCpu}%
                        </Box>
                    </Typography>

                    <Typography variant="caption" display="block">
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "fit-content",
                                borderRadius: 1,
                                "& hr": {
                                    mx: 1.0,
                                },
                            }}
                        >
                            GPU: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu1}
                            %
                            <Divider orientation="vertical" flexItem />
                            {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu2}
                            %
                            <Divider orientation="vertical" flexItem />
                            {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu3}
                            %
                            <Divider orientation="vertical" flexItem />
                            {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu4}%
                        </Box>
                    </Typography>

                    <Typography variant="caption" display="block">
                        Audio normalization:{" "}
                        {getZixiStreamAudioNorm(getZixiConfigApiQueryResult.data).thisStreamAudioNorm}
                    </Typography>
                </div>
            )}
        </>;
    }
};
