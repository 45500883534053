import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useSearchParams } from "react-router-dom";

// Local imports
import { evisActionTypes } from "../contexts/evis/evisActionTypes";
import { genericActionTypes } from "../contexts/generic/genericActionTypes";
import { GlobalContext } from "../contexts/globalContextStore/GlobalContextStore";
import { getEvisIdData } from "../evis/js/utils/evisApiUtils";
import { sortEvisStreamsByPriority } from "../evis/js/utils/evisUtils";
import { isLoggerEnabled } from "../js/utilities.js/logger";

/* WebURL params component receives feeds & attributes via URL, varifies, parses and loads to respective
// global context states. We cant verify at this stage since we dont have DB data yet.
// Feeds are verified  and acted upon in "FeedSelectionOptions" and attributes are verified and upon in "AttributeSelector"
// This way we prevent multiple DB fetch and maintain a seperation between WebParams and userDirected workflows.
*/
// TODO: 
export const WebUrlParamsHandler = () => {
    // const useFeedDataApiQueryResultObj = useFeedHardwareEncoderDataApiQuery();
    const [searchParams] = useSearchParams();
    const [isParamsLoaded, setIsParamsLoaded] = useState(false);
    const currentParams = Object.fromEntries([...searchParams]);

    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;



    function processEncoderData() {
        const urlParamsFeedList = [];
        const urlParamsAttrList = [];

        if (Object.keys(currentParams).length > 0) {
            if (Object.keys(currentParams).includes("feeds")) {
                for (const feed of currentParams["feeds"].split(",")) {
                    urlParamsFeedList.push(feed);
                }
                storeDispatch({
                    type: "ZIXI_ADD_TO_URL_PARAMS_FEED_QUEUE",
                    urlParamsFeedList: urlParamsFeedList,
                });
            }
            //Process attributes
            if (Object.keys(currentParams).includes("attributes")) {
                for (const attr of currentParams["attributes"].split(",")) {
                    urlParamsAttrList.push(attr);
                }
                storeDispatch({
                    type: "ZIXI_ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE",
                    urlParamsAttrList: urlParamsAttrList,
                });
            }
        }

    }

    const fetchEvisStreams = async (evisId) => {
        function extractEvisGroup(evisId) {
            isLoggerEnabled && console.log("$$$ extarcting evis group from evisId", evisId);
            const data = dashboardFeedQueryData.dbEvisQueryData[0];
            for (const evisGroup in data) {
                const foundEvisGroup = data[evisGroup].find(item => item.evisId === evisId);
                if (foundEvisGroup) {
                    return evisGroup;
                }
            }
            isLoggerEnabled && console.warn("evisGroup not found for evisId: ", evisId);

            return null;
        }
        const evisGroup = extractEvisGroup(evisId);
        try {
            const evisIdStreamObjects = await getEvisIdData({ groupname: evisGroup, evisid: evisId }) || [];

            const sortedEvisIdStreamObjects = sortEvisStreamsByPriority(evisIdStreamObjects, evisId);


            if (sortedEvisIdStreamObjects.length === 0) {
                console.warn("No streams found for evisId: ", evisId);
            }
            if (sortedEvisIdStreamObjects.length > 0) {

                storeDispatch({
                    type: evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA,
                    operation: genericActionTypes.OPERATION_ADD,
                    addSelectedEvisFeedToCheckBoxSectionData: [{ evisIdName: evisId, evisStreams: sortedEvisIdStreamObjects }],
                });

                // Dispatch the fetched streams
                storeDispatch({
                    type: evisActionTypes.EVIS_ID_DATA,
                    operation: genericActionTypes.OPERATION_ADD,
                    showThisEvisId: sortedEvisIdStreamObjects,
                });
                storeDispatch({ type: "EVIS_SHOW_ID_DATA", showEvisIdData: true });
            }
        } catch (error) {
            console.error("Error fetching evis streams", error);
        }
    };

    async function processEvisData() {
        const urlParamEvisIdList = [];
        const urlParamEvisAttrList = [];

        if (Object.keys(currentParams).length > 0) {
            if (Object.keys(currentParams).includes("evisIds")) {
                for (const evisId of currentParams["evisIds"].split(",")) {
                    urlParamEvisIdList.push(evisId);
                }
                storeDispatch({
                    type: "EVIS_ID_URL_PARAMS_QUEUE",
                    operation: genericActionTypes.OPERATION_ADD,
                    urlParamEvisIdList: urlParamEvisIdList,
                });
            }
            //Process ds
            if (Object.keys(currentParams).includes("evisAttributes")) {
                for (const attr of currentParams["evisAttributes"].split(",")) {
                    urlParamEvisAttrList.push(attr);
                }
                storeDispatch({
                    type: "EVIS_ID_URL_PARAMS_ATTRIBUTES_QUEUE",
                    operation: genericActionTypes.OPERATION_ADD,
                    urlParamEvisAttrList: urlParamEvisAttrList,
                });
            }

            await Promise.all(urlParamEvisIdList.map(async (evisId) => {
                await fetchEvisStreams(evisId);

                storeDispatch({ type: "EVIS_SHOW_CHECKBOX_DATA", showEvisCheckboxData: true });

                storeDispatch({
                    type: "EVIS_AVOC_VIEW_FEED_LIST",
                    operation: genericActionTypes.OPERATION_ADD,
                    newAvocViewEvisId: evisId,
                });

            }));



        }
    }

    useEffect(() => {
        // const currentParams = Object.fromEntries([...searchParams]);
        isLoggerEnabled && console.log("loading tenant data in WebUrlParams"); //debug aam
        isLoggerEnabled && console.log("URL params=>"); //debug aam
        isLoggerEnabled && console.log(currentParams); //debug aam

        if (dashboardFeedQueryData && dashboardFeedQueryData.isDbQueryLoading === false) {
            processEncoderData();
            processEvisData();
            setIsParamsLoaded(true);
        }



    }, [dashboardFeedQueryData.isDbQueryLoading]);

    return (
        <>
            {isParamsLoaded ? (
                <Navigate to="/" replace={true} />
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "bottom", height: "inherit" }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};
