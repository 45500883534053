import { Box, Fade } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import scrollTopStyles from "./scrollTopStyles";

/**
 * ScrollTop component that displays a button or element that appears
 * when the user scrolls down the page. Clicking this element scrolls
 * the page back to the top.
 *
 * @component
 * @param {Object} props - The props for the ScrollTop component.
 * @param {React.ReactElement} props.children - The child elements to be displayed inside the scroll-to-top component(button or icon).
 * @returns {JSX.Element} The rendered ScrollTop component.
 */
export const ScrollTop = (props) => {
    const { children, window } = props;

    // Set up a scroll trigger that activates when the user scrolls down 100 pixels or more.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    /**
     * Handles the click event on the scroll-to-top button.
     * Scrolls the page to the element with the ID 'scroll-to-here'.
     *
     * @param {React.MouseEvent<HTMLElement>} event - The click event triggered by the user.
     */
    const handleScrollToTopButtonClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector("#scroll-to-here");

        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleScrollToTopButtonClick}
                role="presentation"
                sx={scrollTopStyles.position}
            >
                {children}
            </Box>
        </Fade>
    );
};

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};
