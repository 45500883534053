import React, { useCallback } from "react";
import { genericActionTypes } from "../../../../contexts/generic/genericActionTypes";
import { zixiActionTypes } from "../../../../contexts/zixi/zixiActionTypes";
import { getAliasFromFeedName, getStreamsFromAlias } from "../utils/dashboardUtils";

/**
 * Custom hook to handle encoder feeds.
 * Processes and dispatches actions based on URL params feed list.
 * 
 * @param {Array} urlParamsFeedList - List of feed names from URL params.
 * @param {Object} dashboardFeedQueryData - encoder data.
 * @param {Function} storeDispatch - Dispatch function from the global store.
 * @returns {Function} - A function to process encoder data.
 */
export function useHandleEncoderFeedsParam(urlParamsFeedList, dashboardFeedQueryData, storeDispatch) {
    return useCallback(() => {
        if (urlParamsFeedList.length > 0) {
            urlParamsFeedList.forEach(feed => {
                const feedAlias = getAliasFromFeedName(feed, dashboardFeedQueryData?.dbQueryData?.[0]);
                if (feedAlias !== undefined) {
                    storeDispatch({ type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS, operation: genericActionTypes.OPERATION_ADD, feed: feedAlias });
                    storeDispatch({ type: zixiActionTypes.ZIXI_CHECKBOX_SECTION_DATA, operation: genericActionTypes.OPERATION_ADD, addToCheckBoxSectionData: [feedAlias] });
                    storeDispatch({ type: zixiActionTypes.ZIXI_SHOW_CHECKBOX_DATA, showCheckboxData: true });

                    const showFeedData = getStreamsFromAlias(feedAlias, dashboardFeedQueryData.dbQueryData[0]);
                    if (showFeedData && showFeedData.length > 0) {
                        storeDispatch({ type: zixiActionTypes.ZIXI_FEED_DATA, operation: genericActionTypes.OPERATION_ADD, streams: showFeedData });
                        storeDispatch({ type: zixiActionTypes.ZIXI_SHOW_FEED_DATA, showFeedData: true });
                    }
                    storeDispatch({ type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST, operaton: "add", newAvocViewFeed: [feedAlias] });
                }
            });
        }
    }, [urlParamsFeedList, dashboardFeedQueryData, storeDispatch]);
}

/**
 * Custom hook for handling Encoder attributes.
 * It updates the global attribute state of Encoder with the attributes from the
 * urlParamsAttr list(This is where Encoder attributes from the URL are stored).
 * @param {Array} urlParamsAttrList - The list of attributes from the URL params
 * @param {function} storeDispatch - The dispatch function from the global context
 */
export const useEncoderAttrParamHandler = (urlParamsAttrList, storeDispatch) => {
    React.useEffect(() => {
        if (urlParamsAttrList.length > 0) {
            for (const attr of urlParamsAttrList) {
                if (attr === "showCustomLabelField") {
                    storeDispatch({
                        type: zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE,
                        newAttributeState: { showCustomLabelField: true },
                    });
                } else {
                    storeDispatch({
                        type: zixiActionTypes.ZIXI_SET_ATTRIBUTE_STATE,
                        newAttributeState: { [attr]: true },
                    });
                }
            }
        }
    }, [urlParamsAttrList]);
};