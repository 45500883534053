const thumbnailApiQueryRefreshInterval = 2000; //2 sec
const streamApiQueryRefreshInterval = 10000; //10sec
const configApiQueryRefreshInterval = 5000; //5 sec

/**
 * 
 * @description - This object contains the refresh interval for encoder query
 */
const encoderRefetchInterval = {
    thumbnail: thumbnailApiQueryRefreshInterval,
    stream: streamApiQueryRefreshInterval,
    config: configApiQueryRefreshInterval 
}

export { encoderRefetchInterval };