/**
 * Responsible in creating Evis group names and their respective evisIDNames. 
 * The function sorts the group names alphabetically and the evisIDNames within the group.
 * @param {Array} evisData array of objects with evisIDName and groupName properties
 * @returns {Array} array of objects with groupName and evisIDNames(array) properties
 */
export const organizeEvisDataByGroup = (evisData) => {
    if (!evisData) return {};

    const evisGroupsDetails = {};

    for (const { evisIDName, groupName, status } of evisData) {
        if (!evisGroupsDetails[groupName]) {
            evisGroupsDetails[groupName] = [];
        }
        evisGroupsDetails[groupName].push({ evisId: evisIDName, status: status });
    }

    return [Object.fromEntries(
        Object.keys(evisGroupsDetails)
            .sort((a, b) => a.localeCompare(b))
            .map((groupName) => [
                groupName,
                evisGroupsDetails[groupName].sort((a, b) => a.evisId.localeCompare(b.evisId))
            ])
    )];
};
/**
 * Sorts stream based on priority; Failover group, partner ingress, backfaul stream
 * @param {*} listOfStreamObjects stream objects
 * @param {*} evisId evisId
 * @returns sorted stream objects or empty array if listOfStreamObjects is not an array or empty
 */
const sortEvisStreamsByPriority = (listOfStreamObjects, evisId) => {
    const customSort = (array) => {
        const priorityList = ['dub-grp', 'dub-prim', 'lhr-grp', 'lhr-prim'];
        const priorityMap = new Map();
        priorityList.forEach((key, index) => priorityMap.set(key.toLowerCase(), index));

        const getPriority = (streamId) => {
            for (const [key, value] of priorityMap) {
                if (streamId.toLowerCase().includes(key)) {
                    return value;
                }
            }
            return priorityList.length;
        };

        array.sort((a, b) => {
            const priorityDiff = getPriority(a.stream_id) - getPriority(b.stream_id);
            if (priorityDiff !== 0) {
                return priorityDiff;
            } else {
                return a.stream_id.localeCompare(b.stream_id, undefined, { sensitivity: 'base' });
            }
        });
    };

    if (listOfStreamObjects && Array.isArray(listOfStreamObjects) && listOfStreamObjects.length > 0) {
        customSort(listOfStreamObjects);
    } else {
        return [];
    }

    listOfStreamObjects.forEach((streamObj) => {
        streamObj.evisId = evisId;
    });

    return listOfStreamObjects;
};

export const getInputType = (data) => {

    if (data !== undefined && data["evis.input_type"] !== undefined) {
        return data["evis.input_type"];
    } else {
        return "N/A";
    }


}

export const getBitrate = (data) => {

    if (data !== undefined && data["net_recv.bitrate"] !== undefined) {
        return data["net_recv.bitrate"];
    } else {
        return "N/A";
    }
}

// TODO:

 function getEvisSnapshotParams(selectedEvisIdsCheckboxData, evisAttributeState) {
    const evisIds = [];
    const evisAttributes = [];

    for (const evisId of selectedEvisIdsCheckboxData) {
        evisIds.push(evisId.evisIdName);
    }

    for (const [key, value] of Object.entries(evisAttributeState)) {
        if (value) {
            evisAttributes.push(key);
        }
    }

    return {
        evisIds: evisIds.toString(),
        evisAttributes: evisAttributes.toString(),
    };
}

export {
    sortEvisStreamsByPriority, getEvisSnapshotParams
};
