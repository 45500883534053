// Local imports
import { useContext } from "react";
import { AutoCompleteSelectItem } from "../../../../commonComponents/reusableComponents/autoComplete/AutoCompleteSelectItem";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";

/**
 * The EncoderTenantSelection component allows the user to select a tenant from a list of available tenants.
 *
 * @param {object} props - The component props.
 * @param {string} props.selectedZixiTenant - The currently selected Zixi tenant.
 * @param {function} props.setSelectedZixiTenant - A function to set the selected Zixi tenant.
 * @returns {JSX.Element} - The rendered EncoderTenantSelection component.
 */
export default function EncoderTenantSelection({ selectedZixiTenant, setSelectedZixiTenant }) {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;

    const { dashboardFeedQueryData } = genericStates;
    const tenants = dashboardFeedQueryData?.dbQueryData?.[0]
        ? Object.keys(genericStates.dashboardFeedQueryData.dbQueryData[0])
        : [];

    return (
        <AutoCompleteSelectItem
            list={tenants}
            selectedValue={selectedZixiTenant}
            setItem={setSelectedZixiTenant}
            label={"Select Tenants"} />

    );
}
