const thumbnailApiQueryRefreshInterval = 5000;
const attributeApiQueryRefreshInterval = 5000;

/**
 * @description - This object contains the refresh interval for evis query
 */
const evisRefetchInterval = {
    thumbnail: thumbnailApiQueryRefreshInterval,
    attribute: attributeApiQueryRefreshInterval
}
export { evisRefetchInterval };
