import { diff } from 'deep-object-diff';
import { isLoggerEnabled } from './logger';


/**
 * Function to log the state changes
 * @param {*} reducer the reducer function to be wrapped
 * @returns - a new reducer function that logs the state changes
 */
const contextLogger = (reducer) => (state, action) => {
  isLoggerEnabled && console.group(`Action: ${action.type}`);
  isLoggerEnabled && console.log('Prev State:', state);
  isLoggerEnabled && console.log('Action:', action);

  const nextState = reducer(state, action);
  const stateChanges = diff(state, nextState);

  isLoggerEnabled && console.log('State Changes:', stateChanges);
  isLoggerEnabled && console.log('Next State:', nextState);
  isLoggerEnabled && console.groupEnd();

  return nextState;
};

export default contextLogger;