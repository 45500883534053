const loginStyles = {
    container: {
        minHeight: "100vh",
        color: "inherit",
    },
    loginToolbar: {
        flexGrow: 1,
        color: (theme) =>
            theme.palette.primary.main
    },
    loginButton: {
        fontSize: "1.4rem",
    },
};

export default loginStyles;