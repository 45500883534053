const favoriteSessionStyles = {
    floatingIcon: {
        position: "fixed",
        top: 80,
        right: 16,
        zIndex: 1000,
        backgroundColor: "#ff9100",
        boxShadow: "0px 2px 10px rgba(234, 95, 15, 0.1)",
        "&:hover": {
            backgroundColor: "#49300f",
        },

    },
    bullet: {
        fontSize: "small",
        color: "#d18410"
    },
    favoriteItemText: {
        color: "#a89587",
        marginLeft: "8px"
    },
    divider: { borderColor: "#34312e" }


};

export default favoriteSessionStyles;
