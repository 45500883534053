import { makeAPICall } from "../../../../js/utilities.js/apiUtils";
import { encoderApiConfig } from "../../encoderApiConfig";

const path = "streampreview_dashboard_api/hwenc"
const getStreamConfig = (params) => makeAPICall(path, encoderApiConfig.getEncoderStreamConfig, params);
const getEventId = (params) => makeAPICall(path, encoderApiConfig.getEncoderEventId, params);
const getEventStatus = (params) => makeAPICall(path, encoderApiConfig.getEncoderEventStatus, params);
const getHardwareStatus = (params) => makeAPICall(path, encoderApiConfig.getEncoderHardwareStatus, params);
const getActiveAlertsCount = (params) => makeAPICall(path, encoderApiConfig.getEncoderActiveAlertsCount, params);
const getThumbnailData = (params) => makeAPICall(path, encoderApiConfig.getEncoderThumbnailData, params);

// Used by EncoderStreamViewCard
const getSCTElog = (params) => makeAPICall(path, encoderApiConfig.getEncoderSCTElog, params);


export {
    getActiveAlertsCount, getEventId,
    getEventStatus,
    getHardwareStatus, getSCTElog, getStreamConfig, getThumbnailData
};
// TODO:

