import { Box, Divider, Typography } from "@mui/material";
import React, { useContext, useState } from "react";

// Local imports
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import EncoderFeedSelection from "../../encoder/components/sidebar/datainput/EncoderFeedSelection";
import EncoderTenantSelection from "../../encoder/components/sidebar/datainput/EncoderTenantSelection";
import EvisGroupSelection from "../../evis/components/sidebar/datainput/EvisGroupSelection";
import EvisIDSelection from "../../evis/components/sidebar/datainput/EvisIDSelection";
import { ProcessWebUrlFeeds } from "../../js/utilities.js/ProcessWebUrlFeeds";
import DisplayFavoriteSession from "../../pages/favoritesession/DisplayFavoriteSession";
import AllAttributesDisplay from "../attributes/AllAttributesDisplay";
import { CustomAccordion } from "../CustomComponents";
import { Footer } from "../leftmenudrawer/Footer";
import ArchitectureTabs from "../reusableComponents/architectureTabs/ArchitectureTabs";
import AllSelectedFeedsDisplay from "../selectedfeedsection/AllSelectedFeedsDisplay";
import sideBarContentStyles from "./sideBarContentStyles";

/**
 * The `V1SidebarContents` component renders the sidebar content.
 * It includes various sub-components for selecting feeds and attributes,
 * and handles the loading state of the dashboard feed query data.
 * 
 * @returns {JSX.Element} - The rendered `V1SidebarContents` component.
 */
export default function V1SidebarContents() {
    const [selectedEvisGroup, setSelectedEvisGroup] = useState(null);
    const [selectedZixiTenant, setSelectedZixiTenant] = useState(null);
    // const [expanded, setExpanded] = useState('data');
    const [expanded, setExpanded] = useState(false);
    const [value, setValue] = useState(0);

    const { storeState } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;

    /**
     * Handles the tab change event.
     * @param {Event} event - the event object
     * @param {*} newValue - the new tab value
     */
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    /**
     * Handles the accordion change event.
     * @param {String} panel - the panel that was expanded or collabsed
     * @returns {Function} - the function to handle the accordion change event
     */
    const handleAccordionChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    /**
     *
     * The configuration object for the architectures to be displayed in the sidebar.
     * Used for selecting data
     * @type {Array<Object>} architectures
     */
    const architectures = [
        {
            label: "OnPrem Infra",
            component: (
                <>
                    <EncoderTenantSelection
                        selectedZixiTenant={selectedZixiTenant}
                        setSelectedZixiTenant={setSelectedZixiTenant}
                    />
                    {selectedZixiTenant && (
                        <EncoderFeedSelection selectedZixiTenant={selectedZixiTenant} />
                    )}
                </>
            )
        },
        {
            label: "EVIS",
            component: (
                <>
                    <EvisGroupSelection
                        selectedEvisGroup={selectedEvisGroup}
                        setSelectedEvisGroup={setSelectedEvisGroup}
                    />
                    {selectedEvisGroup && (
                        <EvisIDSelection selectedEvisGroup={selectedEvisGroup} />
                    )}
                </>
            )
        },

        // ADD: Add more architectures here
    ];

    return (
        <>
            {dashboardFeedQueryData?.isDbQueryLoading ? (
                <Box sx={sideBarContentStyles.loading}>
                    {/* <CircularProgress /> */}
                </Box>
            ) : (
                <>
                    {dashboardFeedQueryData && dashboardFeedQueryData.isDbQueryLoading === false && <ProcessWebUrlFeeds />}
                    <CustomAccordion
                        label="Select Tenants"
                        // expanded={expanded === 'data'}
                        expanded={expanded}
                        onChange={handleAccordionChange('data')}
                    >
                        <ArchitectureTabs architectures={architectures} value={value} handleTabChange={handleTabChange} />
                        <Divider sx={sideBarContentStyles.divider} />
                        <Typography sx={sideBarContentStyles.title}>Selected Feeds</Typography>
                        <Divider sx={sideBarContentStyles.divider} />

                        {/* Selected data */}
                        <AllSelectedFeedsDisplay value={value} handleTabChange={handleTabChange} />
                        <Divider sx={sideBarContentStyles.divider} />
                    </CustomAccordion>

                    <CustomAccordion label="Select Attributes">

                        {/* Attributes */}
                        <AllAttributesDisplay value={value} handleTabChange={handleTabChange} />
                    </CustomAccordion>

                    <CustomAccordion label="My Favorites">
                        <DisplayFavoriteSession />
                    </CustomAccordion>

                    < Footer />


                </>
            )}
        </>
    );
}
