import { useQuery } from "@tanstack/react-query";

// Local imports
import { isLoggerEnabled } from "../../../js/utilities.js/logger";
import { getEvisIdData, getEvisStreamAttribute, getEvisStreamThumbnail } from "../utils/evisApiUtils";
import { sortEvisStreamsByPriority } from "../utils/evisUtils";
import { evisRefetchInterval } from "./evisQueryConfig";


/**
 * Returns a function to fetch evis streams for a given evisId
 * @param {String} selectedEvisGroup - Selected evis group
 * @returns {Function} - Function to fetch evis streams for a given evisId
 */
export function useFetchEvisIdStreams(selectedEvisGroup) {
    const fetchEvisIdStreams = async (evisId) => {
        try {
            if (!evisId) throw new Error("evisId is required");
            const evisIdStreamObjects = await getEvisIdData({ groupname: selectedEvisGroup, evisid: evisId }) || [];
            return sortEvisStreamsByPriority(evisIdStreamObjects, evisId);
        } catch (error) {
            isLoggerEnabled && console.error("Error fetching evis streams", error);
            return [];
        }
    };
    return { fetchEvisIdStreams };
}

/**
 * Custom hook to fetch evis stream attributes by stream id, host and type
 * @param {Object} streamObject - Stream object
 * @param {String} streamObject.host - Host of the stream
 * @param {String} streamObject.stream_id - Stream ID
 * @param {String} streamObject.type - Type of the stream
 * 
 * @returns {Object} - Object containing evis attributes
 */
function useEvisAttributesById(streamObject) {
    let evis_host, evis_stream_id, type;
    if (streamObject) {
        evis_host = streamObject.host;
        evis_stream_id = streamObject.stream_id;
        type = streamObject.type === "target" ? "failover" : streamObject.type;
    }
    return useQuery(
        [type, evis_host, evis_stream_id],
        async () => {
            let attributeData = {};

            if (evis_host && evis_stream_id && type) {
                const getAttributeData = await getEvisStreamAttribute({ evis_host: evis_host, evis_stream_id: evis_stream_id, evis_input_type: type });
                attributeData = { ...attributeData, ...getAttributeData[0] };
            } else {
                isLoggerEnabled && console.warn("No attributes returned from API.");
                return [];
            }
            return attributeData;
        },
        {
            refetchInterval: evisRefetchInterval.attribute,
        }
    );
}



/**
 * Custom hook to fetch evis stream thumbnail by stream id and host
 * @param {*} streamObject - Stream object
 * @param {String} streamObject.host - Host of the stream
 * @param {String} streamObject.stream_id - Stream ID
 * 
 * @returns {Object} - Object containing evis thumbnail data
 */
function useEvisThumbnailApiQuery(streamObject) {

    let host_name, stream_id;

    if (streamObject) {
        host_name = streamObject.host;
        stream_id = streamObject.stream_id;
    }

    return useQuery(
        [host_name, stream_id],
        async () => {
            let thumbnailData = {};

            if (host_name && stream_id) {
                const getEvisThumbnailData = await getEvisStreamThumbnail({ host: host_name, stream_id: stream_id });
                thumbnailData = { ...thumbnailData, ...getEvisThumbnailData[0] };
            } else {
                console.warn("Cant execute getThumbnail. host_name & stream_id  not found for ", stream_id);
                return [];
            }
            return [thumbnailData];
        },
        {
            refetchInterval: evisRefetchInterval.thumbnail,
        }

    );
}

export { useEvisAttributesById, useEvisThumbnailApiQuery };

