import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Fab, styled, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { HelpCard } from './HelpCard';

const StyledFab = styled(Fab)(({ theme }) => ({
    backgroundColor: "#0f0f0f",
    color: "#ff9100",
    // size: "small",
    opacity: 0.6,
    // on hover
    "&:hover": {
        backgroundColor: "#2c1b03",
        // size: "small",
        Opacity: "0.8"
    },
}));


/**
 * Questions component to Launch modal with help content.
 * @returns {JSX.Element} The rendered component.
 */
export const Questions = () => {
    const [showCard, setShowCard] = useState(false);
    const handleClickOpen = () => {
        setShowCard(true);
    };
    const handleClose = () => {
        setShowCard(false);
    };

    return (
        <>
            <StyledFab
                color="inherit"
                aria-label="share"
                size="small"
                onClick={() => handleClickOpen()}
            >
                <Tooltip
                    enterNextDelay={500}
                    title="Questions / Help"
                    arrow
                >
                    <QuestionMarkIcon />
                </Tooltip>
            </StyledFab>
            {
                showCard && <HelpCard
                    showCard={showCard}
                    handleClose={handleClose}
                />
            }
        </>
    );
};
