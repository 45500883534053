import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { Autocomplete, Box, TextField } from "@mui/material";
import autoCompleteStyles from "./autoCompleteStyles";


export function AutoCompleteSelectItem({ list, selectedValue, setItem, label }) {
    return <Box sx={autoCompleteStyles.box}>
        <Autocomplete
            options={list}
            value={selectedValue}
            onChange={(event, selectedValue) => {
                setItem(selectedValue);
            }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.toString())}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="standard"
                    sx={autoCompleteStyles.dropdownItem}
                />
            )}
            clearIcon={<ClearIcon style={autoCompleteStyles.iconColor} />}
            popupIcon={<ArrowDropDownIcon style={autoCompleteStyles.iconColor} />}
        />
    </Box>;
}
