const drawerWidth = 300;
const drawerStyle = (theme) => ({
    width: drawerWidth,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: drawerWidth,
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,

        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: "#000000",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#160c00",
        },
    },
});

const drawerToolbar = (theme) => ({
    width: "100%",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    justifyContent: "center",
})

const dividerStyle = { borderColor: "#34312e" };


const leftMenuDrawerStyles = {
    drawerStyle: drawerStyle,
    drawerToolbar: drawerToolbar,
    dividerStyle: dividerStyle,
    closeIcon: { marginRight: "4px" },
};


export default leftMenuDrawerStyles;