import { Divider, Typography } from "@mui/material";
/**
 * This component is responsible for displaying the attributes information.
 * It can handle key-value pairs and nested objects.
 * @param {Object} attributes - The attributes object to display. 
 * @returns {JSX.Element} - The rendered `AttributesInfoDisplay` component.
 */
export const AttributesInfoDisplay = ({ attributes }) => {
    return (
        <div>
            {Object.entries(attributes).map(([key, value]) => (
                <div key={`${key}-${value}`}>
                    {typeof value !== 'object' ? (
                        <Typography variant="caption"><strong>{key}</strong> : {value}</Typography>
                    ) : (
                        <>
                            <Typography variant="caption"><strong>{value.label}: </strong></Typography>
                            {typeof value.data === 'object' && !Array.isArray(value.data) ? (
                                <div>
                                    {Object.entries(value.data).map(([dataKey, { label, data }]) => (
                                        <div key={`${label}-${dataKey}`}>
                                            <Typography variant="caption">
                                                {label}: {data}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <Typography variant="caption">{value.data}</Typography>
                            )}
                            <Divider />
                        </>
                    )}
                </div>
            ))}
        </div>
    );
};
