import { Button, CardMedia, Divider, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext } from "react";
import { Link as ReactLink } from "react-router-dom";

// Local imports
import { StreamViewCard } from "../../../commonComponents/reusableComponents/streamViewCard/StreamViewCard";
import { GlobalContext } from "../../../contexts/globalContextStore/GlobalContextStore";
import { useGetConfigApiQuery, useStreamApiQuery, useThumbnailApiQuery } from "../../js/encoder/hooks/useEncoderQueryUtils";
import {
    getStreamActiveAlerts,
    getStreamActiveInput,
    getStreamAudioLevel,
    getStreamAudioNorm,
    getStreamEventNameAndId,
    getStreamFrameRate,
    getStreamHwStatus,
    getStreamLink,
    getStreamThumbnailData,
    getStreamUpTime,
} from "../../js/encoder/utils/dashboardUtils";
// TODO:

export const EncoderStreamViewCard = (props) => {
    const { storeState } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;

    const { zixiStates } = storeState;
    const { attributeState } = zixiStates;

    const streamApiQueryResult = useStreamApiQuery(props.streamName) || {};
    const thumbnailApiQueryResult = useThumbnailApiQuery(streamApiQueryResult.data);
    const getConfigApiQueryResult = useGetConfigApiQuery(streamApiQueryResult.data);

    const handleShowScteData = (data) => {
        let scteUrlStr = "scte35Info?"
        scteUrlStr += `feed_name=${props.streamName}`
        scteUrlStr += `&event_id=${data[0].event_id}`
        scteUrlStr += `&live_service_endpoint=${data[0].live_service_endpoint}`

        return scteUrlStr
    }

    const getSeraphimLink = (streamName) => {
        // streamName = "tnf2024feed06p8-iad-1"
        let scte35Link = `https://seraphim.amazonavoc.com/_dashboards/app/discover?security_tenant=global#/`
        scte35Link += `?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))`
        scte35Link += `&_a=(columns:!(scte.splice.clock,scte.splice.inserttype,scte.splice.type,scte.splice.base64,scte.splice.duration)`
        scte35Link += `,filters:!(('$state':(store:appState),exists:(field:scte.splice.inserttype)`
        scte35Link += `,meta:(alias:!n,disabled:!f,index:ce0c5010-a7a4-11ea-a510-913854fb48c5,key:scte.splice.inserttype,negate:!f,type:exists,value:exists)))`
        scte35Link += `,index:ce0c5010-a7a4-11ea-a510-913854fb48c5,interval:auto,query:(language:kuery,query:'stream.name:%22${streamName}%22'),sort:!())`
        return scte35Link
    }


    const thumbnailImageComponent = () => {
        return (
            <CardMedia

                component="img"
                sx={{
                    aspectRatio: 16 / 9,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    padding: 1,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                }}
                image={
                    "data:image/jpeg;base64," +
                    window.btoa(
                        getStreamThumbnailData(thumbnailApiQueryResult.data).thisStreamThumbnailData
                    )
                }
                alt="Event Thumbnail Image"
            />
        )
    }

    const seraphimLinkComponent = () => {
        return (
            <Link
                sx={{ fontSize: "0.8rem" }}
                href={getSeraphimLink(props.streamName)}
                target="_blank"
            >
                Seraphim
            </Link>
        )

    }

    const encoderLinkComponent = () => {
        return (
            <Link
                sx={{ fontSize: "0.8rem" }}
                href={
                    getStreamLink(
                        streamApiQueryResult.data,
                        dashboardFeedQueryData.dbQueryData[0]
                    ).thisHref
                }
                target="_blank"
            >
                {
                    getStreamLink(
                        streamApiQueryResult.data,
                        dashboardFeedQueryData.dbQueryData[0]
                    ).thisLink
                }
            </Link>
        )
    }

    const hwEncoderLinkComponent = () => {

        return (
            <Link
                sx={{ fontSize: "0.8rem" }}
                href={handleShowScteData(streamApiQueryResult.data)}
                target="_blank"
            >
                HW-Encoder
            </Link>
        )
    }




    const encoderAttributes = {
        encoderLink: {
            label: "Encoder",
            data: {
                link: { label: "link", data: encoderLinkComponent() },
            }
        },
        encoderEvent: {
            label: "Encoder Event",
            data: `${getStreamEventNameAndId(streamApiQueryResult.data).thisStreamEventName}[${getStreamEventNameAndId(streamApiQueryResult.data).thisStreamEventId}]`,
        },
        activeAlerts: {
            label: "Active Alerts",
            data: `${getStreamActiveAlerts(getConfigApiQueryResult.data).thisStreamActiveAlerts}`,
        },
        eventStats: {
            label: "Event Stats",
            data: {
                uptime: { label: "UpTime", data: `${getStreamUpTime(getConfigApiQueryResult.data).thisStreamUpTime}` },
                videoFrameRate: { label: "Video Frame Rate", data: `${getStreamFrameRate(getConfigApiQueryResult.data).thisStreamFrameRate}` },
                activeInput: { label: "Active input on", data: `${getStreamActiveInput(getConfigApiQueryResult.data).thisStreamInput} : ${getStreamActiveInput(getConfigApiQueryResult.data).thisStreamUri}` },
                audioLevel: { label: "Audio Level", data: `${getStreamAudioLevel(getConfigApiQueryResult.data).thisStreamAudioLevel}` },
            }
        },
        encoderStats: {
            label: "Encoder Stats",
            data: {
                disk: { label: "Disk", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamDisk}%` },
                ram: { label: "RAM", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamMem}%` },
                cpu: { label: "CPU", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamCpu}%` },
                gpu1: { label: "GPU1", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu1}%` },
                gpu2: { label: "GPU2", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu2}%` },
                gpu3: { label: "GPU3", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu3}%` },
                gpu4: { label: "GPU4", data: `${getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu4}%` },
                audioNormalization: { label: "Audio normalization", data: `${getStreamAudioNorm(getConfigApiQueryResult.data).thisStreamAudioNorm}` },
            }
        },
        downloadButtons: {
            label: "SCTE35 Logs",
            data: {
                seraphim: { label: "Seraphim", data: seraphimLinkComponent() },
                // hwEncoder: { label: "HW-Encoder", data: hwEncoderLinkComponent() },
            }

        }
    };


    const eventStatsComponent = (value) => {
        return (
            <Typography variant="caption" display="block">
                {value}
            </Typography>
        )
    }

    const FirstHalfCardContent = () => {
        const encoderLink = encoderAttributes.encoderLink;
        const encoderEvent = encoderAttributes.encoderEvent;
        const activeAlerts = encoderAttributes.activeAlerts;
        return (
            <>
                <Typography variant="body1" display="block">
                    {props.streamName}
                </Typography>

                {attributeState.encoder_link && (
                    <Typography variant="caption" display="block">
                        {encoderLink.label}:
                        {encoderLink.data.link.data}
                    </Typography>
                )}

                {attributeState.encoder_event && (
                    <Typography variant="caption" display="block">
                        {encoderEvent.label}: {encoderEvent.data}
                    </Typography>
                )}

                {attributeState.active_alerts && (
                    <Typography variant="caption" display="block">
                        {activeAlerts.label}{": "}
                        {activeAlerts.data}
                    </Typography>
                )}

                {thumbnailImageComponent()}

            </>
        )
    }

    const SecondHalfCardContent = () => {
        const eventStatsData = encoderAttributes.eventStats.data;
        return (
            <>
                {attributeState.event_stats && (
                    <div>
                        {eventStatsComponent(eventStatsData.uptime.label + ":" + eventStatsData.uptime.data)}
                        {eventStatsComponent(eventStatsData.videoFrameRate.label + ":" + eventStatsData.videoFrameRate.data)}
                        {eventStatsComponent(eventStatsData.activeInput.label + ":" + eventStatsData.activeInput.data)}
                        {eventStatsComponent(eventStatsData.audioLevel.label + ":" + eventStatsData.audioLevel.data)}
                    </div>
                )}

                {attributeState.encoder_stats && (
                    <div>
                        <Typography variant="caption" display="block">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                    borderRadius: 1,
                                    "& hr": {
                                        mx: 1.0,
                                    },
                                }}
                            >
                                {encoderAttributes.encoderStats.data.disk.label}{": "}{encoderAttributes.encoderStats.data.disk.data}
                                <Divider orientation="vertical" flexItem />
                                {encoderAttributes.encoderStats.data.ram.label}{": "}{encoderAttributes.encoderStats.data.ram.data}

                                <Divider orientation="vertical" flexItem />
                                {encoderAttributes.encoderStats.data.cpu.label}{": "}{encoderAttributes.encoderStats.data.cpu.data}

                            </Box>
                        </Typography>

                        <Typography variant="caption" display="block">
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                    borderRadius: 1,
                                    "& hr": {
                                        mx: 1.0,
                                    },
                                }}
                            >
                                GPU: {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu1}
                                %
                                <Divider orientation="vertical" flexItem />
                                {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu2}
                                %
                                <Divider orientation="vertical" flexItem />
                                {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu3}
                                %
                                <Divider orientation="vertical" flexItem />
                                {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu4}%
                            </Box>
                        </Typography>

                        <Typography variant="caption" display="block">
                            Audio normalization:{" "}
                            {getStreamAudioNorm(getConfigApiQueryResult.data).thisStreamAudioNorm}
                        </Typography>
                    </div>
                )}
                {attributeState.download_buttons && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "left",
                            width: "100%",
                            mt: 1,
                            fontSize: "0.6rem"
                        }}
                    >
                        <Typography variant="caption" display="block">
                            {"SCTE35 Logs: "}
                        </Typography>
                        <Button
                            component={Link}
                            to="/about"
                            href={getSeraphimLink(props.streamName)}
                            target="_blank"
                            variant="outlined"
                            size="small"
                            sx={{
                                border: 'none', textTransform: "none", fontSize: "0.7rem",
                                "&:hover": { color: '#FFFDD0', border: 'none', background: "#707070" }
                            }}
                        >
                            {"Seraphim"}
                        </Button>

                        <Divider orientation="vertical" flexItem />
                        <Button
                            component={ReactLink}
                            to={`${handleShowScteData(streamApiQueryResult.data)}`}
                            href={`${handleShowScteData(streamApiQueryResult.data)}`}
                            target="_blank"
                            referrer="noreferrer"
                            variant="outlined"
                            size="small"
                            sx={{
                                border: 'none', textTransform: "none", fontSize: "0.7rem",
                                "&:hover": { color: '#FFFDD0', border: 'none', background: "#707070" }
                            }}
                        >
                            {"HW-Encoder"}
                        </Button>
                    </Box>
                )}

            </>
        )
    }



    return (
        <StreamViewCard
            firstHalfCardContent={<FirstHalfCardContent />}
            secondHalfCardContent={<SecondHalfCardContent />}
            queryResult={streamApiQueryResult}
            attributeData={encoderAttributes}
        />

    );
};
