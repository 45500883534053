import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import PropTypes from "prop-types";
import React from "react";

// Local imports
import { StyledTypographyAttributesLabel } from "../../CustomComponents";
import archAttributesStyles from "./archAttributesStyles";


/**
 * ArchAttributes Component
 *
 * This component renders a group of checkboxes for managing attributes, including a "Check All" option.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.handleCheckboxChange - Function to handle the change event for individual checkboxes.
 * @param {Function} props.handleCheckAll - Function to handle the "Check All" checkbox event.
 * @param {Object} props.attributes - The state of the attributes being managed.
 * @param {Array} props.firstGroupCheckboxes - The first group of checkboxes.
 * @param {Array} props.secondGroupCheckboxes - The second group of checkboxes.
 * @returns {React.Element} The rendered component.
 */
const ArchAttributes = ({ handleCheckboxChange, handleCheckAll, attributes, firstGroupCheckboxes, secondGroupCheckboxes }) => {
    return (
        <Box sx={archAttributesStyles.formControl}>
            <CheckAllCheckBox attributes={attributes} handleCheckAll={handleCheckAll} />
            <AttributesCheckboxGroup
                checkboxes={firstGroupCheckboxes}
                handleCheckboxChange={handleCheckboxChange}
                attributeState={attributes}
            />
            <AttributesCheckboxGroup
                checkboxes={secondGroupCheckboxes}
                handleCheckboxChange={handleCheckboxChange}
                attributeState={attributes}
            />
        </Box>
    );
};

ArchAttributes.propTypes = {
    handleCheckboxChange: PropTypes.func.isRequired,
    handleCheckAll: PropTypes.func.isRequired,
    attributes: PropTypes.object.isRequired,
    firstGroupCheckboxes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    secondGroupCheckboxes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ArchAttributes;

/**
 * CheckAllCheckBox Component
 * 
 * Renders a single checkbox to check/uncheck all attributes.
 * 
 * @param {Object} props - The props passed to the component.
 * @param {Object} props.attributes - The state of the attributes being managed.
 * @param {Function} props.handleCheckAll - Function to handle the "Check All" checkbox event.
 * @returns {React.Element} The rendered component.
 */
const CheckAllCheckBox = ({ attributes, handleCheckAll }) => (
    <FormControl sx={archAttributesStyles.formControl} component="fieldset" variant="standard">
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        size="small"
                        checked={Object.values(attributes).every((value) => value === true)}
                        onChange={handleCheckAll}
                        name="checkAll"
                        sx={archAttributesStyles.checkboxBoarder}
                    />
                }
                sx={archAttributesStyles.checkAllCheckbox}
                label={<StyledTypographyAttributesLabel>Check all</StyledTypographyAttributesLabel>}
            />
        </FormGroup>
    </FormControl>
);

CheckAllCheckBox.propTypes = {
    attributes: PropTypes.object.isRequired,
    handleCheckAll: PropTypes.func.isRequired,
};

/**
 * AttributesCheckboxGroup Component
 * 
 * Renders a group of checkboxes based on the given configs.
 * 
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.checkboxes - Array of checkbox configs (name, label).
 * @param {Function} props.handleCheckboxChange - Function to handle the checkbox change event.
 * @param {Object} props.attributeState - Object containing the current state of each attribute.
 * @returns {React.Element} A form control component containing the checkboxes.
 */
const AttributesCheckboxGroup = ({ checkboxes, handleCheckboxChange, attributeState }) => (
    <FormControl sx={archAttributesStyles.groupFormControl} component="fieldset" variant="standard">
        {checkboxes.map(({ name, label }) => (
            <FormControlLabel
                key={`${name}-${label}`}
                control={
                    <Checkbox
                        size="small"
                        checked={attributeState[name]}
                        onChange={handleCheckboxChange}
                        name={name}
                        sx={archAttributesStyles.checkboxBoarder}
                    />
                }
                sx={archAttributesStyles.checkBoxHover}
                label={<StyledTypographyAttributesLabel>{label}</StyledTypographyAttributesLabel>}
            />
        ))}
    </FormControl>
);

AttributesCheckboxGroup.propTypes = {
    checkboxes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    attributeState: PropTypes.object.isRequired,
};
