import MenuIcon from "@mui/icons-material/Menu";
import { Box, Fab, Snackbar, Stack, styled, Tooltip } from "@mui/material";
import React, { useContext, useState } from "react";
import { genericActionTypes } from '../../contexts/generic/genericActionTypes';
import { GlobalContext } from '../../contexts/globalContextStore/GlobalContextStore';
import { FavoriteSession } from "../../pages/favoritesession/FavoriteSession";
import { FloatingNotesIcon } from "../../pages/notes/FloatingNotesIcon";
import { Questions } from "../../pages/questions/Questions";
import { Snapshot } from "../../pages/shareicon/Snapshot";


const StyledBox = styled(Box)(({ theme }) => ({
    position: "fixed",
    top: theme.spacing(1),
    left: theme.spacing(1),
}));

const StyledFab = styled(Fab)(({ theme }) => ({
    backgroundColor: "#0f0f0f",
    color: "#ff9100",
    // size: "small",
    // on hover
    "&:hover": {
        backgroundColor: "#2c1b03",
        // size: "small",
    },
}));


/**
 * Generates a snapshot URL based on the provided parameters and copies it to the clipboard.
 * Displays a notification to indicate that the URL has been copied.
 * @return {JSX.Element} - The rendered component.
 */
export default function LeftMenuIcons() {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;
    const { openSideBar } = genericStates;

    const [snackBarOpen, setSnackBarOpen] = useState(false);

    /**
     * Handles the drawer toggle event.
     */
    const handleDrawerToggle = () => {
        storeDispatch({ type: genericActionTypes.OPEN_SIDEBAR, openSideBar: !openSideBar });
    };

    return (
        <React.Fragment>
            <StyledBox>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <StyledFab size="small" aria-label="toggle drawer" onClick={handleDrawerToggle}>
                            <Tooltip enterNextDelay={500} title="Open Menu Options" arrow>
                                <MenuIcon />
                            </Tooltip>
                        </StyledFab>
                    </div>
                    <div>
                        < FloatingNotesIcon />
                    </div>
                    <div>
                        <FavoriteSession />
                    </div>
                    <div>
                        <Snapshot />
                    </div>
                    <div>
                        <Snackbar
                            open={snackBarOpen}
                            onClose={() => setSnackBarOpen(false)}
                            autoHideDuration={1000}
                            message="Copied to clipboard"
                        />

                    </div>
                    <div>
                        < Questions />
                    </div>
                </Stack>
            </StyledBox>
        </React.Fragment>
    );
}
