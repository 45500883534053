/**
 * List of regions
 */
const regionList = [
    "iad-1",
    "iad-2",
    "sfo-1",
    "sfo-2",
    "pdx-1",
    "pdx-2",
    "gru-1",
    "gru-2",
    "dub-1",
    "dub-2",
    "fra-1",
    "fra-2",
    "lhr-1",
    "lhr-2",
    "bom-1",
    "bom-2",
    "sin-1",
    "sin-2",
    "nrt-1",
    "nrt-2",
    "nrt-3",
    "nrt-4",
    "syd-1",
    "syd-2",
];

export { regionList };
