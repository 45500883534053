import { Accordion, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { FavoriteSession } from '../../pages/favoritesession/FavoriteSession';
import { FloatingNotesIcon } from '../../pages/notes/FloatingNotesIcon';
import { Questions } from '../../pages/questions/Questions';
import { Snapshot } from '../../pages/shareicon/Snapshot';
import { StyledAccordionSummary } from "../CustomComponents";

/**
 * Footer for Left Menu Drawer
 * @returns {JSX.Element} - A React component that displays the footer.
 * @constructor
 */
export const Footer = () => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    marginTop: "auto",
                }}
                component="footer"
            >
                <Accordion expanded={true}>
                    <StyledAccordionSummary
                        aria-controls="footer"
                        id={"footer"}
                        sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontWeight: "bold",
                            // pointerEvents: "none",
                            // cursor: "default",
                        }}
                    >

                        <Stack spacing={2}>

                            <Stack direction="row" spacing={4}>
                                <div>
                                    < Questions />
                                </div>

                                <div>
                                    < FloatingNotesIcon />
                                </div>
                                <div>
                                    <FavoriteSession />
                                </div>
                                <div>
                                    <Snapshot />
                                </div>

                            </Stack>

                            <div>
                                <Typography variant="subtitle1" sx={{
                                    display: "inline-block",
                                    pointerEvents: "none !important",
                                    cursor: "none !important",
                                }}>
                                    Developed by: <i> {"PV-Live-Infra"} </i>
                                </Typography>

                            </div>

                        </Stack>
                    </StyledAccordionSummary>
                </Accordion>
            </Box>
        </React.Fragment>

    );
};

