const ZIXI_ACTIVE_CHECKED_ITEMS = "ZIXI_ACTIVE_CHECKED_ITEMS";
const ZIXI_CHECKBOX_SECTION_DATA = "ZIXI_CHECKBOX_SECTION_DATA";
const ZIXI_AVOC_VIEW_FEED_LIST = "ZIXI_AVOC_VIEW_FEED_LIST";
const ZIXI_FEED_DATA = "ZIXI_FEED_DATA";
const ZIXI_SHOW_FEED_DATA = "ZIXI_SHOW_FEED_DATA";
const ZIXI_SHOW_CHECKBOX_DATA = "ZIXI_SHOW_CHECKBOX_DATA";
const ZIXI_SHOW_SCTE_EVENTS = "ZIXI_SHOW_SCTE_EVENTS";
const ZIXI_SET_SCTE_DATA = "ZIXI_SET_SCTE_DATA";
const ZIXI_ADD_TO_URL_PARAMS_FEED_QUEUE = "ZIXI_ADD_TO_URL_PARAMS_FEED_QUEUE";
const ZIXI_ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE = "ZIXI_ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE";
const ZIXI_SET_ATTRIBUTE_STATE = "ZIXI_SET_ATTRIBUTE_STATE";

export const zixiActionTypes = {
    ZIXI_ACTIVE_CHECKED_ITEMS,
    ZIXI_CHECKBOX_SECTION_DATA,
    ZIXI_AVOC_VIEW_FEED_LIST,
    ZIXI_FEED_DATA,
    ZIXI_SHOW_FEED_DATA,
    ZIXI_SHOW_CHECKBOX_DATA,
    ZIXI_SHOW_SCTE_EVENTS,
    ZIXI_SET_SCTE_DATA,
    ZIXI_ADD_TO_URL_PARAMS_FEED_QUEUE,
    ZIXI_ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE,
    ZIXI_SET_ATTRIBUTE_STATE

};