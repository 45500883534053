export const handleOpenSidebar = (state, action) => {
    return {
        ...state,
        openSideBar: action.openSideBar,
    };
}

export const handleSetFromLoginPage = (state, action) => {
    return {
        ...state,
        fromLoginPage: action.fromLoginPage,
    };
}

export const handleSetDebugOn = (state, action) => {
    return {
        ...state,
        isDebugOn: action.isDebugOn,
    };

}

export const handleGetDashboardFeedQueryData = (state, action) => {
    return {
        ...state,
        dashboardFeedQueryData: action.dashboardFeedQueryData,

    };
}


export const handleSetCustomLabelEnabled = (state, action) => {
    return {
        ...state,
        customLabelEnabled: !state.customLabelEnabled,
    };
}
