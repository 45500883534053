// Local imports
import PropTypes from "prop-types";
import React from "react";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { AutoCompleteSelectItem } from "../../../../commonComponents/reusableComponents/autoComplete/AutoCompleteSelectItem";
/**
 * EvisGroupSelection component.
 * This component allows the user to select an EVIS group from a dropdown menu.
 * The available groups are derived from the dashboard feed query data.
 * 
 * @component
 * @param {Object} props - The props object.
 * @param {string} props.selectedEvisGroup - The currently selected EVIS group.
 * @param {function} props.setSelectedEvisGroup - Function to update the selected EVIS group.
 * @returns {JSX.Element} The rendered EvisGroupSelection component.
 */
export default function EvisGroupSelection({ selectedEvisGroup, setSelectedEvisGroup }) {
    const { storeState } = React.useContext(GlobalContext);
    const { genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;

    const evisGroups = dashboardFeedQueryData?.dbEvisQueryData?.[0]
        ? Object.keys(dashboardFeedQueryData.dbEvisQueryData[0])
        : [];

    return (
        <AutoCompleteSelectItem
            list={evisGroups}
            selectedValue={selectedEvisGroup}
            setItem={setSelectedEvisGroup}
            label={"Select EVIS Group"}
        />
    );
}

EvisGroupSelection.propTypes = {
    selectedEvisGroup: PropTypes.string.isRequired,
    setSelectedEvisGroup: PropTypes.func.isRequired,
};
