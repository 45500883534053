import { Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import React, { useContext } from "react";

// Local imports
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import StreamViewer from "../streamviewer/StreamViewer";
import thumbnailViewerStyles from "./thumbnailViewerStyles";

/**
 * Dashboard component that displays a thumbnail viewer.
 * Shows a loading spinner while data is loading, and displays streams once loaded.
 * 
 * @returns {JSX.Element} - The rendered JSX for the thumbnail viewer.
 */
export default function ThumbnailViewer() {
    const { storeState } = useContext(GlobalContext);
    const { genericStates } = storeState;

    return (
        <React.Fragment>
            <Box sx={{ display: "flex" }}>
                <Grid
                    container
                    justifyContent="center"
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 4 }}
                    sx={{ flexGrow: 1 }}
                >
                    {genericStates.dashboardFeedQueryData.isDbQueryLoading ? (
                        <Box
                            marginTop={4}
                            sx={thumbnailViewerStyles.spinner}
                        >
                            <CircularProgress size="10vh" />
                        </Box>

                    ) : (
                        <StreamViewer />
                    )}
                </Grid>
            </Box>
        </React.Fragment>
    );
}

// PropTypes validation
ThumbnailViewer.propTypes = {
    props: PropTypes.object,
};
