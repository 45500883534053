const streamApiQueryRefreshInterval = 60000; //60sec
const thumbnailApiQueryRefreshInterval = 2000; //2 sec
const configApiQueryRefreshInterval = 5000; //5 secs

/**
 * @description - This object contains the refresh interval for zixi query
 */
const zixiRefetchInterval = {
    thumbnail: thumbnailApiQueryRefreshInterval,
    stream: streamApiQueryRefreshInterval,
    config: configApiQueryRefreshInterval 
}
export { zixiRefetchInterval };