import React from "react";

// Local imports
import SelectedFeedsView from "../../../../commonComponents/reusableComponents/selectedFeedsView/SelectedFeedsView";
import { genericActionTypes } from "../../../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { zixiActionTypes } from "../../../../contexts/zixi/zixiActionTypes";
import { getStreamsFromAlias } from "../../../js/encoder/utils/dashboardUtils";

/**
 * The `EncoderFeedDisplay` component handles the display and management of the Encoder feed data.
 *
 * @returns {JSX.Element} - The rendered `EncoderFeedDisplay` component.
 */
export default function EncoderFeedDisplay() {
    const { storeState, storeDispatch } = React.useContext(GlobalContext);
    const { zixiStates, genericStates } = storeState;

    const { activeCheckedZixiFeeds, checkBoxSectionData } = zixiStates;
    const { dashboardFeedQueryData } = genericStates;

    /**
     * Handles the selection and deselection of Zixi feeds.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
     * @returns {void}
     */
    const handleZixiCheckBoxSelect = (e) => {
        const isChecked = e.target.checked;
        const selectedFeed = e.target.name;

        if (isChecked) {
            const streams = getStreamsFromAlias(selectedFeed, dashboardFeedQueryData.dbQueryData[0]);
            storeDispatch({
                type: genericActionTypes.MULTIPLE_ACTIONS,
                actions: [
                    {
                        type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                        operation: genericActionTypes.OPERATION_ADD,
                        feed: selectedFeed
                    },
                    {
                        type: zixiActionTypes.ZIXI_FEED_DATA,
                        operation: genericActionTypes.OPERATION_ADD,
                        streams: streams
                    },
                    {
                        type: zixiActionTypes.ZIXI_SHOW_FEED_DATA,
                        showFeedData: true
                    },
                    {
                        type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                        operation: genericActionTypes.OPERATION_ADD,
                        newAvocViewFeed: [selectedFeed]
                    },
                ]
            });

        } else {
            const streamsToRemove = getStreamsFromAlias(
                selectedFeed,
                dashboardFeedQueryData.dbQueryData[0]
            );

            storeDispatch({
                type: genericActionTypes.MULTIPLE_ACTIONS,
                actions: [
                    {
                        type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                        operation: genericActionTypes.OPERATION_REMOVE,
                        feed: selectedFeed
                    },
                    {
                        type: zixiActionTypes.ZIXI_FEED_DATA,
                        operation: genericActionTypes.OPERATION_REMOVE,
                        streams: streamsToRemove
                    },
                    {
                        type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                        operation: "removeItem",
                        removeThisAvocViewFeed: selectedFeed
                    }
                ]
            });
        }
    };

    /**
     * Handles the cancellation of a selected Zixi feed.
     * @param {*} thisfeed - The feed to be removed.
     * @returns {void}
     */
    const handleZixiCancelButtonClick = (thisfeed) => {
        const removeFeedData = getStreamsFromAlias(
            thisfeed,
            dashboardFeedQueryData.dbQueryData[0]
        );
        storeDispatch({
            type: genericActionTypes.MULTIPLE_ACTIONS,
            actions: [
                {
                    type: zixiActionTypes.ZIXI_CHECKBOX_SECTION_DATA,
                    operation: "removeItem",
                    removeFromCheckBoxSectionData: thisfeed
                },
                {
                    type: zixiActionTypes.ZIXI_FEED_DATA,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    streams: removeFeedData
                },
                {
                    type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                    operation: genericActionTypes.OPERATION_REMOVE,
                    feed: thisfeed
                },
                {
                    type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                    operation: "removeItem",
                    removeThisAvocViewFeed: thisfeed
                },
            ]
        });
    };

    /**
     * Handles the "Check All" functionality for Encoder feeds.
     * @param {React.ChangeEvent<HTMLInputElement>} e - The change event object.
     * @returns {void}
     */
    const handleCheckAll = (e) => {
        const isChecked = e.target.checked;
        if (isChecked) {

            storeDispatch({
                type: "MULTIPLE_ACTIONS",
                actions: [
                    {
                        type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                        operation: "addAll",
                        feeds: checkBoxSectionData
                    },
                    {
                        type: zixiActionTypes.ZIXI_SHOW_FEED_DATA,
                        showFeedData: true
                    },
                    {
                        type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                        operation: "addAll",
                        feeds: checkBoxSectionData
                    },
                ]
            });

            checkBoxSectionData.map((feed) => {
                if (!activeCheckedZixiFeeds.includes(feed)) {
                    const zixiStreamsToAdd = getStreamsFromAlias(feed, dashboardFeedQueryData.dbQueryData[0]);
                    storeDispatch({ type: zixiActionTypes.ZIXI_FEED_DATA, operation: genericActionTypes.OPERATION_ADD, streams: zixiStreamsToAdd });
                }
            });
        } else {

            storeDispatch({
                type: genericActionTypes.MULTIPLE_ACTIONS,
                actions: [
                    {
                        type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                        operation: genericActionTypes.OPERATION_RESET
                    },
                    {
                        type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                        operation: genericActionTypes.OPERATION_RESET
                    },
                    {
                        type: zixiActionTypes.ZIXI_FEED_DATA,
                        operation: genericActionTypes.OPERATION_RESET
                    },
                ]
            }
            );
        }
    };

    /**
     * Handles the deletion of all selected Encoder feeds.
     * @returns {void}
     * 
     */
    const deleteAll = () => {
        storeDispatch({
            type: genericActionTypes.MULTIPLE_ACTIONS,
            actions: [
                {
                    type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS,
                    operation: "reset"
                },
                {
                    type: zixiActionTypes.ZIXI_CHECKBOX_SECTION_DATA,
                    operation: "reset"
                },
                {
                    type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST,
                    operation: "reset"
                },
                {
                    type: zixiActionTypes.ZIXI_FEED_DATA,
                    operation: "reset"
                },
                {
                    type: zixiActionTypes.ZIXI_SHOW_FEED_DATA,
                    showFeedData: false
                },
                {
                    type: zixiActionTypes.ZIXI_SHOW_CHECKBOX_DATA,
                    showCheckboxData: false
                },
            ]
        })
    }


    return (
        <SelectedFeedsView
            selectedFeeds={checkBoxSectionData}
            checkedFeeds={activeCheckedZixiFeeds}
            handleCheckAll={handleCheckAll}
            handleDeleteAll={deleteAll}
            handleCheckboxSelect={handleZixiCheckBoxSelect}
            handleCheckboxDelete={handleZixiCancelButtonClick}
            message="No selected feeds"
        />
    );
}