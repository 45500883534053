// local imports
import { genericActionTypes } from "../generic/genericActionTypes";
import { evisActionTypes } from "./evisActionTypes";
import {
    handleEvisActiveCheckedItems,
    handleEvisAddIdToUrlParamsQueue,
    handleEvisAvocViewFeedList,
    handleEvisIdData,
    handleEvisSelectedIdToCheckBoxSectionData,
    handleEvisSetAttributeState,
    handleEvisShowCheckboxData,
    handleEvisShowIdData,
    handleEvisURLParamsIdAttrQueue
} from "./handleEvisActions";

export const evisInitialState = {
    checkedEvisIdsStreams: [],
    avocViewEvisIdList: [],
    selectedEvisIdsCheckboxData: [],
    showEvisCheckboxData: false,
    showEvisIdData: false,
    urlParamsEvisIdList: [],
    urlParamsEvisAttrList: [],
    activeCheckedEvisFeeds: [],
    evisAttributeState: {

        // all true by default
        ceid: true,
        host_url: true,
        input_type: true,
        input_error: false,
        latency: false,
        bitrate: false,
    },

};

export const evisReducer = (state = evisInitialState, action) => {
    switch (action.type) {
        case genericActionTypes.MULTIPLE_ACTIONS:
            return action.actions.reduce((acc, curr) => evisReducer(acc, curr), state);
        case evisActionTypes.EVIS_ACTIVE_CHECKED_IDS:
            return handleEvisActiveCheckedItems(state, action);

        case evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA:
            return handleEvisSelectedIdToCheckBoxSectionData(state, action);

        case evisActionTypes.EVIS_ID_DATA:
            return handleEvisIdData(state, action);

        case evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST:
            return handleEvisAvocViewFeedList(state, action);

        case evisActionTypes.EVIS_ID_URL_PARAMS_QUEUE:
            return handleEvisAddIdToUrlParamsQueue(state, action);

        case evisActionTypes.EVIS_ID_URL_PARAMS_ATTRIBUTES_QUEUE:
            return handleEvisURLParamsIdAttrQueue(state, action);
        case evisActionTypes.EVIS_SET_ATTRIBUTE_STATE:
            return handleEvisSetAttributeState(state, action);

        case evisActionTypes.EVIS_SHOW_CHECKBOX_DATA:
            return handleEvisShowCheckboxData(state, action);

        case evisActionTypes.EVIS_SHOW_ID_DATA:
            return handleEvisShowIdData(state, action);

        default:
            return state;
    }
};
