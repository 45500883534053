import React, { useContext, useMemo } from "react";

// Local imports
import { AutoCompleteSelectItems } from "../../../../commonComponents/reusableComponents/autoComplete/AutoCompleteSelectItems";
import { genericActionTypes } from "../../../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { zixiActionTypes } from "../../../../contexts/zixi/zixiActionTypes";
import { getStreamsFromAlias } from "../../../js/encoder/utils/dashboardUtils";


/**
 * The EncoderFeedSelection component allows the user to select feeds from a list of available feeds.
 *
 * @param {object} props - The component props.
 * @param {string} props.selectedZixiTenant - The currently selected Zixi tenant.
 * @returns {JSX.Element} - The rendered EncoderFeedSelection component.
 */
export default function EncoderFeedSelection({ selectedZixiTenant }) {

    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates, zixiStates } = storeState;

    const { dashboardFeedQueryData } = genericStates;
    const { checkBoxSectionData } = zixiStates;


    // preventing recalculation on every render
    const feeds = useMemo(() => {
        return dashboardFeedQueryData.dbQueryData[0][selectedZixiTenant]
            ?.map((o) => o.alias) || [];
    }
        , [dashboardFeedQueryData.dbQueryData, selectedZixiTenant]);


    /**
     * Handles feed selection. sets checkboxes
     * @param {*} event
     */
    const handleFeedSelect = (selectedFeed) => {

        if (checkBoxSectionData.includes(selectedFeed)) {
            return;
        }

        const feedStreams = getStreamsFromAlias(selectedFeed, dashboardFeedQueryData.dbQueryData[0]);
        storeDispatch({
            type: "MULTIPLE_ACTIONS",
            actions: [
                { type: zixiActionTypes.ZIXI_CHECKBOX_SECTION_DATA, operation: genericActionTypes.OPERATION_ADD, addToCheckBoxSectionData: [selectedFeed] },
                { type: zixiActionTypes.ZIXI_ACTIVE_CHECKED_ITEMS, operation: genericActionTypes.OPERATION_ADD, feed: selectedFeed },
                { type: zixiActionTypes.ZIXI_FEED_DATA, operation: genericActionTypes.OPERATION_ADD, streams: feedStreams },
                { type: zixiActionTypes.ZIXI_AVOC_VIEW_FEED_LIST, operation: genericActionTypes.OPERATION_ADD, newAvocViewFeed: [selectedFeed] },
                { type: zixiActionTypes.ZIXI_SHOW_CHECKBOX_DATA, showCheckboxData: true },
                { type: zixiActionTypes.ZIXI_SHOW_FEED_DATA, showFeedData: true },
            ]
        });
    };

    return (
        < AutoCompleteSelectItems
            list={feeds}
            handleSelect={handleFeedSelect}
            label={'Select Feeds'}
        />
    )
}

