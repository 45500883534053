const gridItemStyles = {
    display: "flex",
    color: "white",
    width: "270px",
    margin: "12px",
    lineHeight: "30px",
    fontSize: "30px",
    alignItems: "center",
};

const feedDividerStyle = { backgroundColor: '#472e04', width: "100%" };

const streamViewerStyles = {
    gridItem: gridItemStyles,
    feedDivider: feedDividerStyle,
};

export default streamViewerStyles;