import CloseIcon from "@mui/icons-material/Close";
import { Card, CardActions, CardContent, Grid, IconButton, Tooltip } from "@mui/material";
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { ThemeProvider } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';


// Local imports
import DashboardTheme from "../../../../../commonComponents/DashboardTheme";
import { GlobalContext } from "../../../../../contexts/globalContextStore/GlobalContextStore";
import { isLoggerEnabled } from "../../../../../js/utilities.js/logger";
import { getSCTElog } from "../../../../js/encoder/utils/encoderApiUtils";
import showScte35InfoStyles from "./showScte35InfoStyles";

/**
 * Handles the close tab functionality
 */
const closeTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
};

/**
 * The `ShowScte35Info` component displays the SCTE 35 information for a specific stream.
 * @param {*} params 
 * @returns 
 */
export const ShowScte35Info = (params) => {
    const { storeState, storeDispatch } = React.useContext(GlobalContext);
    const { genericStates } = storeState;




    const [encScteData, setEncScteData] = React.useState([])
    const [lookbackWindow, setLookbackWindow] = React.useState(5)
    const [searchParams] = useSearchParams();
    let feedName = searchParams.get('feed_name', undefined)


    /**
     * Fetches the SCTE log data based on the search parameters.
     *
     * @returns {Promise<void>} - A Promise that resolves when the SCTE log data is fetched.
     */
    React.useEffect(() => {

        const getScteLogData = async (queryParams) => {
            const response = await getSCTElog(queryParams)
            const data = await response
            setEncScteData(data)
        }
        isLoggerEnabled && console.log("searchParams", searchParams)
        isLoggerEnabled && console.log("searchParams.event_id", searchParams.get('event_id', undefined))
        isLoggerEnabled && console.log("searchParams.lookback_minutes", searchParams.get('lookback_minutes', undefined))
        isLoggerEnabled && console.log("searchParams.live_service_endpoint", searchParams.get('live_service_endpoint', undefined))
        isLoggerEnabled && console.log("searchParams.feed_name", feedName)
        isLoggerEnabled && console.log("encScteData: ", encScteData)


        const queryParams = {
            event_id: searchParams.get('event_id', undefined),
            lookback_minutes: "5",
            live_service_endpoint: searchParams.get('live_service_endpoint', undefined),
        }

        if (Object.values(queryParams).includes(undefined)) {
            setEncScteData([])
        } else {
            getScteLogData(queryParams)
        }
    }, [searchParams])

    return (
        <ThemeProvider theme={DashboardTheme(genericStates.toggleDark)}>
            <CssBaseline />
            <Container mt={20} maxWidth="xxl" >
                <Box sx={showScte35InfoStyles.box}>
                    <Grid
                        container
                        justifyContent="center"
                        spacing={1}
                        marginTop={2}
                        sx={showScte35InfoStyles.postion}
                    >
                        <Card maxWidth="inherit">
                            <CardActions>
                                <Box sx={showScte35InfoStyles.cardActions}>
                                    <Box sx={showScte35InfoStyles.cardActions}>
                                        <Grid container spacing={3}>
                                            <Grid item xs="auto">
                                                <showScte35InfoStyles.item>
                                                    <IconButton onClick={() => closeTab()}>
                                                        <Tooltip title="Close this pane" arrow>
                                                            <CloseIcon
                                                                sx={showScte35InfoStyles.closeIcon}
                                                            />
                                                        </Tooltip>
                                                    </IconButton>
                                                </showScte35InfoStyles.item>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <showScte35InfoStyles.item>
                                                    <Typography sx={showScte35InfoStyles.feedName} gutterBottom>
                                                        {`Feed   :  ${feedName}`}
                                                    </Typography>
                                                </showScte35InfoStyles.item>
                                            </Grid>
                                            <Grid item xs>
                                                <showScte35InfoStyles.item>
                                                    <Typography sx={showScte35InfoStyles.feedName} gutterBottom>
                                                        {`Lookback Window   :  ${lookbackWindow} mts`}
                                                    </Typography>
                                                </showScte35InfoStyles.item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </CardActions>
                            <Divider orientation="horizontal" variant="middle" flexItem />
                            <CardContent>
                                <div style={{ backgroundColor: "#121212" }}>
                                    {(encScteData !== undefined && Object.keys(encScteData).length > 0) ? (
                                        <>
                                            {
                                                Object.keys(encScteData).length > 0 && encScteData[0]["scte_data"].map((item, idx) => (
                                                    <code key={idx} style={showScte35InfoStyles.scteData}>
                                                        {item} <br />
                                                    </code>
                                                ))
                                            }

                                        </>

                                    ) : (
                                        <Box
                                            sx={showScte35InfoStyles.loadingContainer}
                                        >
                                            {" "}
                                            <CircularProgress />{" "}
                                        </Box>
                                    )}

                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Box>
            </Container>
        </ThemeProvider>
    );
}