import PropTypes from "prop-types";
import React, { useCallback, useContext, useMemo } from "react";
import { AutoCompleteSelectItems } from "../../../../commonComponents/reusableComponents/autoComplete/AutoCompleteSelectItems";
import { evisActionTypes } from "../../../../contexts/evis/evisActionTypes";
import { genericActionTypes } from "../../../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import { isLoggerEnabled } from "../../../../js/utilities.js/logger";
import { useFetchEvisIdStreams } from "../../../js/hooks/useEvisQueryUtils";

/**
 * EVIS ID selection component.
 * Responsible for rendering a multi-select dropdown list for selecting EVIS IDs.
 * It fetches the EVIS streams for the selected EVIS ID and updates the global state accordingly.
 * @param {Object} props - the component props. 
 * @param {string} props.selectedEvisGroup - the selected EVIS group.
 * @param {function} props.setSelectedEvisFeeds - the function to set the selected EVIS feeds.
 * @returns {React.Element} the rendered component.
 */
export default function EvisIDSelection({ selectedEvisGroup }) {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { evisStates, genericStates } = storeState;
    const { dashboardFeedQueryData } = genericStates;
    const { selectedEvisIdsCheckboxData } = evisStates;

    // preventing recalculation on every render
    const evisIds = useMemo(() => {
        return dashboardFeedQueryData.dbEvisQueryData[0][selectedEvisGroup] || [];
    }, [dashboardFeedQueryData, selectedEvisGroup]);

    isLoggerEnabled && console.log('evisIds', evisIds);

    // Sort the EVIS IDs based on their connection status
    evisIds.sort((a, b) => {
        if (a.status === 'connected' && b.status !== 'connected') {
            return -1;
        }
        if (a.status !== 'connected' && b.status === 'connected') {
            return 1;
        }
        return 0;
    });

    const { fetchEvisIdStreams } = useFetchEvisIdStreams(selectedEvisGroup);

    /**
     * Handles the selection of an Evis feed.
     * 
     * This function is triggered when a user selects an EVIS ID. It ensures that the selected EVIS ID is not already selected,
     * updates the list of selected EVIS IDs, fetches the corresponding EVIS streams, and dispatches multiple actions to update the global state.
     * @param {Event} event - The event object triggered by the selection. The selected value is always an array containing only one element.
     * @returns {Promise<void>} - A promise that resolves when the function completes. If the selected EVIS ID is already selected, the function returns early.
     */
    const handleEvisFeedSelect = useCallback(async (selectedItem) => {
        const selectedEvisId = selectedItem; // always contains 1 element

        if (!selectedEvisId || selectedItem === '') {
            return;
        }

        // check if the selected value is already selected
        if (selectedEvisIdsCheckboxData.some(item => item.evisIdName === selectedEvisId.evisId)) {
            return;
        }
        // Fetch evis streams for the current selected feed
        const evisStreams = await fetchEvisIdStreams(selectedEvisId.evisId);

        storeDispatch({
            type: genericActionTypes.MULTIPLE_ACTIONS,
            actions: [
                {
                    type: evisActionTypes.EVIS_SELECTED_ID_CHECKBOX_SECTION_DATA,
                    operation: genericActionTypes.OPERATION_ADD,
                    addSelectedEvisFeedToCheckBoxSectionData: [{ evisIdName: selectedEvisId.evisId, evisStreams: evisStreams, status: selectedEvisId.status }]
                },
                {
                    type: evisActionTypes.EVIS_ID_DATA,
                    operation: genericActionTypes.OPERATION_ADD,
                    showThisEvisId: evisStreams
                },
                {
                    type: evisActionTypes.EVIS_AVOC_VIEW_FEED_LIST,
                    operation: genericActionTypes.OPERATION_ADD,
                    newAvocViewEvisId: selectedEvisId.evisId,
                },
                {
                    type: evisActionTypes.EVIS_ACTIVE_CHECKED_IDS,
                    operation: genericActionTypes.OPERATION_ADD,
                    feed: selectedEvisId.evisId
                },
                {
                    type: evisActionTypes.EVIS_SHOW_CHECKBOX_DATA,
                    showEvisCheckboxData: true
                },
                {
                    type: evisActionTypes.EVIS_SHOW_ID_DATA,
                    showEvisIdData: true
                }
            ]
        });
    }, [fetchEvisIdStreams, selectedEvisIdsCheckboxData, storeDispatch]);

    return (
        <AutoCompleteSelectItems
            list={evisIds}
            handleSelect={handleEvisFeedSelect}
            label={'Select EVIS Id'}
            property={'evisId'}
            connectionStatusProp={'status'}
        />
    );
}
EvisIDSelection.propTypes = {
    selectedEvisGroup: PropTypes.string.isRequired,
};