import { useContext } from "react";
import { evisActionTypes } from "../../../../contexts/evis/evisActionTypes";
import { GlobalContext } from "../../../../contexts/globalContextStore/GlobalContextStore";
import ArchAttributes from "../../../../commonComponents/reusableComponents/archAttributes/ArchAttributes";

/**
 * EVIS attributes component.
 * Responsible for rendering and managing the state of EVIS attributes checkboxes.
 * @returns {JSX.Element} The DisplayEvisAttributes component used to display and manage the state of EVIS attributes checkboxes.
 */
export default function EvisAttributes() {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { evisAttributeState } = storeState.evisStates;

    /**
     * Handles the change event of the EVIS attribute checkboxes.
     * It updates the `evisAttributeState` in the global state with the new checkbox state.
     * @param {Event} event The event object containing the checkbox state.
     */
    const handleCheckBox = (event) => {
        const { name, checked } = event.target;
        storeDispatch({
            type: evisActionTypes.EVIS_SET_ATTRIBUTE_STATE,
            newEvisAttributeState: {
                [name]: checked,
            },
        });
    };

    /**
     * Handles the "check all" checkbox change event.
     * It updates the `evisAttributeState` in the global state by setting all the checkboxes to the new state.
     * Either checks all or unchecks all the checkboxes.
     * @param {Event} event The event object containing the checkbox state.
     */
    const handleCheckAllCheckBox = (event) => {
        const { checked } = event.target;
        const newEvisAttributeState = Object.keys(evisAttributeState).reduce((state, key) => {
            state[key] = checked;
            return state;
        }, {});
        storeDispatch({
            type: evisActionTypes.EVIS_SET_ATTRIBUTE_STATE,
            newEvisAttributeState,
        });
    };

    const firstGroupCheckboxes = [
        { name: "ceid", label: "CEID" },
        { name: "host_url", label: "Host URL" },
        { name: "input_error", label: "Input Error" },
    ];

    const secondGroupCheckboxes = [
        { name: "input_type", label: "Input Type" },
        { name: "latency", label: "Latency" },
        { name: "bitrate", label: "Bitrate" },
    ];

    return (
        <ArchAttributes
            handleCheckboxChange={handleCheckBox}
            handleCheckAll={handleCheckAllCheckBox}
            attributes={evisAttributeState}
            firstGroupCheckboxes={firstGroupCheckboxes}
            secondGroupCheckboxes={secondGroupCheckboxes}
        />
    );
}
