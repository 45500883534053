import { useQuery } from "@tanstack/react-query";
import { makeAPICall } from "../../utilities.js/apiUtils";
import { isLoggerEnabled } from "../../utilities.js/logger";
import { infrastructures } from "./apiconfig";

/**
 * Custom hook to fetch initial data for a given infrastructure.
 *
 * If the infrastructure details are not found in the `infrastructures` object,
 * it will log a warning and return an empty array.
 *
 * If the infrastructure details are found, it will make an API call to fetch the
 * data and return the response. If a `dataProcesser` function is provided, it
 * will process the data before returning it.
 *
 * @param {function} [dataProcesser] - Function to process the fetched data
 * @param {string} infraName - Name of the infrastructure to fetch data for
 * @returns {object} data query object 
 */
function useApiFetchInitialData(dataProcesser = undefined, infra) {
    const query = useQuery([infra], async () => {
        const infraDetails = infrastructures[infra];
        if (!infraDetails) {
            isLoggerEnabled && console.warn(`Infra ${infra} details not found. Please add the infra details in infraConfig.js`);
            return [];
        }
        const response = await makeAPICall(infraDetails.path, infraDetails.getInitialData, "none");
        const processedData = response && dataProcesser ? dataProcesser(response) : response;
        return processedData || [];
    });

    return query;
}

export default useApiFetchInitialData;