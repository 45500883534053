import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Fab from "@mui/material/Fab";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/system";
import React, { useContext } from "react";

// Local imports
import DashboardTheme from "../../commonComponents/DashboardTheme";
import { WebUrlParamsHandler } from "../../commonComponents/WebUrlParamsHandler";
import LeftMenuDrawer from "../../commonComponents/leftmenudrawer/LeftMenuDrawer";
import LeftMenuIcons from "../../commonComponents/leftmenudrawer/LeftMenuIcons";
import ThumbnailViewer from "../../commonComponents/thumbnailViewer/ThumbnailViewer";
import { genericActionTypes } from "../../contexts/generic/genericActionTypes";
import { GlobalContext } from "../../contexts/globalContextStore/GlobalContextStore";
import { getSortedEncoderTenants } from "../../encoder/js/encoder/utils/encoderUtils";
import { organizeEvisDataByGroup } from "../../evis/js/utils/evisUtils";
import useApiFetchInitialData from "../../js/api/initialData/useApiFetchInitialData";
import { isLoggerEnabled } from "../../js/utilities.js/logger";
import { ScrollTop } from "../scrollTop/ScrollTop";
import dashboardStyles from "./dashboardStyles";

/**
 * `Dashboard component` that represents the main dashboard page.
 * It contains a sidebar, a toolbar, a thumbnail viewrs, provides a
 * scroll to top functionality and menu and snapshot icons.
 * The componet also handles data fetching and merging using the
 * `useDashboardData` hook.
 * @component
 * @param {Object} props
 * @returns {JSX.Element} - The rendered `Dashboard` component
 */
function Dashboard(props) {
    const { storeState } = useContext(GlobalContext);
    const { genericStates } = storeState;

    const { isLoading, isError } = useDashboardData();
    isLoggerEnabled && isLoading && console.log("Loading Dashboard Data...");
    isLoggerEnabled && isError && console.error("Error Loading Dashboard Data...");

    return (
        <>
            {isLoggerEnabled && console.log("in Dashboard component")}
            <ThemeProvider theme={DashboardTheme()}>
                {!isLoading && <WebUrlParamsHandler />}
                <React.Fragment>
                    {/* this section is needed for the ScrollTop component */}
                    <div sx={dashboardStyles.invisibleBookmark} id="scroll-to-here">
                        {" "}
                    </div>
                    <dashboardStyles.main open={genericStates.openSideBar}>
                        <Container maxWidth="xxl" >
                            <CssBaseline />
                            <LeftMenuIcons />
                            <Box sx={dashboardStyles.box}>
                                <LeftMenuDrawer />
                                <ThumbnailViewer />
                            </Box>
                        </Container>
                    </dashboardStyles.main>
                    <ScrollTop {...props}>
                        <Fab size="small" aria-label="scroll back to top">
                            <Tooltip title="Jump Back to Top" arrow>
                                <KeyboardArrowUpIcon />
                            </Tooltip>
                        </Fab>
                    </ScrollTop>
                </React.Fragment>
            </ThemeProvider>
        </>
    );
}
export default Dashboard;


/**
 * Custom hook to fetch and merge data of differnt infrastructures(Ex. Encoder, EVIS).
 * The hook fetches data using `useApiFetchInitialData` hook and merges the data,
 * updates the global state and returns the merged data along with loading and error states.
 * @returns {Object} The merged data along with loading and error states.
 * @returns {boolean} isLoading - Indicates if the data is still loading.
 * @returns {boolean} isError - Indicates if there was an error while fetching the data.
 */
function useDashboardData() {
    const { storeState, storeDispatch } = useContext(GlobalContext);
    const { genericStates } = storeState;

    const encoderQuery = useApiFetchInitialData(getSortedEncoderTenants, 'encoder');
    const evisQuery = useApiFetchInitialData(organizeEvisDataByGroup, 'EVIS');

    const mergedData = React.useMemo(() => ({
        ...genericStates.dashboardFeedQueryData,
        dbEvisQueryData: evisQuery.data,
        dbQueryData: encoderQuery.data,
        isDbQueryLoading: evisQuery.isLoading || encoderQuery.isLoading,
        isDbQueryError: evisQuery.isError || encoderQuery.isError,
        dbQueryErrorMsg: evisQuery.error || encoderQuery.error,
    }), [
        genericStates.dashboardFeedQueryData,
        evisQuery.data, evisQuery.isLoading, evisQuery.isError, evisQuery.error,
        encoderQuery.data, encoderQuery.isLoading, encoderQuery.isError, encoderQuery.error,
    ]);

    // Simple check to ensure dispatch only occurs when mergedData actually changes meaningfully.
    React.useEffect(() => {
        if (JSON.stringify(genericStates.dashboardFeedQueryData) !== JSON.stringify(mergedData)) {
            storeDispatch({
                type: genericActionTypes.GET_DASHBOARD_FEED_QUERY_DATA,
                dashboardFeedQueryData: mergedData,
            });
        }
    }, [storeDispatch, mergedData, genericStates.dashboardFeedQueryData]);

    return { mergedData, isLoading: mergedData.isDbQueryLoading, isError: mergedData.isDbQueryError };
}

